<div class="container-fluid " [@appearSlow]>

  <div class="row p-0">
    <div class="col-lg-6 offset-lg-3 col-12 p-0 m-0">

    </div>
  </div>
  <div class="row">
    <div class="text-center">
      <img src="assets/images/paym-request/request_success.svg" class="img-fluid" alt="Success">
    </div>
  </div>

  <div class="row text-center ">
    <p class="p-title my-1">Success!</p>
    <p class="p-title my-1">You requested
      <strong>${{ this.totalAmount|number :'.2'}}</strong> from:</p>
  </div>
  <br>
  <br>
  <div class="row bg-color-clear rounded-1" >
    <div class="col-md-6 offset-md-3 col-12 px-1" [ngClass]=" display_selected_all ? 'scroll-requests ': ''">
      <div *ngFor="let recs of recipients_selected"  class="row"  >
        <div class="row">
          <div class=" col-1 offset-md-2 text-center align-content-center p-0">
            <div class="p-0 button-pointer" >
              <img *ngIf="recs.zelle == true" [src]="'assets/images/paym-request/zelle_logo.svg'" class="img-fluid">
              <img *ngIf="recs.kredio == true " [src]="'assets/images/paym-request/logo_' + brand + '.svg'" class="img-fluid">
            </div>
          </div>
          <div class="col-8 col-md-5 py-2">
            <p  class="mb-0 align-content-center p-fullname my-1">{{recs.first_name}} {{recs.last_name}} </p>
            <p *ngIf="recs.email" class="mb-0  p-mean ">{{recs.email}}</p>
            <p *ngIf="!recs.email" class="mb-0  p-mean ">{{recs.phone}}</p>
          </div>
          <div class="col-3 col-md-2 d-flex text-end">
            <p  class="mb-0 align-content-center p-fullname my-1">${{ amountObject[recs.id]|number :'.2'}}</p>
          </div>

        </div>
      </div>
    </div>
  </div>

  <br>
  <br>

  <div class="row my-3 px-1">

    <div class="offset-md-2 col-md-4  col-12 my-3" >
      <button class="yellow-button rounded-4 w-100 py-3 mb-0 h-100"
              id="btnBack" routerLink="/main/paymentsTransfers"
      >Back</button>
    </div>

    <div class="col-md-4  col-12 my-3" >
      <button class="violet-button rounded-4 w-100 py-1 mb-0 h-100"
              id="btnSend" (click)="startNewRequest()"
      ><img src="assets/images/paym-request/send-request.svg"
            class="img-fluid pe-2" (click)="startNewRequest()" >
        New Request</button>
      <p class="mb-0 my-2 p-bottom button-pointer"
         routerLink="main/request" >See all pending requests</p>
    </div>

  </div>

</div>
