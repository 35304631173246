
<div class="container-fluid" [@appearSlow]>

      <div class="row">

        <div class="col-2 d-flex align-items-center justify-content-center" >
          <img  class="img-fluid"  src="assets/images/edit_profile/profile_img.svg" />
        </div>

        <div class="col-8 d-flex align-items-center" >
          <p class="p-full-name m-0" >{{fieldValues['first_name']}} {{fieldValues['last_name']}}</p>
        </div>

        <!--    <div class="cancel-edit d-flex justify-content-end">-->
        <div class="col-2  d-flex align-items-center justify-content-center cancel-edit button-pointer">
          <img class="img-fluid" (click)="cancelEditProfile()" src="assets/images/edit_profile/cancel_edit.svg"/>
        </div>

      </div>

      <!-- Edit Information -->

      <div class="row my-3 d-flex justify-content-center" *ngIf="inputLength==0 && isDataEdited === false">

        <div class="col-12">
          <p class="p-personalInfo">Personal information</p>
        </div>

        <div *ngFor="let field of fieldsConfig">

            <div *ngIf="(field.type === 'string' || field.type === 'number') &&   field.name !='id_issuing_state' &&
                field.name !='dob' && field.name !='email' && field.name !== 'id_issued_date' &&
                      field.name !== 'id_exp_date' && field.name !== 'id_verified_date'"
                class="form-floating my-3">

              <input
                [class]="'form-control bg-color-clear '"
                [ngClass] ="validationErrors[field.name]? 'formError': ''"
                [placeholder]="field.placeHolder"
                [type]="field.type"
                [ngModel]="fieldValues[field.name]"
                (ngModelChange)="updateFieldValue(field.name, $event)"
                (blur)="setFieldToEdited(field.name)"
              >
              <label class="form-label">{{field.displayName}}</label>

            </div>

            <!-- DOB-->
            <div *ngIf="field.name === 'dob'">
            <p class="label-date">Date of Birth</p>
            <div class="input-group ">
              <input
                [id]="'inputDOB'"
                [class]="'form-control bg-color-clear py-3 '"
                [ngClass]="validationErrors[fieldsConfig[2].name]? 'formError ': ''"
                placeholder="Date of Birth"
                name="dp"
                [ngModel]="dateOfBirth"
                (ngModelChange)="updateFieldValue(fieldsConfig[2].name,$event)"
                (blur)="setFieldToEdited(fieldsConfig[2].name)"
                ngbDatepicker
                #dob="ngbDatepicker"
                [maxDate] = maxDob
              />
              <button class="btn btn-outline-secondary bi bi-calendar3 button-toggle" (click)="dob.toggle()" type="button"></button>
            </div>
            <p id="error-dob" class="error my-1" *ngIf="this.validationErrors[fieldsConfig[2].name]">
              {{this.validationErrors[fieldsConfig[2].name]}}
            </p>
          </div>

            <!-- id_type-->
            <div *ngIf="field.name === 'id_type'"   class="form-floating my-3 ">
              <select
                [id]="'inputIdType'"
                class = "form-select bg-color-clear"
                [ngModel]="fieldValues[field.name]"
                (ngModelChange)="updateFieldValue(field.name, $event)"
                (blur)="setFieldToEdited(field.name)"
              >
                <option value="" disabled selected>Select Id Type</option>

                <option
                  *ngFor="let option of this.idTypes"
                  [value] = option[1]
                >{{option[0]}}</option>

              </select>
              <label class="form-label" >{{this.field.displayName}}</label>
            </div>

            <!-- Expiration Date-->
            <div *ngIf=" field.name === 'id_exp_date'">
              <br>
              <p class="label-date">Expiration Date</p>
              <div class="input-group ">
                <input
                  [id]="'inputExpDate'"
                  [class]="'form-control bg-color-clear py-3'"
                  [ngClass]="validationErrors[fieldsConfig[6].name]? 'formError ': ''"
                  placeholder="Expiration Date"
                  [ngModel]="dateExpiration"
                  (ngModelChange)="updateFieldValue(fieldsConfig[6].name,$event)"
                  (blur)="setFieldToEdited(fieldsConfig[6].name)"
                  ngbDatepicker
                  #doe="ngbDatepicker"
                  [minDate]="minAllowedDateOfExp"
                />
                <button id="btnExpDate"
                        class="btn btn-outline-secondary bi bi-calendar3 button-toggle"
                        (click)="doe.toggle()" type="button"></button>
              </div>
              <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[6].name]">
                {{this.validationErrors[fieldsConfig[6].name]}}
              </p>
          </div>

            <!-- Verification Date-->
            <div *ngIf=" field.name === 'id_verified_date'">
            <br>
            <p class="label-date">Verification Date</p>
            <div class="input-group ">
              <input
                [id]="'inputVerDate'"
                class="form-control  bg-color-clear py-3"
                placeholder="Verification Date"
                name="dp"
                [ngModel]="dateVerif"
                (ngModelChange)="updateFieldValue(fieldsConfig[7].name,$event)"
                (blur)="setFieldToEdited(fieldsConfig[7].name)"
                ngbDatepicker
                #dov="ngbDatepicker"
                [maxDate] = maxAllowedDateOfVer
              />
              <button class="btn btn-outline-secondary bi bi-calendar3 button-toggle" (click)="dov.toggle()" type="button"></button>
            </div>
            <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[7].name]">
              {{this.validationErrors[fieldsConfig[7].name]}}
            </p>
          </div>

            <!-- Issue Date-->
            <div *ngIf=" field.name === 'id_issued_date'">
            <br>
            <p class="label-date" >Issue Date</p>
            <div class="input-group ">
              <input
                [id] = "'inputIssuedDate'"
                [class]="'form-control py-3 bg-color-clear'"
                [ngClass]="validationErrors[fieldsConfig[5].name]? 'formError': ''"
                placeholder="Issue Date"
                [ngModel]="dateOfIssue"
                (ngModelChange)="updateFieldValue(fieldsConfig[5].name,$event)"
                (blur)="setFieldToEdited(fieldsConfig[5].name)"
                ngbDatepicker
                #doi="ngbDatepicker"
                [maxDate] = maxAllowedDateOfIssue
              />
              <button class="btn btn-outline-secondary bi bi-calendar3 button-toggle" (click)="doi.toggle()" type="button"></button>
            </div>
            <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[5].name]">
              {{this.validationErrors[fieldsConfig[5].name]}}
            </p>
          </div>

           <!-- State -->
            <div *ngIf=" field.name === 'id_issuing_state'">
              <br>
              <p class="label-date" >Issue State</p>
              <select
                [id] = "'inputIDState'"
                [class]="'form-select py-3 bg-color-clear'"
                [ngModel]="fieldValues[fieldsConfig[8].name]"
                (ngModelChange)="updateFieldValue(fieldsConfig[8].name, $event)"
                (blur)="setFieldToEdited(fieldsConfig[8].name)"
              >
                <option value="" [disabled] = true [selected]="true" > State</option>
                <option
                  *ngFor="let option of fieldsConfig[8].options"
                  [value] = option
                >{{option}}</option>
              </select>

              <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[8].name]">
                {{this.validationErrors[fieldsConfig[8].name]}}
              </p>
          </div>

      </div> <!--Close ngfor-->

        <div class="mt-5">
          <button id="btnConfirm" class="violet-button py-3 w-100 rounded-4" (click)="confirmChanges()"
          >Save</button>
        </div>

      </div> <!--Close row-->

      <!-- Response -->

      <div *ngIf="isDataEdited === true">
        <div *ngIf="dataEditSuccess === false">
          <div class="offset-2 col-8 my-5">
            <p class="p-response text-center fw-bold">We were unable to process your request.</p>
            <p class="p-response text-center fw-bold">You will be redirected shortly.</p>

          </div>
        </div>


        <div *ngIf="dataEditSuccess === true">
          <div class="offset-2 col-8 my-5 ">
            <p class="p-response text-center fw-bold">Your profile data has been updated.</p>
            <p class="p-response text-center fw-bold">You will be redirected shortly.</p>
          </div>
        </div>
      </div>

      <div class="d-lg-none d-block" style="height: 3rem;"></div>

</div> <!-- Close container -->

<div *ngIf="confirmChangesModal==false">
  <app-modal-new-acc>
    <app-profile-confirm-change
    (confirmChanges)="submitEditProfile($event)"
    ></app-profile-confirm-change>
  </app-modal-new-acc>
</div>
