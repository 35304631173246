<div class="container-fluid ">
  <div *ngIf="!startEnrollment">
    <br>
    <br>
    <br>
    <div class="row text-center py-1">
      <div>
        <img src="assets/images/zelle/zelle_logo.svg">
      </div>
    </div>
    <br>
    <br>
    <div class="row text-center">
      <p class="p-zelle-bold">A fast and easy way to send and receive money</p>
      <p class="p-zelle">To send and receive money with <strong>Zelle</strong>,
        you need to enroll your mobile number or email address. </p>
    </div>
    <br>
    <div class="row mb-2">
      <div  class="col-lg-6 col-12 text-center my-2"  (click)="cancel()">
        <button class="yellow-button py-3 px-5 w-100 rounded-3 border-0" (click)="cancel()">Close</button>
      </div>
      <div  class="col-lg-6 col-12 text-center my-2"  (click)="enroll()">
        <button class="violet-button py-3 px-5 w-100 rounded-3 border-0" (click)="enroll()">Enroll to Zelle</button>
      </div>
    </div>
  </div>

  <div *ngIf="startEnrollment">
    <app-zelle-setup
    [email]="this.email"
    [phone]="this.phone"
    ></app-zelle-setup>
  </div>


</div>

