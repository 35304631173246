<div class="container-fluid h-100" >

      <app-nav-buttons [current_route]= your_route ></app-nav-buttons>

      <!-- First narrow bar: Account, Cash Back, Live Payroll -->
      <div class="row my-3 px-3 "  >

        <div class="col-lg-6 col-12" >

          <div class="row h-100" >

            <div class="col-sm-5 col-12 p-0 my-sm-0 my-2 "  >
              <div class="card ms-lg-3 me-sm-1 border-0 rounded-3 h-100 bg-color-clear" >
                <div class="card-body ">
                  <p class="fw-bold p-first-row">Bank Account</p>
                  <p class="text-bank-hint color-bank mb-0">Available Balance</p>
                  <div class="align-items-center my-2">

                    <p id="balancePos" *ngIf="main_account"
                       class=" p-first-row-high mb-0 d-none d-lg-block d-xl-none">${{main_account.balance|number :'.2'}}</p>

                    <p id="balancePosNoLG" *ngIf="main_account"
                       [ngClass]="amount_high ? 'p-first-row-high' : 'p-first-row-low'"
                       class="mb-0 d-lg-none d-xl-block">${{main_account.balance|number :'.2'}}</p>

                    <p id="balanceNull" *ngIf="!main_account" class="p-first-row-high mb-0">$0.00</p>
                  </div>
                </div>
              </div> <!--Close rounded card-->
            </div> <!--Close rounded col-->

            <div class="col-sm-7 col-12 p-0 my-sm-0 my-2 ">
              <div class="card h-100  ms-sm-2 me-lg-3 border-0 rounded-3 bg-color-clear ">
                <div class="card-body " >
                  <p class="p-first-row fw-bold">Cash Back</p>
                  <app-home-cash-back></app-home-cash-back>

                </div> <!--Close card body-->
              </div> <!--Close rounded card-->
            </div> <!--Close rounded col-->

          </div>  <!--Close row-->

        </div> <!--Close col-->

        <div class="col-lg-6 col-12 p-0 my-lg-0 my-2">
          <div class="card h-100  ms-lg-2 me-lg-3  rounded-3 border-0 bg-color-clear">
            <div class="card-body">
              <app-home-carousel
                (extAccount) = addNewExternalAccount($event)
                (showDAndC) = showCouponsDiscounts($event)
              ></app-home-carousel>
            </div>
          </div>

        </div> <!--Close col-->

      </div> <!-- Close First row-->

      <!-- Second row: Display accounts-->
      <div class="row my-3 px-3 " >

        <div class="col-lg-6 col-12 py-0 px-1 my-lg-0 my-2">
          <div class="card h-100  ms-lg-2 me-lg-3  rounded-3 border-0 bg-color-clear">
            <div class="card-body" >

              <div class="row ">
                <p class="text-bank-hint color-bank ">Accounts</p>
              </div>
              <div class="row ">
                <app-accounts-display></app-accounts-display>
              </div>

            </div> <!--Close card body-->
          </div> <!--Close card-->
        </div> <!--Close col-->

        <div class="col-lg-6 col-12 p-0 my-lg-0 my-2 ">

          <div class="card h-100  ms-lg-2 me-lg-3  rounded-3 border-0 bg-color-clear">
            <div class="card-body" >

              <div class="row">
                <p class="text-bank-hint color-bank ">Cards</p>
              </div>
              <div class="row" >
                <app-cards-display></app-cards-display>
              </div>

            </div> <!--Close card body-->
          </div> <!--Close card-->
        </div> <!--Close col-->

      </div> <!-- Close row-->

      <!-- Third row bar: Transaction List-->
      <div class="row mt-3 px-lg-3 " >

        <div class=" col-12 ">
          <div class="card rounded-3 border-0 bg-color-clear ">
            <div class="card-body pt-1 pb-2 " >
              <div *ngIf="main_account" >
                <app-transactions-display
                  [productId]= this.main_account.id
                  [month]=this.home_default_month
                  [year]=this.home_default_year
                  [isHome] = true
                ></app-transactions-display>
              </div> <!--Close row-->
            </div> <!--Close card body-->
          </div> <!--Close card-->
        </div> <!--Close col-->

      </div> <!-- Close row-->

  <div class="divider-from-nav d-lg-none d-block" style="height: 5rem;"></div>


  <div *ngIf="isEmailVerified=='false'">
    <app-modal-new-acc>
      <app-verify-email
        [registeredEmail] = this.logInEmail
        (closeVerifyEmail)="requestEmailClose($event)"
      ></app-verify-email>
    </app-modal-new-acc>
  </div>

</div>









