import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from "./components/Login/login/login.component";
import {HomeComponent} from './components/Home/home/home.component'
import {AccountsComponent} from "./components/Accounts/accounts/accounts.component";
import {CardsComponent} from "./components/Cards/cards/cards.component";
import {TransactionsComponent} from "./components/Transactions/transactions/transactions.component";
import {ProfileComponent} from "./components/Profile/profile/profile.component";
import {CouponsDiscountsComponent} from "./components/Home/CouponsDiscounts/coupons-discounts/coupons-discounts.component";
import {CouponsDiscountsUserComponent} from "./components/Home/CouponsDiscounts/coupons-discounts-user/coupons-discounts-user.component";
import {PayrollComponent} from "./components/Home/Payroll/payroll/payroll.component";
import {PaymentsTransfers} from "./components/Transfers/payments-tranfers/payments-transfers";
import {SignupComponent} from "./components/Signup/signup/signup.component";
import {WelcomeComponent} from "./components/Welcome/welcome/welcome.component";
import {SuccessComponent} from "./components/Profile/success/success.component";
import {RecipientsComponent} from "./components/Recipients/recipients/recipients.component";
import {PayrollEnrollComponent} from "./components/Home/Payroll/payroll-enroll/payroll-enroll.component";
import {AuthGuardGuard} from "./services/auth-guard.guard";
import {SignupPhoneComponent} from "./components/Signup/signup-phone/signup-phone.component";
import {VerifyCompleteComponent} from "./components/Home/verify-complete/verify-complete.component";
import {RecPaymentsComponent} from "./components/RecPayments/rec-payments/rec-payments.component";
import {CardSelectedComponent} from "./components/Cards/card-selected/card-selected.component";
import {PayrollManageComponent} from "./components/Home/Payroll/payroll-manage/payroll-manage.component";

import {MainResponsiveComponent} from "./components/main-responsive/main-responsive.component";
import {LoginDeviceComponent} from "./components/Login/login-device/login-device.component";
import {MsgsDocsComponent} from "./components/MsgsDocs/msgs-docs/msgs-docs.component";
import {LegalTermsComponent} from "./components/Signup/legal-terms/legal-terms.component";
import {SignupMainComponent} from "./components/Signup/signup-main/signup-main.component";
import {ZelleComponent} from "./components/Transfers/Zelle/zelle/zelle.component";
import {PaymentsRequestComponent} from "./components/Transfers/Payments-request/payments-request/payments-request.component";
import {UserkycGuard} from "./services/userkyc.guard";

const routes: Routes = [

  { path:'', component: WelcomeComponent },

  { path:'main' , component: MainResponsiveComponent ,
    children:[
      { path:'home' , component: HomeComponent ,    canActivate:[AuthGuardGuard]},
      { path:'home/payroll' , component: PayrollComponent ,  canActivate:[AuthGuardGuard]},

      { path:'accounts' , component: AccountsComponent ,    canActivate:[AuthGuardGuard]},
      { path: 'accounts/recipients' , component: RecipientsComponent,  canActivate:[AuthGuardGuard]},
      // { path:'accounts/creditScore', component:CreditScoreComponent,  canActivate:[AuthGuardGuard]},

      { path:'alerts' , component: MsgsDocsComponent  ,    canActivate:[AuthGuardGuard]},

      { path:'cards' , component: CardsComponent  ,    canActivate:[AuthGuardGuard]},
      { path:'cards/selected', component: CardSelectedComponent ,  canActivate:[AuthGuardGuard]},

      { path:'couponsDiscounts', component:CouponsDiscountsComponent,  canActivate:[AuthGuardGuard]},
      { path: 'couponsDiscounts/selected', component: CouponsDiscountsUserComponent,  canActivate:[AuthGuardGuard]},

      { path:'transactions' , component: TransactionsComponent ,    canActivate:[AuthGuardGuard]},

      { path:'paymentsTransfers', component:PaymentsTransfers, canActivate:[AuthGuardGuard] },
      { path: 'recpayments' , component: RecPaymentsComponent , canActivate:[AuthGuardGuard]},
      { path:'zelle', component: ZelleComponent ,canActivate:[AuthGuardGuard] },
      { path: 'request' , component: PaymentsRequestComponent ,canActivate:[AuthGuardGuard] },

    ] , canActivate:[AuthGuardGuard]},

  { path:'signup', redirectTo:'signup/main' , pathMatch:'full'},

  { path:'signup' , component: SignupMainComponent ,
    children:[
      { path:'main', component: SignupComponent },
      { path:'legal' , component: LegalTermsComponent,  canActivate:[UserkycGuard]},
      { path:'phone', component: SignupPhoneComponent,  canActivate:[UserkycGuard] },
    ]
  },

  { path:'enrollpayroll' , component: PayrollEnrollComponent ,  canActivate:[AuthGuardGuard]},

  { path:'login', component: LoginComponent },

  { path:'payrollmanage' , component: PayrollManageComponent ,  canActivate:[AuthGuardGuard]},

  { path:'profile', component: ProfileComponent,  canActivate:[UserkycGuard]},

  { path:'register', component: LoginDeviceComponent ,  canActivate:[AuthGuardGuard] },

  { path:'success', component: SuccessComponent ,  canActivate:[UserkycGuard]},

  { path:'verification/:email/:token', component: VerifyCompleteComponent },

  { path:'**', redirectTo:'' , component: WelcomeComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }

