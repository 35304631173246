<div class="container-fluid bg-color-clear">

  <!--User is enrolled to Bank rewards-->

  <div *ngIf="isEnrolled" class="row" >
    <div class="col-6 col-lg-12 col-xl-6" >
      <p class="text-bank-hint color-bank mb-0"  >Paid Cashback</p>
      <div class="align-items-center my-2">
        <p id="paidCBPos"
           [ngClass]="cashBack_paid_high ? 'p-first-1Khigh-row' : 'p-first-1Klow-row'"
           class=" my-1">${{benefitsAccount.paid_cashback|number :'.2'}}</p>
      </div>
     </div>
    <div class="col-6 col-lg-12 col-xl-6" >
      <p class="text-bank-hint color-bank mb-0" >Pending Cashback</p>
      <div class="align-items-center my-2">
        <p  id="pendindCBPos"
            [ngClass]="cashBack_pending_high ? 'p-first-1Khigh-row' : 'p-first-1Klow-row'"
            class="text-muted  mb-0">${{benefitsAccount.pending_cashback|number :'.2'}}</p>
      </div>
    </div>




  </div><!--Close row-->

  <!--User is NOT enrolled to Bank rewards-->
  <div *ngIf="!isEnrolled">

   <div class="row my-2" >
     <div class="col-1 offset-2  p-0 ">
       <img src="assets/images/home/star.svg" alt="Star" class="img-fluid"  >
     </div>
     <div class="col-9  p-0 d-flex align-items-center">
       <p class="text-bank-hint  mb-0">
         Enroll to your <strong>{{brand}}</strong> benefits
       </p>
     </div>
   </div>

    <div class="row col-8 offset-2">
      <button id="btnActivateRewards"
        (click)="activateRewards()"
        class="yellow-button w-100 py-3 rounded-4 button-pointer fw-bold">Enroll</button>
    </div>

  </div><!--Close ngIf-->

</div>
