import {animate, style, transition, trigger} from "@angular/animations";

export const slideInUpper = trigger('slideInUpper', [
  transition(':enter', [
    style({ transform: 'translateY(-100%)' }),
    animate('1000ms ease-out',
      style({ transform: 'translateY(0)' })),
  ]),
]);

export const slideInLower = trigger('slideInLower', [
  transition(':enter', [
    style({ transform: 'translateY(100%)' }),
    animate('1000ms ease-out',
      style({ transform: 'translateY(0)' })),
  ]),
]);

export const slideInLeft = trigger('slideInLeft', [
  transition(':enter', [
    style({ transform: 'translateX(100%)' }),
    animate('1000ms ease-out',
      style({ transform: 'translateX(0)' })),
  ]),
]) ;

export const slideInRight =  trigger('slideInRight', [
  transition(':enter', [
    style({ transform: 'translateX(-100%)' }),
    animate('1000ms ease-out',
      style({ transform: 'translateX(0)' })),
  ]),
]);

export const appearSlow = trigger('appearSlow', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('1000ms ease-out',
      style({ opacity: 1 }),
    ),
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('500ms ease-out',
      style({ opacity: 0 }),
    ),
  ]),
]);
