import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {ProfileService} from "../../../services/profile.service";
import {environment} from "../../../../environments/environment";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css'],
  animations: [
    trigger('slideInUpper', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)' }),
        animate('1000ms ease-out',
          style({ transform: 'translateY(0)' })),
      ]),
    ]),
    trigger('slideInLower', [
      transition(':enter', [
        style({ transform: 'translateY(100%)' }),
        animate('1000ms ease-out',
          style({ transform: 'translateY(0)' })),
      ]),
    ]),
    trigger('slideInLeft', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('1000ms ease-out',
          style({ transform: 'translateX(0)' })),
      ]),
    ]),
    trigger('slideInRight', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('1000ms ease-out',
          style({ transform: 'translateX(0)' })),
      ]),
    ]),
  ],
})
export class SuccessComponent implements OnInit{

  brand_img  :string = 'assets/images/bank_imgs/logo_' + environment.brand + '.svg'
  docsPrivatePolicy !:string
  docsPrivatePolicyCRB !:string
  constructor(
    private router : Router,
    private profileService:ProfileService
  ) { }

  redirect():void {
    localStorage.clear();
    this.router.navigateByUrl('/login')
  }

  getLegalTerms():void{
    this.profileService.getLegalDicsKYC().subscribe({
      next:(res)=>{
        this.docsPrivatePolicy = res.res[2].doc
        this.docsPrivatePolicyCRB = res.res[4].doc
      }})
  }

  redirectToFile(url:string){
    window.open(url, "_blank");
  }
  ngOnInit() {
    sessionStorage.clear();
    this.getLegalTerms()
  }
}
