
<div class="container-fluid h-100">

  <!-- Left side Image asset/logo/terms and policies and right side signin and links -->
  <div *ngIf="displayLarge" class="row h-100">

    <!-- Left side -->
    <div class="col-lg-6 d-none d-lg-inline" style="background-color: #4C49F5" >

      <!-- Logo + Text -->
      <div class="row d-lg-flex d-none" style="height: 25%">
        <div class="get-started d-flex align-items-end justify-content-center" [@slideInUpper]>
          Get Started
        </div>
        <div class="d-lg-flex d-none d-flex  justify-content-center" [@slideInUpper]>
          <img [src]= brand_img class="img-fluid" style="scale: 70%" alt="">
        </div>
      </div>

      <!-- Image asset -->
      <div class="row d-lg-flex d-none py-4" style="height: 70%">
        <div class="d-flex justify-content-center h-100" [@slideInRight]>
          <img class="img-fluid" src="assets/images/login/profile_fill_in.svg" alt="">
        </div>
      </div>

      <!-- Standard terms and policies -->
      <div class="row d-lg-flex d-none">
        <div class="text-center" [@slideInLower]>
          <a class="bottom-a button-pointer" id="btnPrivacyPolicy"
             (click)="redirectToFile(this.docsPrivatePolicy)" > Privacy Policy |</a>
          <a class="bottom-a button-pointer" id="btnPrivacyPolicyCRB"
             (click)="redirectToFile(this.docsPrivatePolicyCRB)"> Legal&nbsp;&nbsp;|&nbsp;</a>
          <a class="bottom-a button-pointer" id="btnContact"  > Contact&nbsp;&nbsp;</a>
        </div>
      </div>

    </div>

    <!-- Right side -->
    <div class="col-lg-6 col-12  py-0 scroll-profile"  [@slideInLeft] >
      <!-- Logo appears in small screen -->
      <div class="row d-lg-none my-3 px-1" >
        <img class="img-fluid "
             [src]="'../../../../assets/images/bank_imgs/logo_blue_' + brand +'.svg'"
              alt="Logo bank">
      </div>

      <div class="row">
        <app-profile-create
        ></app-profile-create>
      </div>

    </div>


  </div> <!--    Close row -->

  <div *ngIf="!displayLarge" class="row h-100 p-0" >
    <app-profile-create-small></app-profile-create-small>
  </div>

</div> <!--    Close container-->


