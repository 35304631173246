<div class="container-fluid h-100 " >

  <!-- Left side Image asset and right side signin/create account -->
  <div class="row h95 "  >

    <!-- Left side Image asset centered -->
    <div class="col-xl-7 col-12 d-xl-flex d-none align-items-center justify-content-center">
      <img src="../../../../assets/images/welcome/welcome_asset.svg"
           [@slideInUpper] class="img-fluid w-75 " alt="Responsive image">
    </div>

    <!-- Right side logo - login - create account - and some text -->
    <div class="col-xl-5 col-12">

      <!-- Products - contact - about - help links -->
      <div class="row d-none d-xl-flex align-items-center  h20" [@slideInUpper]>
        <div class="col-3  align-items-center text-center    px-0" >
            <p routerLink="/" class="help-links ps-1  button-pointer  mb-0 w-100">
              <a ><img src="assets/images/welcome/product_asset.svg" class="img-fluid shadow-lg" alt="Welcome"></a>
              Product</p>
        </div>
        <div class="col-3  align-items-center text-center    px-0" >
          <p routerLink="/" class="help-links ps-1  button-pointer  mb-0 w-100">
            <a ><img src="assets/images/welcome/contact_asset.svg" class="img-fluid shadow-lg" alt="Contact"></a>
            Contact</p>
        </div>

        <div class="col-3  align-items-center text-center    px-0" >
          <p routerLink="/" class="help-links ps-1  button-pointer  mb-0 w-100">
            <a ><img src="assets/images/welcome/about_asset.svg" class="img-fluid shadow-lg" alt="About"></a>
            About</p>
        </div>
        <div class="col-3  align-items-center text-center   px-0" >
          <p routerLink="/" class="help-links ps-1  button-pointer  mb-0 w-100">
            <a ><img src="assets/images/welcome/help_asset.svg" class="img-fluid shadow-lg" alt="Help"></a>
            Help</p>
        </div>
      </div>



      <!--Large Screen -->
      <div class="row  d-none d-xl-block py-3" [@slideInLeft]>
        <p class="p-welcome text-center ">Welcome to</p>
      </div>

      <!--Small Screen -->

      <div class="h5 d-xl-none" ></div>
      <div class="row d-xl-none my-2" [@slideInLeft] >
        <p class="p-welcome-small text-center " >
          Welcome to
        </p>
      </div>
      <!-- Logo  d-flex align-items-center justify-content-center -->
      <div class="row text-center my-3 " >
        <div class="col-10 offset-1">
           <img class="img-fluid w-100" [src]= brand_img
                [@slideInLeft] alt="Welcome Logo">
        </div>
      </div>

      <!-- Asset image for smaller pages -->
      <div class="row d-xl-none align-items-center justify-content-center my-5" >
          <img src="../../../../assets/images/welcome/welcome_asset.svg" [@slideInLeft]
               class="img-fluid m-0" style="scale: 80%;" alt="Responsive image"/>
      </div>


      <!-- Create account and sign in buttons -->

      <div class="row my-5 py-3 h-25"  [@slideInLower]>

        <div class="col-10 offset-1 my-2 d-flex justify-content-center align-items-center">
          <button class="violet-button  w-100 py-3 rounded-4" routerLink="/signup/main" id="create-button">Create Account </button>
        </div>

        <div class="col-10 offset-1  my-2 d-flex justify-content-center align-items-center">
          <button class="yellow-button w-100 py-3 rounded-4" routerLink="/login" id="sign-button" >Sign in</button>
        </div>

        <div class="col-10 offset-1 text-center" >
          <p class="p-bottom my-4">Lorem ipseum dolor sit amet, consectetur adipiscing elit.
            Eget quam sit blandit porta nullam varius
          </p>
        </div>

      </div>

    </div>

  </div>

  <!-- Bottom Side = Standard terms and policies -->
  <div class="row d-xl-flex d-none h5">
    <div class="text-center fw-bold"  [@slideInLower]>
      <a class="bottom-a button-pointer"  id="btnPrivacyPolicy"
         (click)="redirectToFile(this.docsPrivatePolicy)"> Privacy Policy&nbsp;&nbsp;|&nbsp;</a>
      <a class="bottom-a button-pointer" id="btnPrivacyPolicyCRB"
         (click)="redirectToFile(this.docsPrivatePolicyCRB)"> Legal |</a>
      <a class="bottom-a button-pointer" id="btnContact" routerLink="/"> Contact&nbsp;&nbsp;</a>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

