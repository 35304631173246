<div class="container-fluid vh-100" >
  <br>
  <br>

  <div class="row text-center d-lg-none " >
    <p class="p-title mb-0">Our Legal Terms Documents</p>
  </div>

  <!--New Account title: Large screen-->
  <div class="row text-center d-none d-lg-block " >
    <p class="p-title mb-0 ">Our Legal Terms Documents</p>
  </div>

  <div class="row" style="margin-top: 5%">
    <div class="col-10 offset-1">
      <p class="p-brief">In order to complete the signup process,
        please review and accept our terms and conditions.</p>
    </div>
  </div>

  <div class="row" style="margin-top: 10%">
    <div class="col-10 offset-1">

      <!-- eSignatureAgreement-->
      <div>
        <button class=" rounded-4 w-100 py-3 my-2"  #eSignatureAgreement
                data-bs-toggle="modal" data-bs-target="#modal-es" id="eSignatureAgreement"
                [ngClass]="eSignaturePolicyComplete ? 'button-ok' :'button-condition'" >E-Signature Agreement</button>
      </div>

      <!-- accDepositAgreement-->
      <div >
        <button
          class=" rounded-4 w-100 py-3 my-2" #accDepositAgreement
          data-bs-toggle="modal" data-bs-target="#modal-ad" id="accDepositAgreement"
          [ngClass]="accAgreementComplete ? 'button-ok' :'button-condition'">Deposit Account Agreement
        </button>
      </div>

      <!-- policyPrivacy-->
      <div>
        <button class=" rounded-4 w-100 py-3 my-2"  #policyPrivacy
                data-bs-toggle="modal" data-bs-target="#modal-pp" id="policyPrivacy"
                [ngClass]="privatePolicyComplete ? 'button-ok' :'button-condition'" >Privacy Policy</button>
      </div>

      <!-- policyPrivacyCRB-->
      <div>
        <button class=" rounded-4 w-100 py-3 my-2"  #policyPrivacyCRB
                data-bs-toggle="modal" data-bs-target="#modal-ppcrb" id="policyPrivacyCRB"
                [ngClass]="privatePolicyCRBComplete ? 'button-ok' :'button-condition'" >CRB Privacy Policy</button>
      </div>



      <!-- Greendot Terms-->
      <div >
        <button class=" rounded-4 w-100 py-3 my-2 " #greendotTerms
                data-bs-toggle="modal" data-bs-target="#modal-gt" id="greendotTerms"
                [ngClass]="greendotTermsComplete ? 'button-ok' :'button-condition'" >Greendot Terms</button>
      </div>


      <!-- patrioticAct-->
      <div >
        <button class=" rounded-4 w-100 py-3 my-2 mb-5 " #patrioticAct
                data-bs-toggle="modal" data-bs-target="#modal-pa" id="patrioticAct"
                [ngClass]="USAPActComplete ? 'button-ok' :'button-condition'" >USA Patriot Act</button>
      </div>

    </div>
  </div>

  <div class="row mt-3">
    <div class="col-10 offset-1">
      <button id="btnContinue" class=" rounded-4 w-100 py-3 my-2"
              [ngClass]="docsComplete ? 'button-ok' :'button-condition' "
              (click)="submitForm()">Continue</button>
    </div>
  </div>


  <!--        Alert pops up when the TC are not accepted >-->
  <div *ngIf="alert_msg" id="alert_msg" class="alert alert-warning alert-dismissible" role="alert" >
    You have to agree with and sign the legal terms in order to continue with the sign up process.
    <button (click)="closeAlertMsg()" class="btn-close" aria-label="close" data-bs-dismiss="alert"></button>
  </div>

  <!--        Modal to E - Signature >-->

  <div class="modal fade modal-small    " id="modal-es">
    <div class="modal-dialog modal-dialog-scrollable modal-fullscreen-md-down" style="left:0% !important">
      <div class="modal-content px-2" >

        <div class="modal-header">
          <h4   class="fw-bold">
            Disclosure and Consent to Electronic Signatures and Electronic Communications (ESIGN Consent Agreement)
          </h4>

          <button  class="btn-close close-m" data-bs-dismiss="modal"></button>
        </div> <!--Close modal header-->

        <div class="modal-body scroll-lt">

          <p class="p-terms" >{{this.policy_e_signature}}</p>
          <div class="col-10 offset-2">
            <div class="form-check" >
              <input
                id="inputESignature"
                mdbCheckbox
                class="form-check-input"
                type="checkbox"
                [checked]="fieldValues[fieldsConfig[1].name]"
                (change)="updateFieldValue(this.fieldsConfig[1].name, 'true')"
                (blur)="setFieldToEdited(this.fieldsConfig[1].name)"
              >
              <label class="p-terms-ok">I agree to ESIGN Consent Agreement Policy.</label>
            </div>
<!--            <p  class="error" id="err-es" *ngIf="this.validationErrors[fieldsConfig[1].name]">-->
<!--              {{this.validationErrors[fieldsConfig[1].name]}}-->
<!--            </p>-->
          </div>

        </div> <!--Close modal body-->

        <div class="modal-footer">
          <button  class="border-0 violet-button p-3 rounded-4  w-100"
                   data-bs-dismiss="modal" id="modal-es-btn"
          >Close</button>
        </div>

      </div> <!--Close modal content-->
    </div> <!--Close modal-dialog-->
  </div> <!--Close modal-->

  <!--        Modal to Deposit Account Agreement >-->

  <div class="modal fade modal-small" id="modal-ad">
    <div class="modal-dialog modal-md modal-dialog-scrollable modal-fullscreen-md-down" style="left:0% !important">
      <div class="modal-content px-2" >

        <div class="modal-header">
          <h4   class="fw-bold">
            Deposit Account Agreement
          </h4>

          <button  class="btn-close close-m" data-bs-dismiss="modal"></button>
        </div> <!--Close modal header-->

        <div class="modal-body scroll-lt ">

          <div class="p-terms">{{this.policy_depositAccount}}</div>

          <div class="col-10 offset-2 my-2" *ngIf="this.fieldValues['esig_disclosure'] == 'true'">
            <div class="form-check" >
              <input
                id="inputDepAcc"
                mdbCheckbox
                class="form-check-input"
                type="checkbox"
                [checked]="fieldValues[fieldsConfig[0].name]"
                (change)="updateFieldValue(this.fieldsConfig[0].name, 'true')"
                (blur)="setFieldToEdited(this.fieldsConfig[0].name)"
              >
              <label class="p-terms-ok">I agree to the Deposit Account Policy.</label>
            </div>
<!--            <p  class="error" id="err-da" *ngIf="this.validationErrors[fieldsConfig[0].name]">-->
<!--              {{this.validationErrors[fieldsConfig[0].name]}}-->
<!--            </p>-->
          </div>

        </div> <!--Close modal body-->

        <div class="modal-footer">
          <button class="border-0 violet-button p-3 rounded-4  w-100"
                  data-bs-dismiss="modal" id="modal-ad-btn"
          >Close</button>
        </div>

      </div> <!--Close modal content-->
    </div> <!--Close modal-dialog-->
  </div> <!--Close modal-->

  <!--  Modal to Bank Privacy policy -->

  <div class="modal fade " id="modal-pp">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-fullscreen-md-down " >
      <div class="modal-content px-2" >

        <div class="modal-header">
          <h4   class="fw-bold">
            Privacy Policy
          </h4>

          <button  class="btn-close close-m" data-bs-dismiss="modal"></button>
        </div> <!--Close modal header-->

        <div class="modal-body scroll-lt text-center ">

          <object id="pdf_embed_obj" [data]= this.pfdToEmbed(docsPrivatePolicy) class="d-none d-md-block"
                   width="750" height="400" type="application/pdf">
            <embed id="pdf_embed_obj" [src]= this.pfdToEmbed(docsPrivatePolicy)
                   type="application/pdf" class="d-none d-md-block"
                   width="750" height="400" />
          </object>

          <div class="my-3 p-button">
            <img id="img_dwld" src="assets/images/legal/legal_doc.svg" class=" button-pointer" (click)="redirectToFile(docsPrivatePolicy)">
            <span class="mb-0" (click)="redirectToFile(docsPrivatePolicy)" >Download Privacy Policy</span>
          </div>

          <div class="row" *ngIf="this.fieldValues['esig_disclosure'] == 'true'">
            <div class="form-check d-flex text-center">
              <input
                id="inputPrivacy"
                mdbCheckbox
                class="form-check-input text-center"
                type="checkbox"
                [checked]="fieldValues[fieldsConfig[2].name]"
                (change)="updateFieldValue(this.fieldsConfig[2].name, 'true')"
                (blur)="setFieldToEdited(this.fieldsConfig[2].name)"
              >
              <span class="p-terms-ok ms-2">I agree to Privacy Policy.</span>
            </div>
<!--            <p  class="error" id="err-pp" *ngIf="this.validationErrors[fieldsConfig[2].name]">-->
<!--              {{this.validationErrors[fieldsConfig[2].name]}}-->
<!--            </p>-->
          </div>

        </div> <!--Close modal body-->

        <div class="modal-footer">
          <button  class="border-0 violet-button p-3 rounded-4  w-100"
                   data-bs-dismiss="modal" id="modal-pp-btn"
          >Close</button>
        </div>

      </div> <!--Close modal content-->
    </div> <!--Close modal-dialog-->
  </div> <!--Close modal-->

  <!--        Modal to CRB Privacy policy >-->

  <div class="modal fade " id="modal-ppcrb">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-fullscreen-md-down " >
      <div class="modal-content px-2" >

        <div class="modal-header">
          <h4   class="fw-bold">
            Cross River Bank Privacy Policy
          </h4>

          <button  class="btn-close close-m" data-bs-dismiss="modal"></button>
        </div> <!--Close modal header-->

        <div class="modal-body scroll-lt text-center ">


          <object id="pdf_embed_obj_crb" [data]= this.pfdToEmbed(docsPrivatePolicyCRB) class="d-none d-md-block"
                  width="750" height="400" type="application/pdf">
            <embed id="pdf_embed_obj_crb" [src]=  this.pfdToEmbed(docsPrivatePolicyCRB)
                   type="application/pdf" class="d-none d-md-block"
                   width="750" height="400" />
          </object>



          <div class="my-3 p-button">
            <img id="img_dwld_crb" src="assets/images/legal/legal_doc.svg" class=" button-pointer"
                 (click)="redirectToFile(docsPrivatePolicyCRB)">
            <span class="mb-0" (click)="redirectToFile(docsPrivatePolicyCRB)">Download CRB Privacy Policy</span>
          </div>

          <div class="row" *ngIf="this.fieldValues['esig_disclosure'] == 'true'">
            <div class="form-check d-flex text-center">
              <input
                id="inputPrivacyCRB"
                mdbCheckbox
                class="form-check-input text-center"
                type="checkbox"
                [checked]="fieldValues[fieldsConfig[4].name]"
                (change)="updateFieldValue(this.fieldsConfig[4].name, 'true')"
                (blur)="setFieldToEdited(this.fieldsConfig[4].name)"
              >
              <span class="p-terms-ok ms-2">I agree to CRB Privacy Policy.</span>
            </div>
<!--            <p  class="error" id="err-pp-crb" *ngIf="this.validationErrors[fieldsConfig[4].name]">-->
<!--              {{this.validationErrors[fieldsConfig[4].name]}}-->
<!--            </p>-->
          </div>

        </div> <!--Close modal body-->

        <div class="modal-footer">
          <button  class="border-0 violet-button p-3 rounded-4  w-100"
                   data-bs-dismiss="modal" id="modal-pp-crb-btn"
          >Close</button>
        </div>

      </div> <!--Close modal content-->
    </div> <!--Close modal-dialog-->
  </div> <!--Close modal-->

  <!--        Modal to Greendot Terms >-->

  <div class="modal fade " id="modal-gt">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-fullscreen-md-down " >
      <div class="modal-content px-2" >

        <div class="modal-header">
          <h4   class="fw-bold">Greendot Terms & Conditions</h4>

          <button  class="btn-close close-m" data-bs-dismiss="modal"></button>
        </div> <!--Close modal header-->

        <div class="modal-body scroll-lt text-center ">


          <object id="pdf_embed_obj_gt" [data]= this.pfdToEmbed(docsPrivatePolicyCRB) class="d-none d-md-block"
                  width="750" height="400" type="application/pdf">
            <embed id="pdf_embed_obj_gt" [src]=  this.pfdToEmbed(docsPrivatePolicyCRB)
                   type="application/pdf" class="d-none d-md-block"
                   width="750" height="400" />
          </object>



          <div class="my-3 p-button">
            <img id="img_dwld_gt" src="assets/images/legal/legal_doc.svg" class=" button-pointer"
                 (click)="redirectToFile(termsGreendot)">
            <span class="mb-0" (click)="redirectToFile(termsGreendot)">Download Greendot Terms and Conditions</span>
          </div>

          <div class="row" *ngIf="this.fieldValues['esig_disclosure'] == 'true'">
            <div class="form-check d-flex text-center">
              <input
                id="inputPrivacyGT"
                mdbCheckbox
                class="form-check-input text-center"
                type="checkbox"
                [checked]="fieldValues[fieldsConfig[5].name]"
                (change)="updateFieldValue(this.fieldsConfig[5].name, 'true')"
                (blur)="setFieldToEdited(this.fieldsConfig[5].name)"
              >
              <span class="p-terms-ok ms-2">I agree to Greendot terms and conditions.</span>
            </div>
          </div>

        </div> <!--Close modal body-->

        <div class="modal-footer">
          <button  class="border-0 violet-button p-3 rounded-4  w-100"
                   data-bs-dismiss="modal" id="modal-pp-gt-btn"
          >Close</button>
        </div>

      </div> <!--Close modal content-->
    </div> <!--Close modal-dialog-->
  </div> <!--Close modal-->


  <!--        Modal to USA Patriotic Act >-->

  <div class="modal fade modal-small" id="modal-pa">
    <div class="modal-dialog modal-dialog-scrollable modal-fullscreen-md-down" style="left:0% !important">
      <div class="modal-content px-2" >

        <div class="modal-header">
          <h4   class="fw-bold">
            USA Patriot Act
          </h4>

          <button  class="btn-close close-m" data-bs-dismiss="modal"></button>
        </div> <!--Close modal header-->

        <div class="modal-body scroll-lt">

          <p class="p-terms" >{{this.policy_patriotic_act}}</p>

          <div class="col-10 offset-2" *ngIf="this.fieldValues['esig_disclosure'] == 'true'">
            <div class="form-check" >
              <input
                id="inputPA"
                mdbCheckbox
                class="form-check-input"
                type="checkbox"
                [checked]="fieldValues[fieldsConfig[3].name]"
                (change)="updateFieldValue(this.fieldsConfig[3].name, 'true')"
                (blur)="setFieldToEdited(this.fieldsConfig[3].name)"
              >
              <label class="p-terms-ok">I agree to Privacy Policy.</label>
            </div>
<!--            <p  class="error" id="err-pa" *ngIf="this.validationErrors[fieldsConfig[3].name]">-->
<!--              {{this.validationErrors[fieldsConfig[3].name]}}-->
<!--            </p>-->
          </div>

        </div> <!--Close modal body-->

        <div class="modal-footer">
          <button
            class="border-0 violet-button p-3 rounded-4  w-100"
            data-bs-dismiss="modal" id="modal-pa-btn"
          >Close</button>
        </div>

      </div> <!--Close modal content-->
    </div> <!--Close modal-dialog-->
  </div> <!--Close modal-->



</div> <!--    Close container-->

