import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Recipient, RecipientExtended, RecipientsService} from "../../../../../services/recipients.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {RecipAddNewComponent} from "../../../../Recipients/recip-add-new/recip-add-new.component";
import {RecipientDetailsComponent} from "../../../../Recipients/recipient-details/recipient-details.component";
import {RecipEditComponent} from "../../../../Recipients/recip-edit/recip-edit.component";
import {ZelleRecipAddComponent} from "../zelle-recip-add/zelle-recip-add.component";

@Component({
  selector: 'app-zelle-recipients',
  templateUrl: './zelle-recipients.component.html',
  styleUrls: ['./zelle-recipients.component.css']
})
export class ZelleRecipientsComponent implements OnInit{
  recipients: RecipientExtended[] = [] // CHECK
  recipients_display: RecipientExtended[] = [] // CHECK
  pictures_display !: string[][]

  buttonName: string = "See Recipients"

  search_word !:string

  constructor(
    private recipientsService: RecipientsService,
    public dialog : MatDialog
  ) {}

  filterRecipients(newValue:any):void{

    if(newValue){

      const listName = this.recipients.filter(rec=>rec.first_name.toString().toLowerCase().includes(newValue.toString().toLowerCase()))
      const listNameEmails = listName.map(rec => rec.email)

      const listLastName = this.recipients.filter(rec=>rec.last_name.toString().toLowerCase().includes(newValue.toString().toLowerCase()))
      const listLastNameEmails = listLastName.map(rec => rec.email)

      const allEmails = [...listNameEmails,  ...listLastNameEmails]
      const uniqueEmails = [...new Set(allEmails)]

      this.recipients_display = this.recipients.filter(rec => uniqueEmails.includes(rec.email))

    } else {
      this.recipients_display = (this.recipients.length<=3 ? this.recipients :this.recipients.slice(0,3))
    }

  }


  seeAllRecipients(name: string):void{
    if (name === "See Recipients") {
    //  this.recipientsService.getRecipients().subscribe({ next:(res)=>{
          this.recipients_display = this.recipients
          this.buttonName = "Hide Recipients"
      //  }
      //})
    } else if (name === "Hide Recipients") {

      // this.recipientsService.getRecipients().subscribe({
      //   next:(res)=>{
      //     this.recipients = res.recipients
          this.recipients_display = (this.recipients.length<=3 ?
            this.recipients :this.recipients.slice(0,3))
          this.buttonName = "See Recipients"
      //   }
      // })
    }
  }

  addNewRecipient():void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'full-50-dialog';
    this.dialog.open(ZelleRecipAddComponent,dialogConfig)
  }

  showRecipientDetails(data:string):void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { id: data }
    dialogConfig.panelClass = 'full-40-dialog';
    this.dialog.open(RecipientDetailsComponent,dialogConfig)
  }

  editRecipient(data:Recipient):void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { recipient: data }
    dialogConfig.panelClass = 'full-40-dialog';
    this.dialog.open(RecipEditComponent,dialogConfig)
  }

  ngOnInit() {
    this.recipientsService.getRecipients().subscribe({ next:(res)=>{
        if(res.e_code ==1) {
          this.recipients = res.recipients
          this.recipients_display = (this.recipients.length<=3 ? this.recipients :this.recipients.slice(0,3))
          this.pictures_display = this.recipients.map((rec:RecipientExtended)=>
            [rec.first_name, rec.last_name, rec.img_url, rec.id])
        }}
    })
  }
}

// this.recipients  = [
//   {
//     id: "MOCK_ID_1",
//     email:"recipient1@example.com",
//     first_name: "REC_NAME1",
//     last_name: "REC_SURNAME1",
//     phone: "954-123-4567",
//     img_url: "http://images.com/1",
//     kredio: true,
//     zelle: false,
//     cashapp: false
//   },
//   {
//     id: "MOCK_ID_2",
//     email:"recipient2@example.com",
//     first_name: "REC_NAME2",
//     last_name: "REC_SURNAME2",
//     phone: "954-123-4987",
//     img_url: "http://images.com/2",
//     kredio: true,
//     zelle: false,
//     cashapp: false
//   },
//   {
//     id: "MOCK_ID_3",
//     email:"recipient3@example.com",
//     first_name: "REC_NAME3",
//     last_name: "REC_SURNAME3",
//     phone: "954-123-4567",
//     img_url: "http://images.com/3",
//     kredio: true,
//     zelle: false,
//     cashapp: false
//   },
//   {
//     id: "MOCK_ID_4",
//     email:"recipient4@example.com",
//     first_name: "REC_NAME4",
//     last_name: "REC_SURNAME4",
//     phone: "954-123-4987",
//     img_url: "http://images.com/4",
//     kredio: true,
//     zelle: false,
//     cashapp: false
//   },
//   {
//     id: "MOCK_ID_5",
//     email:"recipient5@example.com",
//     first_name: "REC_NAME5",
//     last_name: "REC_SURNAME5",
//     phone: "954-123-4987",
//     img_url: "http://images.com/5",
//     kredio: true,
//     zelle: false,
//     cashapp: false
//   },
//   {
//     id: "MOCK_ID_6",
//     email:"recipient6@example.com",
//     first_name: "REC_NAME6",
//     last_name: "REC_SURNAME6",
//     phone: "954-123-4987",
//     img_url: "http://images.com/6",
//     kredio: true,
//     zelle: false,
//     cashapp: false
//   },
// ]
