<div class="container-fluid">
  <div class="row py-3 ">
    <div class="text-center">
        <img src="../../../../../assets/images/accounts/success.svg">
    </div>

  </div>
    <div class="row text-center py-3">
      <p class="msgActivate" id="msgActivate">{{activateMsg}}</p>
      <p class="msgActivate-small">Continue to enjoy <strong>{{brand}}</strong> benefits.</p>
    </div>

  <div class="row my-3">
    <div class="offset-2 col-8" >
      <button class="violet-button p-3 w-100 rounded-3" routerLink="/home" >Enjoy your benefits!</button>
    </div>
  </div>
</div>
