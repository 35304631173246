import {Component, Inject} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../environments/environment";
import {animate, style, transition, trigger} from "@angular/animations";
import {slideInUpper} from "../../Shared/animations";

@Component({
  selector: 'app-change-psw-msg',
  templateUrl: './change-psw-msg.component.html',
  styleUrls: ['./change-psw-msg.component.css'],
  animations: [ slideInUpper ],
})
export class ChangePswMsgComponent {
  message !: string
  brand :string = environment.brand

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ChangePswMsgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeMessage():void{
    this.dialogRef.close()
    this.router.navigateByUrl('/')
  }
  ngOnInit( ) {
    this.message = this.data.message
  }

}
