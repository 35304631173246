import {Component, OnInit} from '@angular/core';
import {PaymentRequest, PaymentRequestHisto} from "../../../../../services/req-payments.service";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-pay-req-small',
  templateUrl: './pay-req-small.component.html',
  styleUrls: ['./pay-req-small.component.css']
})
export class PayReqSmallComponent implements OnInit{

  brand : string = environment.brand

  isRequest :boolean = false
  isPaymentsRequest :boolean = false // change

  isFilterPaymentRequest !:boolean

  pending_high !: boolean
  histo_high !:boolean
  search_word !:string
  requests_pending !:PaymentRequest[]
  requests_histo !:PaymentRequestHisto[]
  requests_histo_all !:PaymentRequestHisto[]

  changeClass():void {
    this.isRequest =!this.isRequest
  }

  isFilter():void{
    this.isFilterPaymentRequest = !this.isFilterPaymentRequest
  }

  filterRequests(data:Object){
    let selectedChannels : String [] = []
    let filterObject = new Map(Object.entries(data));
    filterObject.forEach((value, key) => {
      if(value==true) { selectedChannels.push(key) }
    })

    selectedChannels = selectedChannels.map((item:String) => item =='isBank'? this.brand : item)

    this.requests_histo =   this.requests_histo_all
      .filter((req:PaymentRequestHisto)=> selectedChannels.includes(req.channel))

    this.histo_high = this.requests_histo_all.length > 3
  }


  filterAlert(data:string){
    if(data !==undefined){
      const requestList = this.requests_histo_all.filter(a=>a.description.toLowerCase().includes(data.toLowerCase()))
      this.requests_histo = requestList
    } else{
      this.requests_histo = this.requests_histo_all
    }
  }
  addNewRequest():void{
    this.isPaymentsRequest = true
  }
  ngOnInit() {
    this.requests_pending = [
      { name: 'Adam',
        surname: 'Smith',
        date: '10-01-2024',
        amount: '15.00',
        description: "Coffee Date",
      },
      { name: 'Tommy',
        surname: 'Brown',
        date: '10-01-2024',
        amount: '150.00',
        description: "Dinner Date",
      },
      { name: 'Adam',
        surname: 'Smith',
        date: '10-01-2024',
        amount: '15.00',
        description: "Coffee Date",
      },
      { name: 'Tommy',
        surname: 'Brown',
        date: '10-01-2024',
        amount: '150.00',
        description: "Dinner Date",
      }
    ]
    this.pending_high = this.requests_pending.length > 3

    this.requests_histo_all = [
      { name: 'Adam',
        surname: 'Smith',
        date: '10-01-2024',
        amount: '15.00',
        description: "Coffee Date",
        status: "Pending",
        channel: 'Thrive'

      },
      { name: 'Tommy',
        surname: 'Brown',
        date: '10-01-2024',
        amount: '150.00',
        description: "Dinner Date",
        status: "Approved",
        channel: 'Zelle'
      },
      { name: 'Adam',
        surname: 'Smith',
        date: '10-01-2024',
        amount: '15.00',
        description: "Coffee Date",
        status: "Declined",
        channel: 'Zelle'
      },
      { name: 'Tommy',
        surname: 'Brown',
        date: '10-01-2024',
        amount: '150.00',
        description: "Dinner Date",
        status: "Approved",
        channel: 'Thrive'
      }
    ]

    this.requests_histo =  this.requests_histo_all

    this.histo_high = this.requests_histo_all.length > 3
  }
}
