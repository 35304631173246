import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ZelleEnrollComponent} from "../zelle-enroll/zelle-enroll.component";
import {Profile, ProfileService} from "../../../../services/profile.service";
import {Account, AccountService} from "../../../../services/account.service";
import {FormFieldConfig} from "../../../Shared/forms-shared/typesForm";
import {ZelleService} from "../../../../services/zelle.service";
import {environment} from "../../../../../environments/environment";
import {emailOrPhoneValidator, isNotEmptyValidator} from "../../../Shared/forms-shared/validators";
import {AuthService} from "../../../../services/auth.service";

@Component({
  selector: 'app-zelle-setup',
  templateUrl: './zelle-setup.component.html',
  styleUrls: ['./zelle-setup.component.css']
})
export class ZelleSetupComponent  implements OnInit{

  @Input() email !:string
  @Input() phone !:string
  @Input() validateOn: string = 'change'


  isSetupData :boolean = true
  isValidationCode :boolean = false
  verifyCode !:string
  isConfirmZelleData :boolean = false

  sender_contactInfo :string[] = []

  sender_account !:string


  isOtherValid :boolean = false
  brand :string = environment.brand
  accounts_sender !:string[][]

  fieldsConfig !: FormFieldConfig[]
  constructor(

    private accountsService : AccountService,
    private authService: AuthService,
    private zelleService: ZelleService,
    public dialogRef: MatDialogRef<ZelleEnrollComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  fieldWasEdited: any = {}
  validationErrors : any = {}
  _fieldValues: any = {}
  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues:any) {
    this._fieldValues= newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  updateFieldValue(fieldName:string, newValue:any) {
    this.fieldValues = {...this.fieldValues, [fieldName]: newValue}

    if(fieldName == 'other' && newValue == true){
      this.isOtherValid = true
      this.fieldsConfig[5].validators = [isNotEmptyValidator, emailOrPhoneValidator]
      return
    }

  }


  cancelActivate():void{
    this.dialogRef.close()
  }

  continue():void{

    if(this.fieldValues.phone ==false &&
      this.fieldValues.email == false &&
      this.fieldValues.other == false){
      return
    }

    this.validationErrors = this.getvalidationErrors(true)

    if(Object.keys(this.validationErrors).length > 0 )
      return

    this.isSetupData = false
    this.isValidationCode = true
    this.sendCodeAgain()
  }

  getAccounts():void{
    this.accountsService.getAccounts().subscribe({next:(res)=>{
        if(res.e_code == 1){
          this.accounts_sender = res.accounts
            .filter((acc:Account) => acc.is_primary == 1)
            .map((acc:Account)=> [acc.id, this.brand + ' '+ acc.number.toString().slice(-4)])
        }}
    })
  }

  sendCodeAgain(): void{
    if(this.fieldValues.phone_number){
      this.authService.validatePhoneNumber({'phone':this.phone}).subscribe({ next:(res)=>{
        }})}
  }

  onCodeCompleted(code: string) {
    this.verifyCode = code
  }

  continueCode():void{
    this.isSetupData = false
    this.isValidationCode = false
    this.isConfirmZelleData = true

    // Get account sender name
    this.sender_account = this.accounts_sender
        .filter((acc:string[])=> acc[0] == this.fieldValues['account_id'])[0][1]

    // Get sender_contactInfo

    if(this.fieldValues['email']==true){
      this.sender_contactInfo.push(this.email)
    }
    if(this.fieldValues['phone']==true){
      this.sender_contactInfo.push(this.phone)
    }
    if(this.fieldValues['other']==true){
      this.sender_contactInfo.push(this.fieldValues['other_data'])
    }

  }


  ngOnInit() {
    this.getAccounts()

    this.fieldsConfig = this.zelleService.formFieldsZelleSetup
    for (let field of this.fieldsConfig)
      this.fieldValues[field.name] = ''
    this.fieldValues = {...this.fieldValues , ['email'] : false}
    this.fieldValues = {...this.fieldValues , ['phone'] : false}
    this.fieldValues = {...this.fieldValues , ['other'] : false}


  }

}
