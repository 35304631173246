import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { HomeService} from "../../../../services/home.service";
import {AccountService} from "../../../../services/account.service";
import {BenefitsAccount} from "../../../../services/payroll.service";

@Component({
  selector: 'app-coup-disc-summary',
  templateUrl: './coup-disc-summary.component.html',
  styleUrls: ['./coup-disc-summary.component.css']
})
export class CoupDiscSummaryComponent implements OnInit{

  benefitsAccount !:BenefitsAccount
  isEnrolled :boolean = false

  @Input() clickedOption !:string
  @Output() newOption = new EventEmitter<string>()

  constructor(
    private accountsService: AccountService,
    private homeService : HomeService
  ) { }

  current_option !:string

  chooseOption(data:string):void{
    this.current_option = data
    this.newOption.emit(data)
  }

  ngOnInit() {

    if(this.clickedOption) {
      this.current_option = this.clickedOption
    } else {
      this.current_option = 'available'
    }
    this.homeService.getRewardsAccount().subscribe({
      next:(res)=>{
        if (res.e_code===1){
          this.isEnrolled = true
          this.benefitsAccount = res.account
        }
      }})
  }
}
