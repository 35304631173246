import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {appearSlow} from "../../../../Shared/animations";
import {environment} from "../../../../../../environments/environment";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-pay-req-filter',
  templateUrl: './pay-req-filter.component.html',
  styleUrls: ['./pay-req-filter.component.css'],
  animations: [ appearSlow ],
})
export class PayReqFilterComponent {

  brand :string = environment.brand
  filterObject = {
    'Zelle': false,
    'isBank' : false,
  }
  @Output() filterRequests = new EventEmitter<Object>()
  @Output() closeFilter = new EventEmitter<any>()
  constructor(
    // public dialogRef: MatDialogRef<PayReqFilterComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }


  filter(field: string, data: boolean):void{
    this.filterObject = { ...this.filterObject , [field]:data}
    this.filterRequests.emit(this.filterObject)
  }
  cancel():void{
   this.closeFilter.emit()
  }
}
