<div class="container-fluid bg-color-clear rounded-2 px-2">
  <br>

  <div class="row text-center d-none d-md-block">
    <p class="p-title">Configure your contact information</p>
  </div>

  <div class="row d-md-none">
    <p class="p-title-sm-scr">Configure your contact information</p>
  </div>

  <div class="row">
    <p class="p-title-small">Primary Contact</p>
  </div>

  <div class="row text-end  d-none d-md-block button-pointer" (click)="addNewContactInfo()">
    <p class="p-add pe-1" (click)="addNewContactInfo()">+ Add Contact Info</p>
  </div>

  <div *ngIf="isZelleContactData">
    <div class="row my-2 p-2 row-under" *ngFor="let data of zelleDisplaytData ; index as idx">
      <div class="col-10" >
        <span  class="btnSelected align-items-center"  [id]="idx.toString()"  (click)="selectZelleOption(data, idx)" ></span>
        <span  class="p-item ms-2 ps-2  align-items-center" (click)="selectZelleOption(data, idx)" >{{data}}</span>
      </div>
      <div class="col-2 p-0 text-end button-pointer ">
        <img src="../../../../../../assets/images/zelle/zelle_edit.svg"
             (click)="editZelle()" class="img-fluid mx-1" alt="Edit"/>
        <img src="../../../../../../assets/images/zelle/zelle_trash.svg"
             (click)="deleteZelleOption(data)" class="img-fluid mx-1" alt="Delete"/>
      </div>
    </div>
  </div>

  <br>

  <div class="row text-center  d-md-none button-pointer" (click)="addNewContactInfo()">
    <p class="p-add pe-1" (click)="addNewContactInfo()">+ Add Contact Info</p>
  </div>

  <br>
  <br>
  <br>

  <div class="row mb-2">
    <div class="col-12 offset-lg-2 col-lg-4">
      <button class="violet-button rounded-3 w-100 py-3 my-2"
              (click)="saveZelleChanges()">Save</button>
    </div>
    <div class="col-12  col-lg-4">
      <button class="yellow-button rounded-3 w-100 py-3 my-2"
              (click)="cancelChanges()">Cancel</button>
    </div>
  </div>

</div>

