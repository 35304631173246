<div class="container-fluid h-100">
      <br>
      <br>

      <!-- Logo appears in small screen -->
      <div class="row d-lg-none my-3 px-1" >
        <img class="img-fluid " [src]= brand_img alt="Logo">
      </div>

      <!--New Account title: Small screen-->
      <div class="row  d-lg-none py-5 " >
        <p class="p-title mb-0 py-2 ">New Account</p>
      </div>

      <!--New Account title: Large screen-->
      <div class="row  d-none d-lg-block  " >
        <p class="p-title mb-0 py-2">New Account</p>
      </div>

      <!-- Create account credentials credentials-->
      <div class="row h-50 ">
        <div class="h-100">
          <div class="form-floating my-3">
            <input
              [class] ="validationErrors[fieldsConfig[0].name]? 'form-control formError': 'form-control'"
              [type]="fieldsConfig[0].type"
              [placeholder]="fieldsConfig[0].placeHolder"
              [ngModel]="fieldValues[fieldsConfig[0].name]"
              (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
              (blur)="setFieldToEdited(fieldsConfig[0].name)"
              [id]="'emailInput'"
            >
            <label [class]="validationErrors[fieldsConfig[0].name]? 'form-label labelError ms-2': 'form-label ms-2'"
            >{{fieldsConfig[0].displayName}}</label>
          </div>
          <p  class="error my-1" id ="emailError" *ngIf="this.validationErrors[fieldsConfig[0].name]">
            {{this.validationErrors[fieldsConfig[0].name]}}
          </p>

          <div class="form-floating my-3">
            <input
              [class] ="validationErrors[fieldsConfig[1].name]? 'form-control formError': 'form-control'"
              [type]="fieldsConfig[1].type"
              [placeholder]="fieldsConfig[1].placeHolder"
              [ngModel]="fieldValues[fieldsConfig[1].name]"
              (ngModelChange)="updateFieldValue(fieldsConfig[1].name, $event)"
              (blur)="setFieldToEdited(fieldsConfig[1].name)"
              [id]="'pswdInput'"
            >
            <label [class]="validationErrors[fieldsConfig[1].name]? 'form-label labelError ms-2': 'form-label ms-2'"
            >{{fieldsConfig[1].placeHolder}}</label>
          </div>
          <p class="error my-1"
             id="pswdError"
             *ngIf="this.validationErrors[fieldsConfig[1].name]">
            {{this.validationErrors[fieldsConfig[1].name]}}
          </p>

          <div class="form-floating my-3">
            <input
              [class] ="validationErrors[fieldsConfig[2].name]? 'form-control formError': 'form-control'"
              [type]="fieldsConfig[2].type"
              [placeholder]="fieldsConfig[2].placeHolder"
              [ngModel]="fieldValues[fieldsConfig[2].name]"
              (ngModelChange)="updateFieldValue(fieldsConfig[2].name, $event)"
              (blur)="setFieldToEdited(fieldsConfig[2].name)"
              [id]="'pswd2Input'"
            >
            <label [class]="validationErrors[fieldsConfig[2].name]? 'form-label labelError ms-2': 'form-label ms-2'"
            >{{fieldsConfig[2].placeHolder}}</label>
          </div>
          <p class="error my-1"
             id="pswd2Error"
             *ngIf="this.validationErrors[fieldsConfig[2].name]">
            {{this.validationErrors[fieldsConfig[2].name]}}
          </p>

          <div class="  w-100 p-wording">
            <p class="py-3"> Password must contain at least eight characters, one uppercase letter,
              one digit and one special character
            </p>
          </div>

          <p class="error my-1 "><strong>{{this.errorSignUp}}</strong></p>
        </div>
      </div>

      <div class="row ">
        <div class="text-center" >
          <button class="violet-button rounded-4 w-100 py-3 my-2 "
                  id="createAccountBtn"
                  (click)="submitForm()">
            Create Account
          </button>
          <button id="cancelBtn" class="yellow-button rounded-4 w-100 py-3 my-2" routerLink="/">
            I already have an account!
          </button>
        </div>
      </div>


</div> <!--    Close container-->

