import {Component, OnInit} from '@angular/core';
import {environment} from "../../../../../../environments/environment";
import {RecipientExtended, RecipientsService} from "../../../../../services/recipients.service";

@Component({
  selector: 'app-pay-req-feqrecips',
  templateUrl: './pay-req-feqrecips.component.html',
  styleUrls: ['./pay-req-feqrecips.component.css']
})
export class PayReqFeqrecipsComponent implements OnInit{

  brand : string = environment.brand
  recipients!: RecipientExtended[]
  recipients_display!: RecipientExtended[]

  constructor(
    private recipientsService: RecipientsService,
  ) {}


  ngOnInit() {
    this.recipientsService.getRecipients().subscribe({ next:(res)=>{
        if(res.e_code === 1) {
          this.recipients = res.recipients
          this.recipients_display = (this.recipients.length<=3 ? this.recipients :this.recipients.slice(0,3))
        }
      }})
  }

}
