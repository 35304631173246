import {Component, OnDestroy, OnInit} from '@angular/core';
import {fromEvent, Subject, takeUntil} from "rxjs";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {ProfileService} from "../../../services/profile.service";
import {animate, style, transition, trigger} from "@angular/animations";
import {slideInLeft, slideInLower, slideInRight, slideInUpper} from "../../Shared/animations";

@Component({
  selector: 'app-signup-main',
  templateUrl: './signup-main.component.html',
  styleUrls: ['./signup-main.component.css'],
  animations: [ slideInLeft, slideInLower, slideInRight, slideInUpper ],
})
export class SignupMainComponent  implements OnInit {

  brand_img  :string = 'assets/images/bank_imgs/logo_' + environment.brand + '.svg'
  currentRoute !:string
  docsPrivatePolicy !:string
  docsPrivatePolicyCRB !:string

  constructor(
    private router: Router,
    private profileService:ProfileService
  ) {}


  getLegalTerms():void{
    this.profileService.getLegalDicsKYC().subscribe({
      next:(res)=>{
        this.docsPrivatePolicy = res.res[2].doc
        this.docsPrivatePolicyCRB = res.res[4].doc
      }})
  }

  redirectToFile(url:string){
    window.open(url, "_blank");
  }

  ngOnInit(): void {
    this.currentRoute = this.router.url
    this.getLegalTerms()
  }



}
