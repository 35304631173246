<div class="container-fluid w-100 bg-color-clear ">
  <br>
  <br>

  <div class="row py-1">
    <div class="col-9 offset-1 d-flex align-items-center text-center">
      <p class="fw-bold mb-0 p-title-coupons" >Get cashback by choosing great deals tailored for you</p>
    </div>
    <div id="btnClose" class="col-1 p-0  offset-1" (click)="closeCoupons()">
            <img src="../../../../../assets/images/coupons/close_button.svg" class="img-fluid">
        </div>
  </div>

  <div class="row text-center">
    <div class="col-10 offset-1">

      <div class="py-3">
        <img src="../../../../../assets/images/coupons/cs_symbol.svg" class="img-fluid">
      </div>
      <p class="p-wording mb-0 fw-bold">{{brand}} coupons and discounts are the easiest way to earn cash back from
        places you already know
      </p>

    </div>
  </div>

  <div class="row ms-2 my-3 ">
    <div class="col-10 offset-1">
        <ul>
          <li class="p-wording  py-3">Free to online and mobile customers</li>
          <li class="p-wording   my-3">No obligation or sign up required</li>
          <li class="p-wording   py-3">Cashback will be put into your account</li>
        </ul>
    </div>
  </div>

  <div class="row py-3">
    <div class="col-12 offset-md-3 col-md-6 px-2">
      <button id="dealsButton"  class="violet-button rounded-4 w-100 py-3 border-0"
        (click)="navigateFc()"  > View your Deals</button>
    </div>
  </div>

</div>

