import {Component, Input} from '@angular/core';
import {PayrollRecord} from "../../../../services/payroll.service";

@Component({
  selector: 'app-payroll-display',
  templateUrl: './payroll-display.component.html',
  styleUrls: ['./payroll-display.component.css']
})
export class PayrollDisplayComponent {

  @Input() payroll_histo!:PayrollRecord[]
  @Input() payrollHisto_display :PayrollRecord[] = []

  showAll :boolean = false
  buttonName : string = 'See All Activity'
  seeAllActivity(): void {
    this.showAll = !this.showAll
    this.payrollHisto_display = (this.showAll == true) ? this.payroll_histo : [this.payroll_histo[0],
      this.payroll_histo[1], this.payroll_histo[2]]

    this.buttonName = (this.showAll == true) ? "Hide Activity" : "See All Activity"
  }
}
