<div class="container-fluid bg-color-clear ">
  <!-- First narrow bar: Account, Cash Back, Live Payroll -->

  <div class="row m-3 p-3 rounded-3  bg-color-clear">

    <div class="row">
      <p class="p-title mb-0">Frequent Recipients</p>
    </div>

    <div *ngIf="pictures_display"  class="row p-0 my-2" >
      <div class="d-flex scroll-pics">
        <div *ngFor="let pic of pictures_display" class="d-flex">
          <div class="m-2  d-flex"  (click)="showRecipientDetails(pic[4])" >
            <div class="text-center p-0 bg-color-clear" style="width: var(--recipient-card-size);)">
              <div class="text-center p-0">
                <img *ngIf="pic[2] != null " src="{{pic[2]}}" class="img-fluid img-radius">
                <img *ngIf="pic[2] == null" src="../../../../../../assets/images/coupons/logo_here.svg" class="img-fluid img-radius">
              </div>
              <p  class="p-freq-rec  m-0">{{pic[0]}}</p>
            </div> <!--close card body-->
          </div><!--close card-->
        </div>
      </div>
    </div>

    <div class="row mx-0 my-2 px-0"  >

      <div class="col-md-9 col-12 p-0 my-2 "  >
        <div class="form  h-100 "  >

          <input class ='form-control inputStyle h-100 py-3'
                 placeholder='Search'
                 [ngModel]="search_word"
                 (ngModelChange)="filterRecipients( $event)"
                 style="background: #F5F5F2 url('../../../../../../assets/images/accounts/magnifier.svg') 95%  no-repeat"
          >
        </div>  <!--Close form -->
      </div> <!--Close col -->

      <div class="col-md-3 col-12 px-2  my-2" >
        <button class="button-new-recipient rounded-4 h-100 w-100 py-3"
                (click)="addNewRecipient()"
                title="Add New Recipient">
          + Add New Recipient
        </button>
      </div>

    </div> <!--Close row-->

    <div class="row mx-0 my-2 px-0">

      <div class="col-lg-12 col-10 d-flex align-items-center" >
        <p class="p-title mb-0">All Recipients</p>
      </div>

      <div class="col-2  p-1  d-md-none d-lg-none text-center" >
        <img src="../../../../../../assets/images/recipients/filter_recipients.svg"
             class="img-fluid "
             alt="Filter">
      </div>

    </div>

    <!-- Display Big Screens -->

    <div *ngIf="recipients_display" class="h-100 d-none d-md-block" >
      <div [ngClass]="recipients_display.length>3 ?  'scroll-recipients' : ''">
        <table class="table">
          <tbody *ngFor="let p of recipients_display">
          <tr >
            <td class="td-name p-0 m-0" >
              <img class="" src="../../../../../../assets/images/recipients/friend.svg">
              {{p.first_name}} {{p.last_name}}
            </td>
            <td class="td-email " >{{p.email}}</td>
            <td class="td-phone " >{{p.phone}}</td>

            <td  class=" p-0 m-0 text-center align-content-center button-pointer "  >
              <img
                id="editRecipient"
                (click)="editRecipient(p)"
                class="img-show"
                title="Edit"
                src="../../../../../../assets/images/rec-payments/edit_button.svg" >
            </td>

            <td  class=" p-0 m-0 text-center align-content-center button-pointer "  >
              <img
                id="showDetails"
                (click)="showRecipientDetails(p.id)"
                class="img-show"
                title="Show Details"
                src="../../../../../../assets/images/recipients/three_dots.svg">
            </td>
          </tr>

          </tbody>
        </table>
      </div>

      <div class="button-border text-center">
        <button  class="mt-2 mb-1 button-toggle border-0"
                 id="btnSeeAllRecipients"
                 (click)="seeAllRecipients(buttonName)">
          {{buttonName}}
        </button>
      </div>


    </div>  <!-- closes recipients_list -->

    <!-- Display Responsive: Small Screens -->

    <div *ngIf="recipients_display" class=" d-block d-md-none" >

      <div [ngClass]=" recipients_display.length>3 ? 'scroll-recipients-small' : '' ">
        <div class=" row" *ngFor="let p of recipients_display">
          <div class="col-8   text-start ps-3" >
            <p class="td-name-small mb-0">{{p.first_name}} {{p.last_name}}</p>
            <div *ngIf="p.email!==''">
              <p class="td-email-small">{{p.email}}</p>
            </div>
            <div *ngIf=" p.email==''">
              <p class="td-email-small">{{p.phone}}</p>
            </div>
          </div>
          <div class="col-2 p-1 align-content-center text-center" >
            <img src="../../../../../../assets/images/rec-payments/edit_button.svg"
                 (click)="editRecipient(p)"
                 class="img-fluid m-0"
                 alt="See Details"
            >
          </div>
          <div class="col-1 p-1 align-content-center text-center" >
            <img src="../../../../../../assets/images/recipients/more_button.svg"
                 (click)="showRecipientDetails(p.id)"
                 class="img-fluid m-0"
                 alt="See Details"
            >
          </div>

        </div>
      </div>

      <div class="button-border">
        <button class="button-activity py-2" (click)="seeAllRecipients(buttonName)">
          {{buttonName}}
        </button>
      </div>
    </div>  <!-- closes recipients_list -->


  </div>

  <div class="divider-from-nav d-lg-none d-block" style="height: 5rem;"></div>


</div>
