import {Component, OnInit} from '@angular/core';
import {ProfileService} from "../../../services/profile.service";
import {environment} from "../../../../environments/environment";
import {slideInLeft, slideInLower, slideInUpper} from "../../Shared/animations";

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css'],
  animations : [slideInUpper,slideInLower,slideInLeft]
})

export class WelcomeComponent implements OnInit{

  brand_img  :string = 'assets/images/bank_imgs/welcome_logo_' + environment.brand + '.svg'
  docsPrivatePolicy !:string
  docsPrivatePolicyCRB !:string
  constructor(
    private profileService:ProfileService
  ) { }
  getLegalTerms():void{
    this.profileService.getLegalDicsKYC().subscribe({
      next:(res)=>{
        this.docsPrivatePolicy = res.res[2].doc
        this.docsPrivatePolicyCRB = res.res[4].doc
      }})
  }

  redirectToFile(url:string){
    window.open(url, "_blank");
  }
  ngOnInit() {
    sessionStorage.clear();
    this.getLegalTerms()
  }

}
