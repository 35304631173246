<div class="container-fluid">
  <div class="row mt-2">

    <div class="col-md-1 col-2 d-flex justify-content-center  p-0">
      <div class="w-100  h-100">
        <img class="img-fluid h-100" src="assets/images/pay_tf/zellePic.svg" alt="Card Img">
      </div>
    </div>

    <div class="col-md-10 col-8 p-0 d-flex align-items-center" >
      <p class="p-title  p-0 mb-0">Zelle Setup</p>
    </div>

    <div class="col-md-1 col-2  p-0 d-flex align-items-center justify-content-center button-pointer" >
      <img class=" w-75 img-fluid"  (click)="cancelActivate()"
           src="assets/images/edit_profile/cancel_edit.svg"/>
    </div>

  </div>

  <br>

  <!--  Set up Zelle-->
  <div  *ngIf="isSetupData" class="p-0 m-0" >
    <div class="row">
      <p class="p-title">Contact Info</p>
      <p class="p-sub-title">Choose the email or mobile number you want to send and receive money</p>
    </div>

    <div class="row px-2 my-4">
      <div class="col-1 d-flex justify-content-end">
              <span [ngClass]="fieldValues['email'] ? 'termsSelected mx-1': 'termsSelect mx-1' "
                    (click)="updateFieldValue('email', !fieldValues['email'])"></span>
      </div>
      <div class="col-11 px-0 ">
        <p class="p-under mb-0">Use your email: <strong>{{this.email}}</strong></p>
      </div>
    </div>

    <div class="row px-2 my-4">
      <div class="col-1 d-flex justify-content-end">
              <span [ngClass]="fieldValues['phone']? 'termsSelected mx-1': 'termsSelect mx-1' "
                    (click)="updateFieldValue('phone', !fieldValues['phone'])"></span>
      </div>
      <div class="col-11 px-0 ">
        <p class="p-under mb-0">Use your phone: <strong>{{this.phone}}</strong></p>
      </div>
    </div>

    <div class="row px-2 my-4">
      <div class="col-1 d-flex justify-content-end">
              <span [ngClass]="fieldValues['other']? 'termsSelected mx-1': 'termsSelect mx-1' "
                    (click)="updateFieldValue('other', !fieldValues['other'])"></span>
      </div>
      <div class="col-11 px-0 ">
        <p class="p-under mb-0">Use other email or phone number</p>
      </div>
    </div>

    <div *ngIf="isOtherValid" class="row">
      <div class="col-12">
        <div class="form-floating">
          <input
            [id] ="'inputName'"
            [class] ="validationErrors[fieldsConfig[5].name]? 'form-control formError': 'form-control'"
            [placeholder]="fieldsConfig[5].placeHolder"
            [type]="fieldsConfig[5].type"
            [ngModel]="fieldValues[fieldsConfig[5].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[5].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[5].name)"
          >
          <label class="form-label">{{fieldsConfig[5].displayName}}</label>
        </div>
        <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[5].name]">
          {{this.validationErrors[fieldsConfig[5].name]}}
        </p>
      </div>

    </div>


    <br>
    <div class="row">
      <p class="p-title">Deposit Account</p>
      <p class="p-sub-title">Select the primary account you want to use to send and receive money</p>
    </div>

    <div class="row">
      <div class="col-12 my-2 ">
        <div class="form-floating ">
          <select
            id="sender"
            [class] ="validationErrors[fieldsConfig[2].name]? 'form-control formError ': 'form-control '"
            [ngModel]="fieldValues[fieldsConfig[2].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[2].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[2].name)"
          >
            <option [ngValue]="undefined" [disabled] = "true" [selected]="true" >Select Account</option>
            <option  *ngFor="let option of accounts_sender" [value] = option[0] >{{option[1]}}</option>



          </select>
          <label class="form-label" >{{fieldsConfig[2].displayName}}</label>
        </div>
        <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[2].name]"> {{this.validationErrors[fieldsConfig[2].name]}}
      </div> <!-- First Col ends -->

    </div>

    <br>
    <div class="row ">
      <div class="row px-2 my-2">
        <div class="col-1 d-flex justify-content-end">
                <span [ngClass]="fieldValues['zelle_terms'] ? 'termsSelected mx-1': 'termsSelect mx-1' "
                      (click)="updateFieldValue('zelle_terms', !fieldValues['zelle_terms'])"></span>
        </div>
        <div class="col-11 px-0 ">
          <p class="p-under mb-0">I have received, read and consent to the <strong>Add New Recipient</strong>
            policy governing my use of <strong>Zelle</strong> </p>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div  class="col-lg-6 offset-3 col-12 text-center my-2"  (click)="continue()">
        <button class="violet-button py-3 px-5 w-100 rounded-3 border-0" (click)="continue()">Continue</button>
      </div>
    </div>

  </div>

  <!--  Validation Code-->
  <div *ngIf="isValidationCode" class="p-0 m-0">

    <div class="row">
      <p class="p-title">Enter Verification Code</p>
      <p class="p-sub-title">Introduce the code that was sent to <strong>{{phone}}</strong> </p>
    </div>

    <div class="row my-3">
      <div class="col-8 offset-2 px-0">
        <code-input
          id="inputCode"
          [isCodeHidden]="true"
          [codeLength]="4"
          (codeCompleted)="onCodeCompleted($event)">
        </code-input>
      </div>

    </div>

    <div class="row ms-1 mb-3">
      <p class="p-resend  fw-bold" ><img (click)="sendCodeAgain()" id="imgResend"
                                         src="../../../../assets/images/signup/send_again.svg"
                                         class="img-fluid button-pointer"><span class="ps-2">Send code again</span> </p>
    </div>

    <div class="row mb-2">
      <div  class="col-lg-6 offset-3 col-12 text-center my-2"  (click)="continueCode()">
        <button class="violet-button py-3 px-5 w-100 rounded-3 border-0" (click)="continueCode()">Continue</button>
      </div>
    </div>

  </div>

  <!--  Confirm Information -->
  <div *ngIf="isConfirmZelleData" class="p-0 m-0">

    <div class="row text-center">
      <div class="my-1">
        <img src="assets/images/zelle/success_check.svg">
      </div>
      <div class="my-1 ">
        <p class="p-title-success">Zelle is ready to use!</p>
      </div>
    </div>

    <div class="row my-3">
      <div class="col-12 col-lg-6 text-center ">
        <p class="p-summary"  >Contact Info</p>
        <div class="row rounded-4 p-2 row-border"  >
          <p *ngFor="let sender of sender_contactInfo" class="mt-1">{{sender}}</p>
        </div>
      </div>
      <div class="col-12 col-lg-6 text-center ">
        <p class="p-summary">Deposit Account</p>
        <div class="row rounded-4 p-2 row-border">
          <p class="mb-0">{{this.sender_account}}</p>
        </div>
      </div>
    </div>

    <div class="row my-2">
      <div  class="col-lg-6 offset-3 col-12 text-center my-2"  (click)="cancelActivate()">
        <button class="violet-button py-3 px-5 w-100 rounded-3 border-0" (click)="cancelActivate()">Continue</button>
      </div>
    </div>

  </div>

  <br>
</div>
