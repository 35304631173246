import {Component, Inject, Input} from '@angular/core';
import {AuthService} from "../../../services/auth.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../environments/environment";
import {appearSlow} from "../../Shared/animations";

@Component({
  selector: 'app-error-dialog-msg',
  templateUrl: './error-dialog-msg.component.html',
  styleUrls: ['./error-dialog-msg.component.css'],
  animations: [ appearSlow ],
})
export class ErrorDialogMsgComponent {
  title !: string
  status !: number
  statusText !: string
  url!: string
  message !: string

  brand :string = environment.brand
  constructor(
   public dialogRef: MatDialogRef<ErrorDialogMsgComponent>,
   public authService: AuthService,
   @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeMessage():void{
    this.dialogRef.close()
    this.authService.logout()
  }
  ngOnInit( ) {

    this.status = this.data.status|| 500
    this.statusText = this.data.e_msg || "We were unable to process your request"
  }
}
