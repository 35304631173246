<div class="container-fluid h-75 d-flex flex-column justify-content-evenly redLines" >

  <div id="0" class="row  px-0 my-1  " *ngFor="let rec of recipients_display ">

    <div class="col-1 text-center p-0">
      <img *ngIf="rec.zelle == true" [src]="'assets/images/paym-request/zelle_logo.svg'" class="img-fluid ">
      <img *ngIf="rec.kredio == true " [src]="'assets/images/paym-request/logo_' + brand + '.svg'" class="img-fluid ">
    </div>

    <div class="col-8 px-0">
      <p id="fullName" class="fr-data mx-0 my-0 ms-2" >{{rec.first_name}} {{rec.last_name}}</p>
      <p id="email" *ngIf="rec.email" class="fr-source  ms-2" >{{rec.email.toString().split('@')[0]}}</p>
      <p id="phone" *ngIf="!rec.email" class="fr-source  ms-2" >{{rec.phone.toString()}}</p>
    </div>
    <div class="col-3">

    </div>
  </div>  <!--Close row-->

</div>  <!--Close container-->

