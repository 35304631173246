<div class="container-fluid h-100">
      <app-nav-buttons
        [current_route]= your_route
      ></app-nav-buttons>

      <!--Clients NOT enrolled to credit score-->

      <div *ngIf="!creditScoreData.isEnrolled">

        <app-modal>

          <div class="row m-1" >

            <div class="col-10 d-flex align-items-center p-0">
              <img src="assets/images/credit_score/cm_logo.svg" class="img-fluid" >
              <p class="p-title-ne mb-0">Credit Monitoring</p>
            </div>
            <div class="col-2 d-flex justify-content-around p-0">
              <img (click)="cancelEnrollToCreditScore()"
                   src="assets/images/credit_score/close_button.svg" class="img-fluid " >
            </div>
          </div> <!--Close row-->

          <div class="row" >

             <div class="col-10 offset-1">

               <div class="row mx-1 my-4 "  >
                 <div class="text-center" >
                   <img class="img-fluid my-3" src="assets/images/credit_score/dollar_logo.svg">
                   <p class="p-middle-ne">Checking your credit score just got easier with <strong>{{brand}}</strong></p>
                   <p class="p-middle-sm">
                     Monitor your credit score through <strong>{{brand}}</strong> online banking or mobile app for free.
                     You can check your credit score with no limits.
                   </p>
                 </div>
               </div> <!--Close row-->

               <div class="row m-1"   >

                 <div class="col-1 p-0 d-flex justify-content-around align-items-center" >
                   <img  src="assets/images/cards/card_bullet_point.svg"
                         alt="bullet" class="img-fluid"  >
                 </div>

                 <div class="col-11" >
                   <p class="p-checckbox mb-0" >SEE YOUR SCORE ANYWHERE</p>
                   <p class="p-checckbox-l">Your score is updated on a monthly basis with the latest information</p>
                 </div>

               </div> <!--Close row-->

               <div class="row m-1">

                 <div class="col-1 p-0 d-flex justify-content-around align-items-center">
                   <img  src="assets/images/cards/card_bullet_point.svg"
                         alt="bullet" class="img-fluid" >
                 </div>

                 <div class="col-11" >
                   <p class="p-checckbox mb-0" >CHECK YOUR PROGRESS</p>
                   <p class="p-checckbox-l" >Check up to 12 months of your credit score history</p>
                 </div>

               </div> <!--Close row-->


               <div class="row my-3 d-flex justify-content-center" >
                 <div class="col-lg-4 col-6 text-center p-0" >
                   <button class="button-enroll rounded-3 w-100 "
                           (click)="enrollToCreditScore()"
                   >Enroll</button>
                 </div>
               </div> <!--Close row-->
             </div>

             </div>



        </app-modal>

      </div> <!--Close ngif-->

      <!--Clients enrolled to credit score-->

      <div *ngIf="creditScoreData.isEnrolled">
        <div class="row background_row rounded-4 my-3 mx-3 p-3" >

          <div class="col-lg-4  col-12 d-flex justify-content-center align-middle py-1" >
            <div class="row">
              <div class="text-center">
                <p class="title">Welcome Back! Your latest score is:</p>
                <p class="sec-title">Latest update: {{creditScoreData.date_updated}}</p>
              </div>
              <div class="h-50 d-flex justify-content-center">
                <div class="round-score d-flex justify-content-center align-items-center">
                  <div class="text-center py-1">
                    <p class="score mt-2 mb-0">{{creditScoreData.rating}}</p>
                    <p [ngClass]="score_class">{{creditScoreData.ratingText}}</p>
                  </div>
                </div>
              </div>
            </div> <!--Close row-->
          </div> <!--Close col4-->

          <div class="col-lg-5 col-12 my-lg-0 my-3"  >
            <div class="text-center">
              <p class="p-message">Your credit score is in {{this.message}} shape!</p>
            </div>
            <div class="row">
              <div class="col-6">
                <p class="p-message" >{{this.creditScoreData.rating}}/800</p>
              </div>
              <div class="col-6 d-flex justify-content-end">
                <p class="p-message" >{{this.message.toUpperCase()}}</p>
              </div>
            </div> <!--Close row-->
            <div class="row">
              <div class="progress h-100">
                <div class="progress-bar" style="width: 48%; background-color: #B7B6FB">300</div>
                <div class="progress-bar" style="width: 16% ; background-color: #9492F9">580</div>
                <div class="progress-bar" style="width: 12%; background-color: #706DF7">670</div>
                <div class="progress-bar" style="width: 10% ; background-color: #4C49F5">740</div>
                <div class="progress-bar" style="width: 14% ;background-color: #3D3BC8">800</div>
              </div> <!--Close progress bar-->

              <div class="text-center mt-2">
                <p class="p-brand mb-0"> Providedp by TransUnion</p>
              </div>
            </div> <!--Close row-->
          </div> <!--Close col-4-->

          <div class="col-lg-3 col-12 py-3">
            <div class="row text-center h-100 mt-3">
              <div (click)="clickedButton('View PDF')">
                <img src="assets/images/credit_score/view_pdf.svg" class="img-fluid">
              </div>
              <div (click)="clickedButton('Dispute Credit')">
                <img src="assets/images/credit_score/dispute_credit.svg" class="img-fluid">
              </div>
            </div>
          </div> <!--Close col4-->

        </div> <!--Close First row-->

        <div class="row background_row rounded-4 p-2  mx-3 mb-5" >
          <div class="row" >
            <p class="p-chart">Credit Score History</p>
          </div>

          <div class="row">
            <div class="chart-container  mx-3 " style="position: relative ; height: 50vh">
              <canvas baseChart
                      style=" height: 100%"
                      [type]="'line'"
                      [legend]="false"
                      [datasets]="chartData"
                      [labels]="chartLabels"
                      [options]="chartOptions"
              ></canvas>
            </div>
          </div> <!--Close row-->

        </div> <!--Close row-->

      </div> <!--Close ngif enrolled-->

      <div class="d-lg-none d-block" style="height: 5rem;"></div>

</div>   <!--Close container-->
