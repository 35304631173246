<div class="container-fluid" [@appearSlow] >

<!--  Enter security code-->
  <div *ngIf="!isSecurityCode">

    <div class="row my-3 d-flex justify-content-center" >

      <div class="col-12">
        <p class="p-personalInfo">Enter the verification code</p>
      </div>

      <div class="col-12 my-2">
        <p class="p-middle">Enter the 4-digit code that was sent to the phone number provided before</p>
      </div>

      <div class="col-12 px-0">
        <code-input
          id="inputCode"
          [isCodeHidden]="true"
          [codeLength]="4"
          (codeCompleted)="onCodeCompleted($event)">
        </code-input>
      </div>

      <div class="col-11 my-2 ">
        <p id="errorMsg" class="error my-1" *ngIf="this.validationErrors[fieldsConfig[1].name]">
          {{this.validationErrors[fieldsConfig[1].name]}}
        </p>
      </div>

      <div class="row my-3">
        <div id="sendCodeAgain" (click)="sendCodeAgain()">
          <img (click)="sendCodeAgain()"
               src="../../../../assets/images/signup/send_again.svg"
               class="img-fluid button-pointer">
        </div>
        <p class="p-resend my-2 fw-bold" >Send code again</p>
      </div>

    </div>

    <div class="row">
      <div class="col-12 px-2 my-5 ">
        <button id="btnConfirm" (click)="activatePhone()" class="violet-button rounded-4 w-100 py-3 ">
          Confirm Code
        </button>
      </div>
    </div>

  </div>

  <!-- Security code was requested-->

  <div *ngIf="isSecurityCode">

    <div *ngIf="successPhoneCode">
      <div class="row">
        <div class="offset-2 col-8 text-center py-3  ">
          <img  class=" img-fluid"  src="assets/images/edit_profile/success_logo.svg" />
        </div>

        <div class="offset-2 col-8 p-0 ">
          <p id="p-res-1" class="p-response text-center fw-bold my-2">Your new phone number is now active.</p>
          <p id="p-res-2" class="p-response text-center fw-bold my-2">Close and continue to {{brand}}.</p>
        </div>
      </div>
      <div class="row">
        <div class="d-flex justify-content-center ">
          <button id="btnClose" (click)="cancelEditPhone()" class="violet-button rounded-4 w-100 py-3 m-2 ">
            Close
          </button>
        </div>
      </div>

    </div>

  </div>


</div>



