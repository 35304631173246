import {Component, Input} from '@angular/core';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-spinn-wheel',
  templateUrl: './spinn-wheel.component.html',
  styleUrls: ['./spinn-wheel.component.css']
})
export class SpinnWheelComponent {
  @Input() message !:string

  brand_img  :string = 'assets/images/bank_imgs/spinn_logo_' + environment.brand + '.svg'

}
