<div class="container-fluid py-2" [@appearSlow] >
    <div class="row text-center my-5" >
    <div>
      <img src="../../../../assets/images/accounts/warning.svg" alt="warning">
    </div>
  </div>

    <div class="row text-center mt-3">
      <div class="offset-1 col-10">
        <p id="message" class="p-msg my-4 ">{{this.title}}</p>
      </div>
    </div>

  <div class="row text-center pb-3">
    <p id="message-small-msg" class="p-msg-small px-3">{{this.message}}</p>
  </div>

    <div class="row text-center my-4 ">
      <p id="message-small" class="p-msg-small  ">Close and continue to <strong>{{brand}}</strong></p>
    </div>

    <div  class="row text-center px-3 my-3">
    <button class="violet-button py-3 px-5 w-100 rounded-4 border-0" id="btnClose"
            (click)="closeCardsMsg()">Close</button>
  </div>
</div>
