<div class="container-fluid  h-100 ">
  <app-nav-buttons [current_route]= your_route ></app-nav-buttons>

  <div class="row mt-3 px-lg-3 h-75 d-none d-md-block" >
    <div class="col-12 bg-color-clear rounded-2 h-100">
      <div class="row">
          <div id="divSend" class="col-3 col- div-hover "
               (click)="chooseOption('send')">
            <p id="pSend" class="p-title w-100 mx-0 text-center py-2"
               [ngClass]="{'p-clicked' : current_option ==='send'}" >Send Money</p>
          </div>
          <div id="divRequest" class="col-2   div-hover "
               (click)="chooseOption('request')">
            <p  id="pRequest" class="p-title w-100 mx-0 text-center py-2"
                [ngClass]="{'p-clicked' : current_option ==='request'}">Request Money</p>
          </div>
          <div id="divActivity" class="col-2 div-hover "
               (click)="chooseOption('activity')">
            <p id="pActivity" class="p-title w-100 mx-0 text-center py-2"
               [ngClass]="{'p-clicked' : current_option ==='activity'}">Activity</p>
          </div>
          <div id="divRecipients" class="col-3   div-hover"
               (click)="chooseOption('recipients')">
            <p id="pRecipients" class="p-title w-100 mx-0 text-center py-2"
               [ngClass]="{'p-clicked' : current_option ==='recipients'}"
            >Recipients</p>
          </div>
          <div id="divSettings" class="col-2   div-hover"
               (click)="chooseOption('settings')">
            <p id="pSettings" class="p-title w-100 mx-0 text-center py-2"
               [ngClass]="{'p-clicked' : current_option ==='settings'}"
            >Settings</p>
          </div>
        </div>

      <div *ngIf="current_option =='recipients'" class="row" >
        <app-zelle-recipients></app-zelle-recipients>
      </div>

      <div *ngIf="current_option =='send'" class="row" >
        <app-zelle-send></app-zelle-send>
      </div>

      <div *ngIf="current_option =='settings'" class="row" >
        <app-zelle-settings></app-zelle-settings>
      </div>

    </div>
  </div> <!-- Close row-->

  <div class="d-block d-md-none">
    <div class="row d-flex justify-content-center mx-2 bg-color-clear rounded-2 p-2" >
      <div  class="col-4   rounded-3 d-flex justify-content-center align-items-center px-0 py-2"
            (click)="chooseOption('send')"
            [ngClass]="current_option == 'send' ? 'title-text-mobile-on' : 'title-text-mobile-off'" >
        <p class="m-0" >
          Send Money
        </p>
      </div>
      <div  class="col-4   rounded-3 d-flex justify-content-center align-items-center px-0 py-2"
            (click)="chooseOption('request')"
            [ngClass]="current_option == 'request' ? 'title-text-mobile-on' : 'title-text-mobile-off'" >
        <p class="m-0" >
          Request Money
        </p>
      </div>
      <div class="col-4    rounded-3 d-flex justify-content-center align-items-center px-0 py-2"
           (click)="chooseOption('activity')"
           [ngClass]="current_option == 'activity' ? 'title-text-mobile-on' : 'title-text-mobile-off'">
        <p class=" m-0" >
          Activity
        </p>
      </div>
      <div class="col-4    rounded-3 d-flex justify-content-center align-items-center px-0 py-2"
           (click)="chooseOption('recipients')"
           [ngClass]="current_option == 'recipients' ? 'title-text-mobile-on' : 'title-text-mobile-off'">
        <p class=" m-0" >
          Recipients
        </p>
      </div>
      <div  class="col-4   rounded-3 d-flex justify-content-center align-items-center px-0 py-2"
            (click)="chooseOption('settings')"
            [ngClass]="current_option == 'settings' ? 'title-text-mobile-on' : 'title-text-mobile-off'">
        <p class="   m-0">
          Settings
        </p>
      </div>
    </div>

    <div *ngIf="current_option =='send'" class="row rounded-2 p-2" >
      <app-zelle-send></app-zelle-send>
    </div>
    <div *ngIf="current_option =='settings'" class="row rounded-2 p-2" >
      <app-zelle-settings></app-zelle-settings>
    </div>
    <div *ngIf="current_option =='recipients'" class="row" >
      <app-zelle-recipients></app-zelle-recipients>
    </div>
  </div>

  <div class="divider-from-nav d-lg-none d-block" style="height: 5rem;"></div>

</div>
