import {Component, OnInit} from '@angular/core';
import {CardSelected, CardService} from "../../../services/card.service";
import {Router} from "@angular/router";
import {SharedataService} from "../../../services/sharedata.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {CardActivateComponent} from "../card-activate/card-activate.component";
import {CardMoreOptionsComponent} from "../card-more-options/card-more-options.component";
import {TrDisputeMsgComponent} from "../../Transactions/tr-dispute-msg/tr-dispute-msg.component";
import {appearSlow} from "../../Shared/animations";

@Component({
  selector: 'app-card-selected',
  templateUrl: './card-selected.component.html',
  styleUrls: ['./card-selected.component.css'],
  animations: [ appearSlow],
})
export class CardSelectedComponent implements OnInit{
  your_route = 'Card Details'
  selectedCard !: CardSelected
  showSpendLimits !:boolean
  showLimitSummary :boolean = false

  percentage !: number
  available_spend !: number

  // Display period for budget
  budgetLimit :string = ""
  periods = ["weekly", "bi-weekly" , "monthly"]

  d = new Date()
  home_default_year:number = this.d.getFullYear()
  home_default_month:number = this.d.getMonth()+1

  cardReport :boolean = false

  dispute_info !:any
  constructor(
    private router: Router,
    private cardsService : CardService,
    private shareDataService: SharedataService,
    public dialog : MatDialog
  ) { }


  reportCard():void{
  //   this.cardReport = true
  //   this.dispute_info ={ ...this.dispute_info, 'trans_id': this.selectedCard.id}
  //   this.dispute_info ={ ...this.dispute_info, 'source': 1}
  //
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.data = { info:this.dispute_info }
  //   dialogConfig.panelClass = 'full-50-dialog';
  //
  //   this.dialog.open(TrDisputeComponent, dialogConfig)

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title:"Dispute Transaction",
      msg:"Please, contact customer service to dispute this transaction."
    }
    dialogConfig.panelClass = 'full-30-dialog';
    this.dialog.open(TrDisputeMsgComponent, dialogConfig)

  }

  seeCardOptions():void{
      const dialogConfig = new MatDialogConfig();
      dialogConfig.data = { card_id:this.selectedCard.id , loan_id: this.selectedCard.account_id ,
        is_primary: this.selectedCard.is_primary , card_type:this.selectedCard.card_type}
      dialogConfig.panelClass = 'full-30-dialog';
      this.dialog.open(CardMoreOptionsComponent, dialogConfig)
  }

  activateCard(dataAcc:string, dataCard:string , dataNum:string): void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { idAccount:dataAcc , idCard: dataCard , numCard:dataNum}
    dialogConfig.panelClass = 'full-30-dialog';
    this.dialog.open(CardActivateComponent,dialogConfig)
  }

  unlockCard(acc_id:string, c_id:string): void {
    const payload = {loan_id: acc_id, card_id: c_id}
    this.cardsService.unblockCard(payload).subscribe({next:(res)=>{
      if(res.e_code === 1){
       // setTimeout(()=>{
          const currentUrl = '/main/cards';
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl]);})
        //},1000)
      }
    }})
  }

  toggleLock(acc_id:string, c_id:string): void {
    const payload = {loan_id: acc_id, card_id: c_id}
    this.cardsService.blockCard(payload).subscribe({next:(res)=>{
      if(res.e_code === 1) {
       // setTimeout(()=>{
          const currentUrl = '/main/cards';
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl]);})
        //},1000)
      }
    }})
  }

  setSpendingLimits(b:boolean):void{
    this.showSpendLimits  = b
  }

  displayCardLimits(b:boolean):void{
    this.showLimitSummary = b
  }

  ngOnInit() {

    this.shareDataService.getSelectedCard().subscribe({
      next:(value) => { this.selectedCard = value }
    })
    this.budgetLimit = this.selectedCard.period !== null ? this.periods[this.selectedCard.period] : ""

    this.percentage = (Number(this.selectedCard.current_spending)/Number(this.selectedCard.balance))*100

    if(Number(this.selectedCard.current_spending)>0){
      this.available_spend = Number(this.selectedCard.total_amount)  + Number(this.selectedCard.balance)
    } else {
      this.available_spend = 0
    }

  }

}
