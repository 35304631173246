import {Component, Input} from '@angular/core';
import {Transaction, TransactionFilter, TransactionService} from "../../../services/transaction.service";
import {HomeService} from "../../../services/home.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {TrDisputeMsgComponent} from "../../Transactions/tr-dispute-msg/tr-dispute-msg.component";

@Component({
  selector: 'app-card-tr-display',
  templateUrl: './card-tr-display.component.html',
  styleUrls: ['./card-tr-display.component.css']
})
export class CardTrDisplayComponent {
  @Input() isTransactionsPage !: boolean
  @Input() productId !:string
  @Input() cardID !:string

  date = new Date()
  @Input() year :number = this.date.getFullYear()
  @Input() month : number = this.date.getMonth()
  @Input() wordSearch !:string

  monthNames = ['', "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

  isFilterTransactions :boolean = false
  dispute_info !: any
  disputeTransaction :boolean = false

  search_word !: string
  transactions_list : Transaction[] = []
  transactions_display : Transaction[] = []

  displayTransactions :boolean = false

  showAll : boolean = false
  buttonName : string = 'See all Activity'
  constructor(
    private homeService: HomeService,
    private transactionService: TransactionService,
    public dialog : MatDialog
  ) { }

  disputeThisTransaction(data: any): void {

    // this.disputeTransaction = true
    // this.dispute_info = data
    // if (this.cardID ==''){
    //   this.dispute_info = {...this.dispute_info, 'source': 0}
    // } else {
    //   this.dispute_info = {...this.dispute_info, 'source': 1}
    // }
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.data = { info:this.dispute_info }
    // dialogConfig.panelClass = 'full-50-dialog';
    // this.dialog.open(TrDisputeComponent, dialogConfig)

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title:"Dispute Transaction",
      msg:"Please, contact customer service to dispute this transaction."
    }
    dialogConfig.panelClass = 'full-30-dialog';
    this.dialog.open(TrDisputeMsgComponent, dialogConfig)


  }

  seeAllActivity(): void {

    this.showAll = !this.showAll

    const displayShortList = (this.transactions_list.length<=3 ?
      this.transactions_list : this.transactions_list.slice(0,3))

    this.transactions_display = (this.showAll == true) ? this.transactions_list : displayShortList

    this.buttonName = (this.showAll == true) ? "Hide Activity" : "See all Activity"
  }

  filterTransaction(fieldName:string, newValue:string):void{

    if(newValue){
      const filteredList = this.transactions_list.filter(t=>t.desc.toLowerCase().includes(newValue.toLowerCase()))
      this.transactions_display = filteredList

    } else {
      let payload = {
        loan_id: this.productId, card_id:this.cardID, year:this.year, month:this.month,
        type_in: [], limit: 1000, period: 0 }

      this.transactionService.getCardTransactions(payload).subscribe({
        next:(res)=>{
          this.transactions_list = res.transfers.reverse()
          this.transactions_display = (this.transactions_list.length<=3 ?
            this.transactions_list : this.transactions_list.slice(0,3))
        }
      })

    }
  }


  filterTransactions(data:boolean):void{
    this.isFilterTransactions = data
  }

  selectFilterTransactions (data:TransactionFilter):void {
    // loanId:string, month: number, year: number, limit:number, period:number
    this.isFilterTransactions = false

    let payload = {
      loan_id:this.productId, card_id:this.cardID,
      year:Number(data.year) , month: Number(data.month),
      type_in: [], limit: Number(data.limit), period: Number(data.period)
    }

    this.transactionService.getCardTransactions(payload).subscribe({
      next: (res) => {
        if(res.e_code === 1){
          this.transactions_list = res.transfers.reverse()
          this.transactions_display = (this.transactions_list.length<=3 ?
            this.transactions_list : this.transactions_list.slice(0,3))
          if (this.transactions_list.length>0){
            this.displayTransactions= true
          }
        }}})
  }

  getMonthName(data:string){
    return this.monthNames[Number(data)]
  }

  ngOnInit(): void {
    let payload = {
      loan_id:this.productId, card_id:this.cardID,
      year: this.year, month: this.month,
      type_in: [], limit: 1000, period: 0
    }

    this.transactionService.getCardTransactions(payload).subscribe({
      next: (res) => {
          if(res.e_code === 1){
            this.transactions_list = res.transfers.reverse()
            this.transactions_display = (this.transactions_list.length<=3 ?
              this.transactions_list : this.transactions_list.slice(0,3))
            if (this.transactions_list.length>0){
              this.displayTransactions= true
            }
          }}})
  }


}
