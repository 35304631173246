<div class="container-fluid  bg-color-clear scroll-new-rec">

  <div *ngIf="this.transferInfo==false" class="div-profile-box ">

    <!-- First Row = Some Text and Pictures -->
    <div class="row mt-3">

      <div class="col-lg-1 col-2 p-0"   >
        <div class="d-flex justify-content-around ">
          <img class="img-fluid" src="assets/images/pay_tf/transfer.svg" >
        </div>
      </div> <!-- Second Col ends -->

      <div class="col-lg-10 col-8 p-0 d-flex align-items-center " >
        <p class="transfer-text my-0">New Recurrent Payment</p>

      </div> <!-- Col ends -->

      <div class="col-lg-1 col-2 p-0"  >
        <div class="d-flex justify-content-around button-pointer">
          <img (click)="cancelNewPayment()"
               src="assets/images/pay_tf/exit_button.svg" class="img-fluid" alt="exit_button">
        </div>
      </div> <!-- Second Col ends -->

    </div> <!-- First Row ends -->


    <!-- Second Row = Transfer from/to forms -->
    <div class="row mt-1">

      <div class="col-sm-6 col-12 my-2 ">
        <div class="form-floating ">
          <select
            id="sender"
            [class] ="validationErrors[fieldsConfig[0].name]? 'form-control formError ': 'form-control '"
            [ngModel]="fieldValues[fieldsConfig[0].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[0].name)"
          >
            <option  *ngFor="let option of accounts_sender" [value] = option[0] >{{option[1]}}</option>

          </select>
          <label class="form-label" >{{fieldsConfig[0].displayName}}</label>
        </div>
        <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[0].name]"> {{this.validationErrors[fieldsConfig[0].name]}}
      </div> <!-- First Col ends -->

      <div class="col-sm-6 col-12 my-2">
        <div class="form-floating  ">
          <select
            id="receiver"
            [class] ="validationErrors[fieldsConfig[1].name]? 'form-select formError select-style': 'form-control select-style'"
            [ngModel]="fieldValues[fieldsConfig[1].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[1].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[1].name)"
          >
            <option  *ngFor="let option of accounts_receiver" [value] = option[0] >{{option[1]}}</option>
          </select>
          <label class="form-label" >{{fieldsConfig[1].displayName}}</label>
        </div>
        <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[1].name]"> {{this.validationErrors[fieldsConfig[1].name]}}
      </div> <!-- Second Col ends -->

    </div> <!-- Second Row ends -->

    <!-- Third row = Add new recipient appears if Local transfer   -->
    <div class="row mt-2"  >
      <div class="d-flex justify-content-end text-decoration-underline new-recipient  "
      ><p id="newRecipient" (click)="redirectRecipients()" class="button-pointer" >+ Add New Recipient</p>
      </div>
    </div> <!-- Third Row ends -->

    <!-- Fourth row = Amount/Memo -->
    <div class="row mt-4">

      <div class="col-sm-6 col-12 my-2">
        <div class="form-floating">
          <input
            id="amount"
            [class] ="validationErrors[fieldsConfig[2].name]? 'form-control formError input-style': 'form-control input-style'"
            [type]="fieldsConfig[2].type"
            [ngModel]="fieldValues[fieldsConfig[2].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[2].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[2].name)"
          >
          <label class="form-label">{{fieldsConfig[2].displayName}}</label>
        </div>
        <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[2].name]">
          {{this.validationErrors[fieldsConfig[2].name]}}
        </p>
      </div> <!-- First Col ends -->

      <div class="col-sm-6 col-12 my-2">
        <div class="form-floating">
          <input
            id="memo"
            [class] ="validationErrors[fieldsConfig[3].name]? 'form-control formError input-style': 'form-control input-style'"
            [type]="fieldsConfig[3].type"
            [ngModel]="fieldValues[fieldsConfig[3].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[3].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[3].name)"
          >
          <label class="form-label">{{fieldsConfig[3].displayName}}</label>
        </div>
        <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[3].name]">
          {{this.validationErrors[fieldsConfig[3].name]}}
        </p>
      </div> <!-- Second Col ends -->

    </div> <!-- Fourth Row ends -->

    <!-- Fith row = Frequency/send on/duration -->
    <div class="row mt-3" >

      <div class="col-sm-4 col-12 my-2">
        <div class="mt-1">
          <div class="form-floating">
            <select
              [id]="'freq'"
              [class] ="validationErrors[fieldsConfig[4].name]? 'form-select formError ': 'form-control '"
              [ngModel]="fieldValues[fieldsConfig[4].name]"
              (ngModelChange)="updateFieldValue(fieldsConfig[4].name, $event)"
              (blur)="setFieldToEdited(fieldsConfig[4].name)"
            >

              <option  value = 1 >Weekly</option>
              <option  value = 3 >Monthly</option>
            </select>
            <label class="form-label"> Frequency</label>
          </div>

          <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[4].name]">
            {{this.validationErrors[fieldsConfig[4].name]}}
          </p>

        </div>
      </div> <!-- First Col ends -->

      <!-- Second Col : Monthly -->
      <div class="col-sm-4 col-12 my-2" *ngIf="this.fieldValues.frequency === '3'">
        <div class="mt-1">
          <div class="form-floating">
            <select [id]="'day_month'"
                    [class] ="validationErrors[fieldsConfig[5].name]? 'form-select formError ': 'form-control '"
                    [ngModel]="fieldValues[fieldsConfig[5].name]"
                    (ngModelChange)="updateFieldValue(fieldsConfig[5].name, $event)"
                    (blur)="setFieldToEdited(fieldsConfig[5].name)"
            >
              <option value="" disabled selected></option>
              <option  value = 1 >1</option>
              <option  value = 2 >2</option>
              <option  value = 3 >3</option>
              <option  value = 4 >4</option>
              <option  value = 5 >5</option>
              <option  value = 6 >6</option>
              <option  value = 7 >7</option>
              <option  value = 8 >8</option>
              <option  value = 9 >9</option>

              <option  value = 10 >10</option>
              <option  value = 11 >11</option>
              <option  value = 12 >12</option>
              <option  value = 13 >13</option>
              <option  value = 14 >14</option>
              <option  value = 15 >15</option>
              <option  value = 16 >16</option>
              <option  value = 17 >17</option>
              <option  value = 18 >18</option>
              <option  value = 19 >19</option>

              <option  value = 20 >20</option>
              <option  value = 21 >21</option>
              <option  value = 22 >22</option>
              <option  value = 23 >23</option>
              <option  value = 24 >24</option>
              <option  value = 25 >25</option>
              <option  value = 26 >26</option>
              <option  value = 27 >27</option>
              <option  value = 28 >28</option>
              <option  value = 29 >29</option>
              <option  value = 30 >30</option>

            </select>
            <label class="form-label"> Send on</label>
          </div>
          <div>
            <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[5].name]">
              {{this.validationErrors[fieldsConfig[5].name]}}
            </p>
          </div>

        </div>
      </div> <!-- Second Col ends -->

      <!-- Second Col : Weekly -->
      <div class="col-sm-4 col-12 my-2" *ngIf="this.fieldValues.frequency === '1'">
        <div class="mt-1">
          <div class="form-floating">
            <select class="form-select"
                    [id]="'day_week'"
                    [class] ="validationErrors[fieldsConfig[5].name]? 'form-select formError ': 'form-control '"
                    [ngModel]="fieldValues[fieldsConfig[5].name]"
                    (ngModelChange)="updateFieldValue(fieldsConfig[5].name, $event)"
                    (blur)="setFieldToEdited(fieldsConfig[5].name)"
            >
              <option value="" disabled selected></option>
              <option  value = 0 >0 - Monday</option>
              <option  value = 1 >1 - Tuesday</option>
              <option  value = 2 >2 - Wednesday</option>
              <option  value = 3 >3 - Thursday</option>
              <option  value = 4 >4 - Friday</option>
              <option  value = 5 >5 - Saturday</option>
              <option  value = 6 >6 - Sunday</option>
            </select>
            <label class="form-label"> Send on</label>
          </div>
          <div>
            <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[5].name]">
              {{this.validationErrors[fieldsConfig[5].name]}}
            </p>
          </div>

        </div>
      </div> <!-- Second Col ends -->


      <div class="col-sm-4 col-12 my-2">
        <div class="mt-1">
          <div class="input-group ">
            <input
              [id] ="'date_info'"
              [class] ="validationErrors[fieldsConfig[6].name]? 'input-date form-control formError py-3': ' input-date form-control  py-3'"
              [placeholder]="'End Date'"
              name="dp"
              [ngModel]="dateTransfer"
              (ngModelChange)="updateFieldValue(fieldsConfig[6].name,$event)"
              (blur)="setFieldToEdited(fieldsConfig[6].name)"
              ngbDatepicker
              #dov="ngbDatepicker"
              [minDate]= minTransferEndDate
            />
            <button class="btn btn-outline-secondary bi bi-calendar3 " (click)="dov.toggle()" type="button"></button>
          </div>
          <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[6].name]">
            {{this.validationErrors[fieldsConfig[6].name]}}
          </p>
        </div>
      </div> <!-- Third Col ends -->

    </div> <!-- Six Row ends -->

    <!-- Seven row = Continue Button -->
    <div class="row">
      <div class="col-lg-6  col-12 my-3 text-center">
        <div id="cancelBtn" class="text-center yellow-button rounded-3 py-3 px-2 w-100 button-pointer"
             (click)="cancelNewPayment()"> Cancel
        </div>
      </div>
      <div class="col-lg-6  col-12 my-3 text-center">
        <div id="continueBtn" class="text-center violet-button rounded-3 py-3 px-2 w-100 button-pointer"
             (click)="verifyInformation()"> Add Payment
        </div>
      </div>
    </div>


  </div>

  <!--  Recurrent Payment has been added-->
  <div *ngIf="this.transferInfo==true">

    <div class="row mt-3 p-0" >

      <div class="col-lg-1 col-2  p-0"  >
        <div class="d-flex justify-content-around ">
          <img class="img-fluid" src="assets/images/pay_tf/transfer.svg" >
        </div>
      </div> <!-- Second Col ends -->

      <div class="col-lg-10 col-8 p-0 d-flex align-items-center " >
        <p class="transfer-text my-0">Add Recurrent Payment</p>
      </div> <!-- Col ends -->

      <div class="col-lg-1 col-2 p-0"  >
        <div class="d-flex justify-content-around button-pointer">
          <img (click)="cancelNewPayment()"
               src="assets/images/pay_tf/exit_button.svg" class="img-fluid" alt="exit_button">
        </div>
      </div> <!-- Second Col ends -->

    </div> <!-- First Row ends -->
    <div class="row text-center mt-3">
      <div>
        <img src="assets/images/pay_tf/success.svg">
      </div>
      <p class="p-title my-3">Success!</p>
      <p id="pMessage" class="p-text">{{this.message}}</p>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <button id="btnSuccess" class=" violet-button rounded-3  w-75 py-3 my-3" (click)="cancelNewPayment()">Continue</button>
      </div>
    </div>
  </div>

</div> <!-- Container ends -->
