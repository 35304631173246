import {Component, Inject, Input, OnInit} from '@angular/core';
import {Recipient, RecipientExtended, RecipientsService} from "../../../services/recipients.service";
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import {TransferLocalService} from "../../../services/transfer-local.service";
import {Router} from "@angular/router";
import {NgbDate, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-rec-pay-new',
  templateUrl: './rec-pay-new.component.html',
  styleUrls: ['./rec-pay-new.component.css']
})
export class RecPayNewComponent implements OnInit{
  accounts_sender !:string[][]
  accounts_receiver !:string[]
  transferInfo :boolean = false
  message !:string
  validateOn: string = 'change'
  fieldWasEdited: any = {}
  fieldsConfig !: FormFieldConfig[]
  validationErrors : any = {}
  displayEndDay !:string

  networkObj = { kredio : 1, zelle: 2,  cashapp: 3 }


  // Change frequency
  today = new Date()
  dateTransfer!: NgbDateStruct
  minTransferEndDate = new NgbDate(this.today.getFullYear(), this.today.getMonth()+1, (this.today.getDate() +1));

  constructor(
    private transferLocalService : TransferLocalService,
    private recipientsService : RecipientsService,
    private router: Router,
    public dialogRef: MatDialogRef<RecPayNewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  _fieldValues: any = {}
  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues: any) {
    this._fieldValues = newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }
  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  updateFieldValue(fieldName: string, newValue: string) {
    this.fieldValues = {...this.fieldValues, [fieldName]: newValue}
  }
  cancelNewPayment():void {
    this.dialogRef.close()

    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    })
  }

  verifyInformation():void {

    // Bank Network
    this.fieldValues['network'] = 1 // 2: Zelle 3: CashApp


    this.validationErrors = this.getvalidationErrors(true)

    if(Object.keys(this.validationErrors).length > 0) {
      return;
    }
    const tempTransfer = this.fieldValues['end_date']

    this.displayEndDay = `${tempTransfer.year}-${tempTransfer.month}-${tempTransfer.day}`
    this.fieldValues['end_date'] = this.displayEndDay

    // Set frenquency type to number
    let freq =  Number(this.fieldValues.frequency)
    this.fieldValues['frequency'] = freq

    this.transferLocalService.addRecurrentPayment(this.fieldValues).subscribe({
      next:(res)=>{
       if(res.e_code === 1) {
         this.transferInfo = true
         this.message = res.e_msg
       }
      }})


  }

  redirectRecipients():void {
    this.dialogRef.close()
    this.router.navigateByUrl('recipients')
  }

  createInitialPayloads():void {
    for (let field of this.fieldsConfig)
      this.fieldValues[field.name] = ''

    this.fieldValues['frequency'] = '3' // Default = Monthly
  }

  ngOnInit() {

    this.accounts_sender = this.data.accounts_sender

    this.fieldsConfig  = this.transferLocalService.fieldsConfigAll

    this.createInitialPayloads()

    this.recipientsService.getRecipients().subscribe({ next:res =>{
        if(res.e_code === 1) {
          // Show accounts for internal transfers only !!!
          let local_receivers = res.recipients.filter((rec:RecipientExtended) => rec.kredio == true)
          this.accounts_receiver = local_receivers.map(
            (rec:RecipientExtended) => [rec.id , rec.first_name +"-"+ rec.last_name ])
        }
      }})

  }
}
