import {Component, Inject, Input, OnInit} from '@angular/core';
import {PayrollService} from "../../../../services/payroll.service";
import {FormFieldConfig} from "../../../Shared/forms-shared/typesForm";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SharedataService} from "../../../../services/sharedata.service";
import {Router} from "@angular/router";
import {isHigher0Lower100Validator, isNotEmptyValidator} from "../../../Shared/forms-shared/validators";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-payroll-transfer-form',
  templateUrl: './payroll-transfer-form.component.html',
  styleUrls: ['./payroll-transfer-form.component.css']
})
export class PayrollTransferFormComponent implements OnInit{

  brand : string = environment.brand
  percentAllocation : string = "1" // default case: full transfer only
  entrePercentage :boolean = false
  enterFixedAmount :boolean = false
  validationErrors : any = {}
  fieldWasEdited: any = {}
  validateOn: string = 'change'
  fieldsConfig !: FormFieldConfig[]
  constructor(
    private payrollService: PayrollService,
    public dialogRef: MatDialogRef<PayrollTransferFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shareDataService : SharedataService,
    private router : Router
  ) { }

  _fieldValues:any = { }

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues:any) {
    this._fieldValues= newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  updateFieldValue(fieldName:string, newValue:String) {
    this.fieldValues = { ... this.fieldValues, [fieldName]: newValue}

    if(this.percentAllocation === "1") { // full allocation 100%
      this.entrePercentage = false
      this.enterFixedAmount = false
      this.fieldsConfig[3]['validators'] = []
      this.fieldsConfig[4]['validators'] = []
    }
    if(this.percentAllocation !== "1" && this.fieldValues.type === "1"){ // partial transfer + type: 1 enter % allocation 1-99
      this.entrePercentage = true
      this.enterFixedAmount = false
      this.fieldsConfig[4]['validators'] = []
      this.fieldsConfig[3]['validators'] = [isNotEmptyValidator, isHigher0Lower100Validator]
    }
    if(this.percentAllocation !== "1" && this.fieldValues.type === "0"){ // partial transfer +  type: USD 0 enter value
      this.entrePercentage = false
      this.enterFixedAmount = true
      this.fieldsConfig[3]['validators'] = []
      this.fieldsConfig[4]['validators'] = [isNotEmptyValidator]
    }
  }
  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }
  transferWages():void {
    this.validationErrors = this.getvalidationErrors(true)

    if(Object.keys(this.validationErrors).length > 0)
      return
    let typeNumber = parseInt(this.fieldValues.type)
    this.fieldValues = {...this.fieldValues, ['type']: typeNumber}

    if( this.percentAllocation == "1" ) { // Argyle only allows full transfer
      this.fieldValues = {...this.fieldValues, ['allocation_percentage']: "100"}
    }

    this.payrollService.getDDSConfig(this.fieldValues).subscribe({
      next:(res)=>{
        const encryptedConfig = res.encrypted_config
        this.shareDataService.setConfigDDS(encryptedConfig)
        this.dialogRef.close()
        this.router.navigateByUrl('/payrollmanage')
      }})
  }
  cancelActivate(): void{
   this.dialogRef.close()
  }
  ngOnInit() {
    this.fieldsConfig = this.payrollService.payrollFormFields
    for(let field of this.fieldsConfig){
      this.fieldValues[field.name] = ""
    }
    this.fieldValues['loan_id'] = this.data.loan_id
    this.percentAllocation = this.data.allocations

  }

}
