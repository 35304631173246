<div class="container-fluid bg-color-clear h-100" [@appearSlow]>


  <div class="row h-100">

    <div class="col-md-6 col-12 ">
      <app-zelle-send-money
      [accounts_sender]="sender_accounts"
      (isZelleTrHisto)="displayZelleHisto($event)"
      ></app-zelle-send-money>
    </div>

    <div class="col-md-6 col-12" *ngIf="isDisplayTransactionsHisto">

      <div class="row text-center">
        <p class="p-histo py-2">Past Zelle Transactions</p>
      </div>

      <div class="row p-0 m-0" *ngIf="isHistoData">
        <div [ngClass]=" isScrollHisto ? 'scroll-data' : '' ">
          <table id="tableTransactions" class="table">
            <thead>
            <tr>
              <th class="d-none d-md-block">Date</th>
              <th>Memo</th>
              <th class="text-end">Amount</th>
            </tr>
            </thead>
            <tbody  *ngFor="let tr of histoData">
            <tr id="transactions">
              <td  class="td-when d-none d-md-block">
                {{getMonthName(tr.dt_when.split("-")[1])}} {{tr.dt_when.split("-")[2]}} -
                 {{ tr.dt_when.split("-")[0]}}
              </td>
              <td >
                <p class="td-desc-sm mb-0">{{tr.desc}}</p>
                <p class="td-when-sm d-block d-md-none mb-0">
                  {{getMonthName(tr.dt_when.split("-")[1])}} {{tr.dt_when.split("-")[2]}} -
                  {{ tr.dt_when.split("-")[0]}}
                </p>
                </td>
              <td class="td-amount text-end">${{tr.amount|number :'.2'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="row p-0 m-0" *ngIf="!isHistoData" >
        <div class="h-100 d-flex justify-content-center text-center align-items-center">
          <div>
            <div class="d-flex justify-content-center text-center mb-3">
              <img src="assets/images/transactions/dollar.svg">
            </div>
            <p id="emptyMsg1" class="p-b-nt" >No transactions found</p>
            <p id="emptyMsg2" class="p-b-nt-small">Start using <strong>Zelle</strong> and you will see the transactions displayed here</p>
          </div>
        </div>
      </div>

    </div>

  </div>

</div>
