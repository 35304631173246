import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {Card, CardBlock, CardSetDefault, CardService, CardSelected} from "../../../services/card.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {CardsSuccessMsgComponent} from "../cards-success-msg/cards-success-msg.component";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-card-summary',
  templateUrl: './card-summary.component.html',
  styleUrls: ['./card-summary.component.css']
})
export class CardSummaryComponent  implements OnInit{

  @Input() accountId !:string
  @Input() cardData !: Card
  @Output() infoCard = new EventEmitter<CardSelected>()

  brand : string = environment.brand

  lastFourDigits !:string

  activate_default: boolean = false
  message_default !:string
  activate_lock: boolean = false
  message_lock !: string

  constructor(
    private router: Router,
    private cardsService: CardService,
    public dialog : MatDialog
  ) { }
  toggleLock(acc_id:string, c_id:string): void {
    let card_data = { "loan_id": acc_id, "card_id": c_id }
    this.cardsService.blockCard(card_data).subscribe({next:(res)=>{
          this.message_lock = res.e_msg
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = { title: "Request to Lock Card" , msg: this.message_lock }
          dialogConfig.panelClass = 'full-30-dialog';
          this.dialog.open(CardsSuccessMsgComponent,dialogConfig)
        }}
    )
  }
  unlockCard(acc_id:string, c_id:string): void {
    let card_data = {loan_id: acc_id, card_id: c_id}
    this.cardsService.unblockCard(card_data).subscribe({ next:(res)=>{
        this.message_lock = res.e_msg
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = { title: "Request to UNLock Card" , msg: this.message_lock }
        dialogConfig.panelClass = 'full-30-dialog';
        this.dialog.open(CardsSuccessMsgComponent,dialogConfig)
      }
    })

  }

  // toggleDefault(acc_id:string, c_id:string): void {
  //   let card_data = {loan_id: acc_id, card_id: c_id}
  //   this.cardsService.setDefaultCard(card_data).subscribe({ next:(res)=>{
  //       const dialogConfig = new MatDialogConfig();
  //       dialogConfig.data = { title: "Request Status Changed" , msg: res.msg }
  //       dialogConfig.panelClass = 'full-30-dialog';
  //       this.dialog.open(CardsSuccessMsgComponent,dialogConfig)
  //     }})
  // }

  displayCardInfo(data:Card):void {
    const allData = {...data, 'account_id':this.accountId}
    this.infoCard.emit(allData)
  }

  ngOnInit() {
    this.lastFourDigits = this.cardData.number.toString().slice(-4)
  }
}
