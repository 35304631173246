import {Recipient} from "../../../services/recipients.service";
import {AccountService} from "../../../services/account.service";
import {Component} from '@angular/core';
import {appearSlow} from "../../Shared/animations";

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  providers: [AccountService],
  styleUrls: ['./accounts.component.css'],
  animations: [ appearSlow ],

})
export class AccountsComponent {

  your_route :string = 'Accounts'

  show_activity :boolean = true
  show_statements :boolean = false
  showAccountRoutingNumber: boolean = false
  report_credit_bureau : boolean = false

  //accounts !: Account[] ;

  // Set default year and month for transactions
  d = new Date()
  home_default_year:number = this.d.getFullYear()
  home_default_month:number = this.d.getMonth()+1

  recipients: Recipient[] = []

  constructor(
    private accountService: AccountService
  ) { }

  displayAccountRoutingNumber():void{
    this.showAccountRoutingNumber = !this.showAccountRoutingNumber
  }
  reportToCreditBureau():void{
    this.report_credit_bureau = !this.report_credit_bureau
  }

  showStatements(str:string):void {
    if(str === 'statements'){
      this.show_statements = true
      this.show_activity = false
    } else {
      this.show_activity = true
      this.show_statements = false
    }
  }

}
