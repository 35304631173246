
<div id="carouselExampleControls" class="carousel slide h-100 m-0" data-bs-ride="carousel"   >
  <div class="carousel-inner h-100"  >

    <div class="carousel-item active h-100 "   >

      <div *ngIf="userIsEnrolled"  class="h-100">

        <div class="row d-flex justify-content-center h-100" >

          <div class="col-9  col-sm-4 d-flex align-items-center p-0" >
            <div class="row m-0 w-100 p-0 ">
              <div class="col-2 p-0" >
                <img src="assets/images/home/star.svg" alt="Star" class="w-100" >
              </div>
              <div class="col-10 p-0">

                <!-- Large screens -->
                <p class="p-payroll mt-1 mb-0 d-none d-lg-block">Live Payroll</p>
                <p class="p-period mb-0 d-none d-lg-block">Period: {{payrollPeriod}}</p>
                <p class="p-wage-earned mb-0 d-none d-lg-block ">Available wages:</p>
                <p [ngClass]="amount_high ? 'p-1Khigh mb-0 d-none d-lg-block' : 'p-1Klow mb-0 d-none d-lg-block'">${{payrollAmount|number :'.2'}}</p>
<!--                <p  [ngClass]="amount_high ? 'p-1Khigh mb-0 d-none d-lg-block' : 'p-1Klow mb-0 d-none d-lg-block'" >$99,000.00</p>-->

                <!-- Small/Mid screens -->
                <p class="p-payroll mb-0 d-sm-block d-lg-none h-100 align-content-center">Live Payroll</p>

             </div>
            </div>
          </div>

          <div class="col-8  col-sm-5  d-flex align-items-center justify-content-center " >
            <div class="text-center" >
              <p class="p-wage-earned d-flex justify-content-center " >
                Don't wait for your payday! Get paid for the hours you have already worked
              </p>
             <div  id="btnAccessWages" (click)="displayPayroll()" class="yellow-button text-center rounded-4  ">
               <p class="mb-0 py-3">Access wages now</p>
              </div>
            </div>
          </div>

        </div> <!--Close row-->

      </div> <!--Close ngif enrolled=True-->

      <div *ngIf="!userIsEnrolled" class="h-100">

        <div class="row d-flex justify-content-center h-100  " >

          <div class=" col-8  col-sm-5 d-flex align-items-center " >
            <div>
              <div class="row">
                <div class="col-2 p-0 " >
                  <img src="assets/images/home/star.svg" alt="Star" class="w-100"  >
                </div>
                <div class="col-10 d-flex align-items-center " >
                  <p class="p-payroll mb-0">Live Payroll</p>
                </div>
              </div>
              <p class="p-wage-earned p-0 align-justify">
                Don't wait for your payday! Get paid for the hours you have already worked
              </p>
            </div>
          </div>

          <div class="col-8  col-sm-4 d-flex align-items-center" >
            <button class="button-r3 w-100 rounded-4 py-3"
                    (click)="displayPayroll()">Enroll
            </button>
          </div>

        </div> <!--Close row-->

      </div> <!--Close ngif enrolled=false-->

    </div>

    <div class="carousel-item  h-100" >
      <div class="row d-flex justify-content-center h-100  " >
        <div class=" col-8  col-sm-5 d-flex align-items-center my-1 " >
            <div class="row">
                  <div class="col-2 p-0 " >
                    <img src="assets/images/home/star.svg" alt="Star" class="img-fluid "  >
                  </div>
                  <div class="col-10  ps-0 " >
                    <p class="p-payroll mb-0 pt-1 ">Coupons & Discounts</p>
                    <p class="p-wage-earned mb-0 align-justify pt-1 ">{{brand}} coupons and discounts are the easiest way to
                      earn cash back from places you already know</p>
                  </div>
            </div>
        </div>

        <div class="col-8  col-sm-4  d-flex align-items-center justify-content-center my-1" >
              <div id="btnCoupons" class="yellow-button w-100 rounded-4 text-center" (click)="displayCouponsDiscounts()">
                <p class="mb-0 py-3">View Deals</p>
              </div>
            </div>
      </div> <!--Close row-->
    </div>

<!--    <div class="carousel-item  h-100" >-->

<!--        <div class="row d-flex justify-content-center h-100  " >-->

<!--                  <div class=" col-8  col-sm-5 d-flex align-items-center  p-0" >-->

<!--                      <div class="row w-100 m-0 p-0 ">-->
<!--                        <div class="col-2 p-0 d-flex align-items-center " >-->
<!--                          <img src="assets/images/home/star.svg" alt="Star" class="img-fluid"  >-->
<!--                        </div>-->
<!--                        <div class="col-10  d-flex align-items-center" >-->
<!--                          <p class="p-ext-acc m-0 ">Add External Account</p>-->
<!--                        </div>-->
<!--                      </div>-->

<!--                  </div>-->

<!--                  <div class="col-8 col-sm-4 d-flex align-items-center justify-content-center p-0 " >-->
<!--                    <button  class="button-r3 mt-3 rounded-4 py-3 " (click)="addExternalAccount()">-->
<!--                    Connect-->
<!--                    </button>-->
<!--                  </div>-->

<!--        </div> &lt;!&ndash;Close row&ndash;&gt;-->

<!--    </div>-->

    <div class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
      <span class="carousel-control-prev-icon w-50"  aria-hidden="true"></span>

    </div>
    <div class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
      <span class="carousel-control-next-icon span-icon w-50" aria-hidden="true"></span>
    </div>
  </div>

</div>


