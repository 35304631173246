import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {CardSelected} from "./card.service";

@Injectable({
  providedIn: 'root'
})
export class SharedataService {

  private signUpState$ = new BehaviorSubject<any>({});
  private phoneNumber$ = new BehaviorSubject<any>({});
  private phoneNumberId$ = new BehaviorSubject<any>({});
  private userEmail$ = new BehaviorSubject<string>('');
  private securityCodeId$ = new BehaviorSubject<string>("");
  private verifiedEmail$ = new BehaviorSubject<string>('');
  private userId$ = new BehaviorSubject<string>("");
  private mainAccountData$ = new BehaviorSubject<any>({});
  private selectedOption$ = new BehaviorSubject<string>("");
  private selectedCard$ = new BehaviorSubject<any>({});
  private configDDS$ = new BehaviorSubject<any>('');


  private screenSizeLarge$ = new BehaviorSubject<any>('');


  setScreenSizeLarge(data:any) {
    this.screenSizeLarge$.next(data);
  }
  getScreenSizeLarge() {
    return this.screenSizeLarge$.asObservable();
  }

  setConfigDDS(data: CardSelected) {
    this.configDDS$.next(data);
  }
  getConfigDDS() {
    return this.configDDS$.asObservable();
  }


  setSelectedCard(data: CardSelected) {
    this.selectedCard$.next(data);
  }
  getSelectedCard() {
    return  this.selectedCard$.asObservable();
  }


  setOption(data: string) {
    this.selectedOption$.next(data);
  }

  getOption() {
    return  this.selectedOption$.asObservable();
  }

  setMainAccount(data: any) {
    this.mainAccountData$.next(data);
  }

  getMainAccountData() {
    return  this.mainAccountData$.asObservable();
  }

  setUserId(data: any) {
    this.userId$.next(data);
  }

  getUserId() {
    return  this.userId$.asObservable();
  }

  setVerifiedEmail(data: string) {
    this.verifiedEmail$.next(data);
  }

  getVerifiedEmail() {
    return  this.verifiedEmail$.asObservable();
  }

  getSecurityCodeId() {
    return  this.securityCodeId$.asObservable();
  }

  setSecurityCodeId(data: any) {
    this.securityCodeId$.next(data);
  }

  setPhoneNumberId(data: any) {
    this.phoneNumberId$.next(data);
  }

  getPhoneNumberId() {
    return this.phoneNumberId$.asObservable();
  }

  setPhoneNumber(data: any) {
    this.phoneNumber$.next(data);
  }

  getPhoneNumber() {
    return this.phoneNumber$.asObservable();
  }

  setSignUpState(data: any) {
    this.signUpState$.next(data);
  }

  getSignUpState() {
     return this.signUpState$.asObservable();
  }
  setUserEmail(data: any) {
    this.userEmail$.next(data);
  }

}
