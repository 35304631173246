<div class="container-fluid h-100" [@appearSlow]>

  <div class="row" >

    <div class="col-md-1 col-2 px-0 d-flex align-items-center justify-content-center" >
      <img class="img-fluid" src="assets/images/nav-bar/home_icon.svg">
    </div>

    <div class="col-md-10 col-8  d-flex align-items-center">
      <p class="p-title m-0" >Verify Email</p>
    </div>

    <div class="col-md-1 col-2 p-0 d-flex align-content-around button-pointer" >
      <img (click)="cancelConfirmEmail()" id="btnClosePopup"
           class="img-fluid m-0"
           src="assets/images/credit_score/close_button.svg">
    </div>

  </div> <!--Close row-->

  <!-- Agree to verify email -->

  <div *ngIf="!isEmailSent" class="h-50">

    <div class="row text-center my-3">
      <p id="p-email" class="p-wording mb-1 my-2">To confirm your email address <strong>{{this.registeredEmail}}</strong>, we have have sent you a link</p>
      <p class="p-wording mb-1 my-3 ">Please, verify your email account to access <strong>{{brand}}</strong></p>
    </div>

    <div class="d-flex align-items-center justify-content-center h-50">
      <div>
        <img class="img-fluid" src="assets/images/login/checkEmail.svg">
      </div>
    </div>

    <div class="row mt-4"  >
      <div  id="confirmButton" class="violet-button py-3  rounded-4 border-0 text-center button-pointer"
               (click)="sendVerificationEmail()"
      >Confirm</div>
    </div>

  </div> <!-- Close ngif -->

  <!-- Agree to verify email sent -->

  <div *ngIf="isEmailSent">

      <div class=" text-center my-4">
        <img class="img-fluid" src="assets/images/home/success_icon.svg">
      </div>

      <div class="row text-center my-3">
      <p id="p-check" class="p-wording">Please check your email inbox to verify the address</p>
    </div>

      <div class="text-center"  >
        <div class="violet-button w-100 py-3 rounded-4 border-0 button-pointer" id="btnClose" (click)="close()"
        >Close</div>
      </div>

  </div> <!-- Close ngif -->

</div>



