import { Component, OnInit, Inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {appearSlow} from "../../Shared/animations";
@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.css'],
  animations: [ appearSlow ],
})
export class ErrorDialogComponent implements OnInit{

  @Input() title !: string
  @Input() status : number = 404
  @Input() statusText : string = "We were unable to process your request"
  @Input() url!: string
  @Input() errorsDetails!: Array<Object>
  @Input() errorBE!: string

  errorsList !:string[]

  brand :string = environment.brand

  constructor(
    public activeModal: NgbActiveModal,
    private auth : AuthService,
    private router: Router

  ) {}
//
  closeMessage():void{
    if(
      this.status === 500 ||
      this.statusText === 'Token Expired'
      || this.status === 404
      || this.statusText === 'User not authenticated'
      || this.errorBE === 'Customer already has a Bank Record in the system'
      || this.statusText === 'Email already activated'
      || this.statusText === 'Wrong Token') {
      this.activeModal.close()
      sessionStorage.clear()
      this.router.navigateByUrl('/login')

    } else {
      this.activeModal.close()
    }
  }
  ngOnInit( ) {
    this.errorsList = this.errorsDetails.filter((err: any) => {
        return err.e_code == 2
      }).map((err: any) => err.e_msg)


  }

}












