<div class="container-fluid h-100">

  <!-- Left side Image asset/logo/terms and policies and right side signin and links -->
  <div class="row h-100">
    <!--    <div class="row h-100" *ngIf="completeProfile ==false">-->

    <!-- Left side -->
    <div class="col-lg-6 d-none d-lg-inline violet-background" >

      <!-- Logo + Text -->
      <div class="row d-lg-flex d-none" style="height: 25%" >
        <div class="get-started d-flex align-items-end justify-content-center"
             [@slideInUpper]>
          Get Started
        </div>
        <div class="d-lg-flex d-none d-flex  justify-content-center" [@slideInUpper]>
          <img [src]="'../../../../assets/images/bank_imgs/logo_' + brand + '.svg'" class="img-fluid" style="scale: 70%" alt="">
        </div>
      </div>

      <!-- Image asset -->
      <div class="row d-lg-flex d-none" style="height: 70%">
        <div class="d-flex justify-content-center" [@slideInRight]>
          <img class="img-fluid" src="../../../../assets/images/login/register_device.svg" alt="">
        </div>
      </div>

      <!-- Standard terms and policies -->
      <div class="row d-lg-flex d-none" [@slideInLower]>
        <div class="text-center">
          <a class="bottom-a-white button-pointer" id="btnPrivacyPolicy"
             (click)="redirectToFile(this.docsPrivatePolicy)"> Privacy Policy&nbsp;&nbsp;|&nbsp;</a>
          <a class="bottom-a-white button-pointer" id="btnPrivacyPolicyCRB"
             (click)="redirectToFile(this.docsPrivatePolicyCRB)"> Legal&nbsp;&nbsp;|&nbsp;</a>
          <a class="bottom-a-white button-pointer" id="btnContact" > Contact</a>
        </div>
      </div>

    </div>

    <!-- Right side -->

    <div class="col-lg-4 offset-lg-1 h-100 py-0"  [@slideInLeft] >

      <div class="row" style="height:5% "></div>


      <!--Title: Small screen-->
      <div class="row  d-lg-none my-3 " >
        <p class="p-title mb-0 py-2 ">Verify your device</p>
      </div>

      <!--Title: Large screen-->
      <div class="row  d-none d-lg-block my-3  " >
        <p class="p-title mb-0 py-2">Verify your device</p>
      </div>


        <!--  Enter code sent to registered phone-->

      <div *ngIf="!this.checkCode" class="h-50">
        <div class="row" >
              <p>We have detected a new device. We will send you a text message with a verification code to register it.</p>
            </div>
        <div class="row">
              <div class="form-floating my-3" >
                <input
                  [id]="'inputCode'"
                  [class] ="validationErrors[fieldsConfig[0].name]? 'form-control formError': 'form-control'"
                  [type]= "'string'"
                  [placeholder]="fieldsConfig[1].displayName"
                  [ngModel]="fieldValues[fieldsConfig[0].name]"
                  (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
                  (blur)="setFieldToEdited(fieldsConfig[0].name)"
                >
                <label class="form-label ms-2 ">{{fieldsConfig[0].displayName}}</label>
              </div>
              <p class="error my-1" id="errorCode"
                 *ngIf="this.validationErrors[fieldsConfig[0].name]"
              >
                {{this.validationErrors[fieldsConfig[0].name]}}
              </p>

            </div>
      </div>
      <div *ngIf="!this.checkCode"  class="row  px-2">
        <button class="violet-button rounded-4  w-100 py-3 my-2" id="contineButton"
                (click)="registerDevice()">Continue</button>
      </div>
      <div *ngIf="!this.checkCode"  class="row  px-2">
        <button class="yellow-button rounded-4  w-100 py-3 my-2" id="cancelButton"
                (click)="redirectToWelcome()">Cancel</button>
      </div>

        <!--  Success Message-->
      <div *ngIf="this.checkCode" class="h-50 d-flex align-items-center text-center">
          <div class="w-100">
            <div class ="text-center my-3">
              <img src="../../../../assets/images/login/success_logo.svg" alt="Success">
            </div>
            <p class="text-center">Your device was registered successfully.</p>
          </div>
        </div>
      <div *ngIf="this.checkCode" class="row  px-2 " >
        <button class="violet-button rounded-4  w-100 py-3 my-2" id="welcomeButton"
                (click)="redirectToWelcome()">
          Welcome</button>
      </div>

    </div>

  </div>

</div>
