import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import {
  isHigher0Lower100Validator,
  ishigherThan2020Validator,
  isNotEmptyValidator
} from "../../Shared/forms-shared/validators";
import {Transaction, TransactionFilter, TransactionService} from "../../../services/transaction.service";
import {CardLimitGlComponent} from "../../Cards/Limits/card-limit-gl/card-limit-gl.component";
import {TransactionsDisplayComponent} from "../transactions-display/transactions-display.component";

@Component({
  selector: 'app-tr-filter',
  templateUrl: './tr-filter.component.html',
  styleUrls: ['./tr-filter.component.css']
})
export class TrFilterComponent implements OnInit{
  @Output() filterData = new EventEmitter<TransactionFilter>()
  @Output() isFilterTransactions = new EventEmitter<boolean>()
  filter_option !: string

  transactions_list !: Transaction[]
  transactions_display !: Transaction[]
  displayTransactions !:boolean
  validationErrors : any = {}
  fieldWasEdited: any = {}
  validateOn: string = 'change'
  fieldsConfig !: FormFieldConfig[]
  constructor(
    public transactionsService: TransactionService,

  ) { }

  _fieldValues:any = { }

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues:any) {
    this._fieldValues= newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  updateFieldValue(fieldName:string, newValue:String) {
    this.fieldValues = { ... this.fieldValues, [fieldName]: newValue}
  }
  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  cancelFilter():void{
    this.isFilterTransactions.emit(false)
  }

  updatedFieldsConfig(data:any):void{
    this.filter_option = data

    if(data == '0') { // month
      this.fieldsConfig[0]['validators'] = [isNotEmptyValidator, ishigherThan2020Validator]
      this.fieldsConfig[1]['validators'] = [isNotEmptyValidator]
      this.fieldsConfig[2]['validators'] = []
      return
    }

    if(data == '1') { // period

      this.fieldsConfig[0]['validators'] = []
      this.fieldsConfig[1]['validators'] = []
      this.fieldsConfig[2]['validators'] = [isNotEmptyValidator]
      return
    }
  }

  filter():void{
    if(this.filter_option == '0') { // month
      this.fieldValues['period'] = 0
      this.fieldWasEdited['fieldWasEdited'] = true
    } else if(this.filter_option== '1') { // period
      this.fieldValues['year'] = 0
      this.fieldValues['month'] = 0
      this.fieldWasEdited['year'] = true
      this.fieldWasEdited['month'] = true
    }

    this.validationErrors = this.getvalidationErrors(true)

    if(Object.keys(this.validationErrors).length > 0)
      return

    this.filterData.emit(this.fieldValues)
    this.isFilterTransactions.emit(false)
  }

  ngOnInit() {

    this.fieldsConfig = this.transactionsService.filterFormFields
    for(let field of this.fieldsConfig){
      this.fieldValues[field.name] = ""
    }
    this.fieldValues['limit'] = 1000 // BE
    this.fieldWasEdited['limit'] = true
  }

}
