import {Component, OnInit} from '@angular/core';
import {Transaction} from "../../../../services/transaction.service";
import {Account, AccountService} from "../../../../services/account.service";
import {appearSlow} from "../../../Shared/animations";
import {SharedataService} from "../../../../services/sharedata.service";

@Component({
  selector: 'app-zelle-send',
  templateUrl: './zelle-send.component.html',
  styleUrls: ['./zelle-send.component.css'],
  animations: [appearSlow]
})
export class ZelleSendComponent implements OnInit {

  monthNames = ['', "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  isDisplayTransactionsHisto :boolean = true
  isLargeScreen !: boolean
  getMonthName(data:string){
    return this.monthNames[Number(data)]
  }
  sender_accounts !:string[][]
  histoData !: Transaction[];
  isHistoData !: boolean

  isScrollHisto !:boolean

  constructor(
    private accountsService: AccountService,
    private shareDataService: SharedataService
  ) { }

  getHistoData():void{
    this.histoData  = [
      {
        "trans_id": "JH9I0cKhDZxrEvNERxGRaWE=",
        "type": "Account Origination",
        "dt_when": "2023-11-06",
        "amount": "0.00",
        "account_balance": "0.00",
        "bucket_name": "PRI",
        "desc": "Opening Balance",
        "month": 11,
        "year": 2023,
        "status" :"Success",
      },
      {
        "trans_id": "Vro3aKF2+N8S3obCddVlsdg=",
        "type": "Internal depository",
        "dt_when": "2023-11-28",
        "amount": "10000.00",
        "account_balance": "10000.00",
        "bucket_name": "PRI",
        "desc": "Added Funds for testing",
        "month": 11,
        "year": 2023,
        "status" :"Success",
      },
      {
        "trans_id": "5Y6bpmHiP84uWs+pHsloDpA=",
        "type": "Internal depository",
        "dt_when": "2024-01-03",
        "amount": "-100.00",
        "account_balance": "9900.00",
        "bucket_name": "PRI",
        "desc": "Internal Transfer to maria.negri@oliphantusa.com",
        "month": 1,
        "year": 2024,
        "status" :"Success",
      },
      {
        "trans_id": "gPGjexxRpTOsdw8F7TzCQfU=",
        "type": "Internal depository",
        "dt_when": "2024-01-03",
        "amount": "-200.00",
        "account_balance": "9800.00",
        "bucket_name": "PRI",
        "desc": "Internal Transfer to maria.negri@oliphantusa.com",
        "month": 1,
        "year": 2024,
        "status" :"Success",
      },
      {
        "trans_id": "ms0wTyDEjmGIfpHkV4ErAAU=",
        "type": "Internal depository",
        "dt_when": "2024-01-03",
        "amount": "-300.00",
        "account_balance": "9700.00",
        "bucket_name": "PRI",
        "desc": "Internal Transfer to maria.negri@oliphantusa.com",
        "month": 1,
        "year": 2024,
        "status" :"Success",
      },
      {
        "trans_id": "4aRA8YWQiAhzr5nxracBa+s=",
        "type": "Internal depository",
        "dt_when": "2024-01-03",
        "amount": "-100.00",
        "account_balance": "9600.00",
        "bucket_name": "PRI",
        "desc": "Internal Transfer to maria.negri@oliphantusa.com",
        "month": 1,
        "year": 2024,
        "status" :"Success",
      }
    ];
    this.isHistoData = this.histoData.length > 0 ? true : false
    this.isScrollHisto = this.histoData.length > 8 ? true : false
  }



  displayZelleHisto(data:boolean){
    if(!this.isLargeScreen){
      this.isDisplayTransactionsHisto = data
    }
  }

  getSenderAccounts():void{
    this.accountsService.getAccounts().subscribe({next:(res)=>{
        const accounts_list_sender = res.accounts.filter((acc:Account) => acc.is_primary === 1)
        this.sender_accounts = accounts_list_sender.map((acc:Account) => [acc.id, acc.number.toString()])
      }})
  };

  ngOnInit() {
    this.shareDataService.getScreenSizeLarge().subscribe( (value)=>{
      this.isLargeScreen = value
    })
    this.getHistoData()
    this.getSenderAccounts()
  }


}
