import {Component, Input, OnInit} from '@angular/core';
import {AuthService, User } from "../../../services/auth.service";
import {Router} from "@angular/router";
import {SharedataService} from "../../../services/sharedata.service";
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ErrorDialogMsgComponent} from "../../Errors/error-dialog-msg/error-dialog-msg.component";
import {ProfileService} from "../../../services/profile.service";
import {environment} from "../../../../environments/environment";
import {animate, style, transition, trigger} from "@angular/animations";
import {slideInLeft, slideInLower, slideInRight, slideInUpper} from "../../Shared/animations";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  providers: [AuthService],
  styleUrls: ['./login.component.css'],
  animations: [ slideInLeft, slideInLower, slideInRight, slideInUpper ],
})
export class LoginComponent implements OnInit{

  @Input() validateOn: string = 'change'

  brand_img  :string = 'assets/images/bank_imgs/logo_blue_' + environment.brand + '.svg'
  brand :string = environment.brand
  forgotPassword: boolean = false

  errorLogin: string = ''

  loggedInUser: User | undefined;

  docsPrivatePolicy !:string
  docsPrivatePolicyCRB !:string

  constructor(
    private authService: AuthService,
    private profileService:ProfileService,
    private router: Router,
    private shareService : SharedataService,
    public dialog : MatDialog
  ) {}

  fieldsConfig !: FormFieldConfig[]

  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues:any) {
    this._fieldValues= newFieldValues
    if (this.validateOn=='change') {
    this.validationErrors = this.getvalidationErrors(false)
    }
  }
  validationErrors : any = {}
  getvalidationErrors(isSubmit:boolean){

    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit === true){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }

    return errors
  }

  fieldWasEdited: any = {}

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    //if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
    this.validationErrors = this.getvalidationErrors(false)
    //}
  }

  updateFieldValue(fieldName:string, newValue:string) {
    this.fieldValues = { ... this.fieldValues, [fieldName]: newValue }
  }

  changePassword():void{
    this.forgotPassword = true
  }

  submitForm() {
    this.validationErrors = this.getvalidationErrors(true)
    if(Object.keys(this.validationErrors).length > 0) {
      return
    }
    this.authService.login(this.fieldValues).subscribe(res => {

          if (res.e_code == 1) { // redirect to home

            // Verify email
            if(res.response.is_email_verified  == 1) {
              this.shareService.setVerifiedEmail('true')}
            else { this.shareService.setVerifiedEmail('false') }

            this.loggedInUser = res.response as User;
            this.shareService.setUserEmail(res.response.email)
            this.shareService.setUserId(res.response.user_id)
            sessionStorage.setItem('isLoggedIn', 'true');
            this.router.navigateByUrl('/main/home');
        }

          // Register new device
          else if(res.e_code ==4) {
            sessionStorage.setItem('isLoggedIn', 'true');
            this.shareService.setUserId(res.user_id)
            this.router.navigateByUrl('/register')
          }

          // Continue kyc
          else if(res.e_code ==3) {
            sessionStorage.setItem('isUserKYC', 'true');

            this.shareService.setUserId(res.response.user_id)
            this.shareService.setSignUpState(res.response.step)

            if (res.response.step == 1){
              this.router.navigateByUrl('/signup/legal');
            }
            else if(res.response.step == 2) {
              this.router.navigateByUrl('/signup/phone');
            }
            else if(res.response.step == 3) {
              this.shareService.setPhoneNumber(res.response.phone)
              this.shareService.setPhoneNumberId(res.response.phone_id)
              this.router.navigateByUrl('/signup/phone');
            }

            else if (res.response.step == 4){
              this.router.navigateByUrl('/profile');
            }
            else if (res.response.step == 5){
              // Bank profile pending --> opendialog + "Bank auth pending message"
              const dialogConfig = new MatDialogConfig();
              dialogConfig.data = { status: "202" , msg: "Bank authorization is pending. You will be notified through email on any updates." }
              dialogConfig.panelClass = 'full-30-dialog';
               this.dialog.open(ErrorDialogMsgComponent,dialogConfig)
            }
            else if (res.response.step == 6){
              // Galileo account creating pensing --> opendialog + "Account and cards are still pending."
              const dialogConfig = new MatDialogConfig();
              dialogConfig.data = { status: "202" , msg: "Account and cards are still pending. You will be notified through email on any updates." }
              dialogConfig.panelClass = 'full-30-dialog';
              this.dialog.open(ErrorDialogMsgComponent,dialogConfig)
            }
        }
    });
  }

  getLegalTerms():void{
    this.profileService.getLegalDicsKYC().subscribe({
      next:(res)=>{
        this.docsPrivatePolicy = res.res[2].doc
        this.docsPrivatePolicyCRB = res.res[4].doc
      }})
  }

  redirectToFile(url:string){
    window.open(url, "_blank");
  }

  ngOnInit() {
    this.getLegalTerms()

    this.fieldsConfig = this.authService.formFields

    // Set initial values and pass user email
    for (let field of this.fieldsConfig)
      this.fieldValues[field.name] = ""
  }

}
