import {Component, OnInit} from "@angular/core";
import { environment } from "src/environments/environment";
import {RecipientExtended, RecipientsService} from "../../../../../services/recipients.service";
import {Router} from "@angular/router";


@Component({
  selector: 'app-pay-req-new',
  templateUrl: './pay-req-new.component.html',
  styleUrls: ['./pay-req-new.component.css']
})
export class PayReqNewComponent implements OnInit {

  successNewRequest: boolean = false
  brand: string = environment.brand
  search_word !: string | undefined


  _select_amount !: string
  get select_amount() {
    return this._select_amount
  }
  set select_amount(newValues: string) {
    Number(newValues) > 0 ? this._select_amount = newValues : this._select_amount = "" }

  _select_memo !: string
  get select_memo() {
    return this._select_memo
  }
  set select_memo(newValues: string) {
    this._select_memo = newValues
  }

  amountObject: any = {}
  totalAmount: number = 0.00

  recipients: RecipientExtended[] = []
  recipients_all: RecipientExtended[] = []
  recipients_display: RecipientExtended[] = []
  recipients_selected: RecipientExtended[] = []

  display_selected: boolean = false
  display_selected_all: boolean = false

  display_filtered_all: boolean = false
  recIds_selected: string[] = []


  constructor(
    private recipientsService: RecipientsService,
    private router: Router
  ) {}

  removeRecipient(id: string) {
    this.search_word = ""
    // Remove the recipient from recipients_selected displayed to the user
    let temp_recipients = this.recipients_selected.filter(t => t.id != id)
    this.recipients_selected = temp_recipients

    // Recover recipient removed
    let recip = this.recipients_all.filter(r => r.id == id)

    // Update recipients : put the removed recipient back to the list
    this.recipients = [...this.recipients, ...recip]

    this.updateDisplaySelected()
  }


  updateDisplaySelected(): void {
    // Update display selected recipients
    if (this.recipients_selected.length > 0) {
      this.display_selected = true

      // Update scroll bar
      this.recipients_selected.length > 2 ?
        this.display_selected_all = true : this.display_selected_all = false

    } else {
      // Update display selected recipients
      this.display_selected = false
      // Update scroll bar
      this.display_selected_all = false
    }
  }

  addRecipient(id: string, data: RecipientExtended): void {
    this.search_word = ""
    // Add and display selected recipient
    this.recipients_selected = [...this.recipients_selected, data]
    this.updateDisplaySelected()

    // Remove the selected(added) recipient from the list available to choose from
    this.updateRecipientsAdd(id)

    // Clean input search field before new search
    this.recipients_display = []
    this.search_word = ""
  }

  updateRecipientsAdd(data: string): void {
    // Remove the selected(added) recipient from the list available to choose from
    this.recipients = this.recipients.filter((rec: RecipientExtended) => rec.id !== data)
  }

  updateRecipientAmount(id: string, data: any) {
    if (data != '' && Number(data) >0) {
      this.amountObject = {...this.amountObject, [id]: data}

      let arr = Object.values(this.amountObject).map(el => Number(el))
      this.totalAmount = arr.reduce((a, b) => a + b)

    } else {
      delete this.amountObject[id]
      this.totalAmount = 0
    }

  }


  addMemo(data: string) {
    data.split('').length <15 ? this.select_memo=data : this.select_memo = ""

  }

  sendRequest(): void {
    if(this.totalAmount > 0 && this.select_memo != ""){
      this.successNewRequest = true
    }
  }

  filterRecipient(newValue: string): void {
    if (newValue) {
      const filteredList_name = this.recipients.filter(t => t.first_name.toLowerCase().includes(newValue.toLowerCase()))
      const filteredList_lastname = this.recipients.filter(t => t.last_name.toLowerCase().includes(newValue.toLowerCase()))
      const allFilteredRecipients = [...filteredList_name, ...filteredList_lastname]

      // Keep unique ids
      let uniqueIds = [...new Set(allFilteredRecipients.map((rec: RecipientExtended) => rec.id))]

      // Filter/Display unique recipients
      this.recipients_display = this.recipients.filter((rec: RecipientExtended) => uniqueIds.includes(rec.id))

      this.recipients_display.length > 2 ?
        this.display_filtered_all = true : this.display_filtered_all = false

    } else {
      this.recipients_display = []
      this.search_word = ""
    }

  }

  useZelle(): void {
  }


  getRecipientsData(): void {
    this.recipientsService.getRecipients().subscribe({
      next: (res) => {
        if (res.e_code == 1) {
          this.recipients_all = res.recipients.filter((rec: RecipientExtended) => rec.kredio || rec.zelle)
          this.recipients = res.recipients.filter((rec: RecipientExtended) => rec.kredio || rec.zelle)
          this.recipients_display = []
          console.log(this.recipients)
        }}
      })
  }

  startNewRequest(data: boolean) {
    this.totalAmount = 0
    this.amountObject = {}
    this.recipients_selected = []
    this.updateDisplaySelected()

    this.getRecipientsData()
    this.successNewRequest = data
  }
  ngOnInit() {
    this.getRecipientsData()
  }
}






























