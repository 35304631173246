<div class="container-fluid" >

  <div class="row p-1">

    <div class="col-md-1 col-2 p-0 d-flex justify-content-center   ">
        <img class="img-fluid" src="../../../../../assets/images/cards/activate_card.svg" alt="Card Img">
    </div>

    <div class="col-md-10 col-8 p-0 d-flex align-items-center" >
      <p class="transfer-text  p-0 mb-0">Filter Transactions</p>
    </div>

    <div class="col-md-1 col-2  p-0 d-flex align-items-center justify-content-center button-pointer"
         (click)="cancelFilter()">
      <img class="img-fluid"
           src="../../../../../assets/images/edit_profile/cancel_edit.svg"/>
    </div>

  </div>

  <div class="row my-4 ">
    <p class="p-info">Select Filter Parameters</p>
  </div>

  <div class="row px-1 my-3" >
    <div class=" d-flex justify-content-center">
      <div class="form-floating w-100 border-0" >
        <select class="form-select border-0"
                id='filterOption'
                [ngModel]="filter_option"
                (ngModelChange)="updatedFieldsConfig($event)"
        >
          <option [value] = '0' > Select Month</option>
          <option [value] = '1' >Select Period (months)</option>
        </select>
        <label class="form-label ms-2">Select Month/Period (in months)</label>
      </div> <!--Close col -->
    </div>
  </div> <!--Close row-->

  <div *ngIf="filter_option == '0'" class="p-0" >

    <div class="row px-1 my-3" >
      <div class=" d-flex justify-content-center">
        <div class="form-floating w-100 border-0 ">
          <input
            id= 'option0FilterYear'
            [class] ="validationErrors[fieldsConfig[0].name]? 'form-control formError ': 'form-control inputStyle'"
            [placeholder]="fieldsConfig[0].placeHolder"
            [type]="fieldsConfig[0].type"
            [ngModel]="fieldValues[fieldsConfig[0].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[0].name)"
          >
          <label class="form-label ms-2">{{fieldsConfig[0].displayName}}</label>
        </div>
      </div>
      <p  class="error" id="errorOption0Year" *ngIf="this.validationErrors[fieldsConfig[0].name]">
        {{this.validationErrors[fieldsConfig[0].name]}}
      </p>
    </div> <!--Close row-->

    <div class="row px-1 my-3 ">
      <div class="form-floating border-0" >
        <select class="form-select border-0 "
                id='option0FilterMonth'
                [ngModel]="fieldValues[fieldsConfig[1].name]"
                (ngModelChange)="updateFieldValue(fieldsConfig[1].name, $event)"
        >
          <option value="" [disabled] = true [selected]="true" >Select Month</option>
          <option [value] = 1 >January</option>
          <option [value] = 2 >February</option>
          <option [value] = 3 >March</option>
          <option [value] = 4 >April</option>
          <option [value] = 5 >May</option>
          <option [value] = 6 >June</option>
          <option [value] = 7 >July</option>
          <option [value] = 8 >August</option>
          <option [value] = 9 >September</option>
          <option [value] = 10 >October</option>
          <option [value] = 11 >November</option>
          <option [value] = 12 >December</option>
        </select>
        <label class="ms-2">{{[fieldsConfig[1].displayName]}}</label>
      </div> <!--Close form -->
      <p  class="error my-1" id="errorOption0Month" *ngIf="this.validationErrors[fieldsConfig[1].name]">
        {{this.validationErrors[fieldsConfig[1].name]}}
      </p>
    </div>

  </div> <!--Close row-->

  <div *ngIf="filter_option == '1'" class="p-0" >
    <div class="row px-1 my-3">
      <div class="form-floating border-0 ">
        <input
          id='option1Filter'
          [class] ="validationErrors[fieldsConfig[2].name]? 'form-control formError ': 'form-control inputStyle'"
          [placeholder]="fieldsConfig[2].placeHolder"
          [type]="fieldsConfig[2].type"
          [ngModel]="fieldValues[fieldsConfig[2].name]"
          (ngModelChange)="updateFieldValue(fieldsConfig[2].name, $event)"
          (blur)="setFieldToEdited(fieldsConfig[2].name)"
        >
        <label class="form-label ms-2">{{fieldsConfig[2].displayName}}</label>
      </div>
      <p  class="error my-1" id="errorOption1" *ngIf="this.validationErrors[fieldsConfig[2].name]">
        {{this.validationErrors[fieldsConfig[2].name]}}
      </p>
    </div>
  </div> <!--Close row-->

  <div class="row mt-4 mb-2">
    <div class=" col-lg-6 col-12  d-flex justify-content-center align-items-center my-2  ">
      <button class="yellow-button w-lg-75 w-100 rounded-2 py-3" id="btnCancel"  (click)="cancelFilter()">Cancel</button>
    </div>
    <div class=" col-lg-6 col-12  d-flex justify-content-center align-items-center my-2  ">
      <button id="activateBtn" class="violet-button w-lg-75 w-100 rounded-2 py-3" id="btnContinue"
              (click)="filter()">Filter</button>
    </div>

  </div> <!--Close row-->

</div>  <!--Close container-->
