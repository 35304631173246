<div class="container-fluid " [@appearSlow]>
  <app-nav-buttons
    [current_route]= your_route
  ></app-nav-buttons>

  <!--Display large screens-->
  <div  class="d-none d-md-block m-0 py-0 px-3 ">
    <div class="py-1 px-0 m-0 bg-color-clear mt-3 rounded-1 " >
      <div class="col-12  rounded-3 ">
        <div class="row text-center align-content-center ">
          <p class="p-title mb-0 py-2">Request Payment</p>
          <p class="p-title-sub my-1">You can see the pending request and the historic requests</p>
        </div>
      </div>
    </div>

    <div *ngIf="!isPaymentsRequest" class="py-1 px-0 m-0 bg-color-clear rounded-1">
      <div class="row py-1 px-3 mx-3 my-0 " >
        <div class="col-12 bg-color-clear rounded-3 ">
          <div class="row my-1">
            <p class="p-title-section my-1">Pending Requests</p>
          </div>
          <div class="m-0 p-0" [ngClass]="pending_high? 'scroll-requests':''">
            <div *ngFor="let req of requests_pending"  >
              <div class="row mx-2 py-1 r-underline">
                <div class="col-3">
                  <div class="row">
                    <div class="col-3 d-flex align-items-center justify-content-center p-0 ">
                      <img src="../../../../../assets/images/paym-request/person_logo.svg" class="img-fluid ">
                    </div>
                    <div class="col-9 d-flex align-items-center px-0  ">
                      <p class="p-name mb-0  ">{{req.name}} {{req.surname}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-2 d-flex justify-content-center align-items-center px-0 ">
                  <p class="p-date mb-0 ">{{req.date}}</p>
                </div>
                <div class="col-3 d-flex align-items-center px-0 ">
                  <p class="p-desc mb-0 align-content-center ms-0 ">{{req.description}}</p>
                </div>
                <div class="col-2 d-flex align-items-center justify-content-end">
                  <p class="p-amount mb-0 pe-1">${{req.amount}}</p>
                </div>
                <div class="col-1   px-0 ">
                  <div class="btn-cancel rounded-4 text-center py-2 mx-1 button-pointer">Decline</div>
                </div>
                <div class="col-1   px-0">
                  <div class="btn-ok rounded-4 text-center py-2 mx-1 button-pointer ">Approve</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <div class="row py-1 px-3 mx-3 my-0 " >
        <div class="col-12 bg-color-clear rounded-3 ">
          <div class="row">
            <div class="col-md-7 col-12 my-2">
              <div class="form-floating w-100"  >
                <input
                  [id]="'inputFilter'"
                  [class] ="'form-control'"
                  [placeholder]="'Search'"
                  [type]="'string'"
                  [ngModel]="search_word"
                  (ngModelChange)="filterAlert($event)"
                  style="background: #F5F5F2 url('../../../../../assets/images/accounts/magnifier.svg') 95%  no-repeat"
                >
                <label class="form-label ms-2">Search</label>
              </div>
            </div>
            <div class="col-md-5 col-12">
              <div class="row">
                <div class="col-6 d-flex justify-content-center my-2 px-1 ">
                  <button class="text-center py-3 w-100  rounded-4 p-filter"
                          (click)="isFilter()"  id="btn_filter">
                    <img src="assets/images/recipients/filter_recp_white.svg">
                    Filters
                  </button>
                </div>
                <div class=" col-6 d-flex justify-content-center my-2 px-1 ">
                  <button class="violet-button text-center py-3 w-100  rounded-4"
                          (click)="addNewRequest()"  id="btn_add">
                    + New Request
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row my-1">
            <p class="p-title-section my-1">Requests Histo</p>
          </div>
          <div class="row m-0 p-0" [ngClass]="histo_high? 'scroll-requests-histo':''">
            <div *ngFor="let reqHisto of requests_histo"  >
              <div class="row mx-2 py-1 r-underline">
                <div class="col-2 d-flex justify-content-center align-items-center px-0 ">
                  <p class="p-date mb-0">{{reqHisto.date}}</p>
                </div>
                <div class="col-3">
                  <div class="row">
                    <div class="col-3 d-flex align-items-center justify-content-end p-0">
                      <img src="assets/images/paym-request/person_logo.svg"
                           class="img-fluid">
                    </div>
                    <div class="col-9 d-flex align-items-center px-0">
                      <p class="p-name mb-0 ms-3">{{reqHisto.name}} {{reqHisto.surname}}</p>
                    </div>
                  </div>
                </div>

                <div class="col-3  align-items-center ">
                  <p class="p-desc mb-0">{{reqHisto.description}}</p>
                </div>
                <div class="col-2 d-flex justify-content-end align-items-center">
                  <p class="p-amount mb-0 pe-1 ">${{reqHisto.amount}}</p>
                </div>
                <div class="col-1 d-flex text-center align-items-center ">
                  <img *ngIf="reqHisto.channel =='Zelle'" src="../../../../../assets/images/paym-request/zelle_logo.svg" class="img-fluid ">
                  <img *ngIf="reqHisto.channel == brand " [src]="'assets/images/paym-request/logo_' + brand + '.svg'" class="img-fluid ">
                </div>
                <div class="col-1 d-flex align-items-center text-center  px-0" >
                  <p *ngIf="reqHisto.status == 'Approved'" class="p-approved mb-0" >Approved</p>
                  <p *ngIf="reqHisto.status == 'Declined'" class="p-declined mb-0" >Declined</p>
                  <p *ngIf="reqHisto.status == 'Pending'" class="p-pending mb-0">Pending</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
    </div>

    <div *ngIf="isPaymentsRequest" class="py-1 px-0 m-0 bg-color-clear">
      <app-pay-req-new></app-pay-req-new>
    </div>

    <div *ngIf="isFilterPaymentRequest">
      <app-modal-filter-tr>
        <app-pay-req-filter
          (closeFilter)="isFilter()"
          (filterRequests)="filterRequests($event)"
        ></app-pay-req-filter>
      </app-modal-filter-tr>
    </div>

  </div>

  <!--Display small screens-->
  <div  class="d-block d-md-none m-0 p-0">
    <app-pay-req-small></app-pay-req-small>
  </div>


</div>
