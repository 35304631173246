import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Account,AccountService} from "../../../services/account.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {TrasnfersGreendotComponent} from "../trasnfers-greendot/trasnfers-greendot.component";
import {environment} from "../../../../environments/environment";
import {animate, style, transition, trigger} from "@angular/animations";
import {appearSlow} from "../../Shared/animations";
import {ZelleEnrollComponent} from "../Zelle/zelle-enroll/zelle-enroll.component";

@Component({
  selector: 'app-payments-tranfers',
  templateUrl: './payments-transfers.html',
  styleUrls: ['./payments-transfers.css'],
  animations: [ appearSlow ],
})
export class PaymentsTransfers implements OnInit{

  isMove :boolean = true
  isSend :boolean = false
  isRequest :boolean = false

  brand :string = environment.brand
  isLocalTransfer !: boolean
  isInternalTransfer !: boolean

  sender_accounts !:string[][]
  receiver_accounts !:string[][]
  your_route : string = 'Payments & Transfers'

  constructor(
    private router : Router,
    private accountsService : AccountService,
    public dialog : MatDialog
  ) { }


  greenDotTransfer():void {
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.data = {  }
    // dialogConfig.panelClass = 'full-40-dialog';
    // this.dialog.open(TrasnfersGreendotComponent,dialogConfig)
  }
  makeLocalTransfer():void {
    this.receiver_accounts = this.sender_accounts
    this.isLocalTransfer = true
  }

  makeInternalTransfer():void {
    this.isInternalTransfer = true
  }

  moveMoney(move:any, send:any, request:any) {
    if (move.classList.contains('d-none')) {
       move.classList.remove('d-none')
      this.isMove = true
    }
    if (!send.classList.contains('d-none')){
      send.classList.add('d-none')
      this.isSend = false
    }
    if (!request.classList.contains('d-none')){
      request.classList.add('d-none')
      this.isRequest = false
    }
  }
  sendMoney(move:any, send:any, request:any) {
    if (send.classList.contains('d-none')) {
      send.classList.remove('d-none')
      this.isSend = true
    }
    if (!move.classList.contains('d-none')){
      move.classList.add('d-none')
      this.isMove = false
    }
    if (!request.classList.contains('d-none')){
      request.classList.add('d-none')
      this.isRequest = false
    }
  }
  requestMoney(move:any, send:any, request:any) {
    if (request.classList.contains('d-none')) {
      request.classList.remove('d-none')
      this.isRequest = true
    }
    if (!send.classList.contains('d-none')){
      send.classList.add('d-none')
      this.isSend = false
    }
    if (!move.classList.contains('d-none')){
      move.classList.add('d-none')
      this.isMove = false
    }
  }

  ngOnInit() {
    this.accountsService.getAccounts().subscribe({next:(res)=>{
        if(res.e_code == 1){
          const accounts_list_sender = res.accounts.filter((acc:Account) => acc.is_primary === 1)
          this.sender_accounts = accounts_list_sender.map((acc:Account) => [acc.id, acc.number.toString()])
          const accounts_list_receiver = res.accounts.filter((acc:Account) => acc.is_primary === 0)
          this.receiver_accounts = accounts_list_receiver.map((acc:Account) => [acc.id, acc.number.toString()])
        }}
    })
  }
}

