<div class="container-fluid bg-color-clear ">

  <div class="row mt-5 mb-3   ">
    <div class="text-center">
      <img src="../../../../../assets/images/alerts/warning_triangle.svg" class="img-fluid" alt="Warning">
    </div>
  </div>

  <div class="row mt-5 text-center ">
    <p id="msgConfirmTitle" class="p-alert ">Delete Alert</p>
  </div>

  <div class="row m-2 text-center ">
    <p id="msgConfirm" class="p-wording ">Confirm you want to delete the alerts selected.</p>
  </div>


  <div class="row mt-3 mb-1 px-1">
    <div class="col-lg-6 col-12 my-2 d-flex justify-content-center align-items-center">
      <button class="violet-button  py-3 w-100 rounded-3 button-pointer " id="btnDelete" (click)="deleteAlert()">Delete</button>
    </div>

    <div class="col-lg-6 col-12  my-2 d-flex justify-content-center align-items-center">
      <button class="yellow-button  w-100 py-3 rounded-3 button-pointer" id="btnCancel" (click)="cancelDeleteAlert()">Cancel</button>
    </div>
  </div>

</div>
