import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TransferLocalService} from "../../../../services/transfer-local.service";
import {RecipientExtended, RecipientsService} from "../../../../services/recipients.service";
import {ZelleService} from "../../../../services/zelle.service";
import {FormFieldConfig} from "../../../Shared/forms-shared/typesForm";
import {NgbDate, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {appearSlow} from "../../../Shared/animations";

@Component({
  selector: 'app-zelle-send-money',
  templateUrl: './zelle-send-money.component.html',
  styleUrls: ['./zelle-send-money.component.css'],
  animations: [appearSlow]
})
export class ZelleSendMoneyComponent implements OnInit{
  @Input() accounts_sender !:string[][]

  @Output() isZelleTrHisto = new EventEmitter<boolean>()

  isTransferSuccess : boolean = false
  isTransferInfo : boolean | undefined = false
  receiver_id !: string
  receiver_account !:string
  sender_account !: string

  displayEndDay !:string
  message !:string
  frequency_dict :any = {}
  frequency_words :string = ''
  repeat_payment: boolean = false
  accounts_receiver !:string[]
  fieldsConfig !: FormFieldConfig[]
  fieldsConfigRec !: FormFieldConfig[]


  today = new Date()
  dateRecTransfer!: NgbDateStruct
  minRecTransferEndDate = new NgbDate(this.today.getFullYear(), this.today.getMonth()+1, (this.today.getDate() +1));


  validateOn: string = 'change'

  fieldWasEdited: any = {}
  validationErrors : any = {}
  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues: any) {
    this._fieldValues = newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  fieldRecWasEdited: any = {}
  _fieldValuesRec: any = {}
  validationRecErrors : any = {}
  get fieldValuesRec() {
    return this._fieldValuesRec
  }
  set fieldValuesRec(newValues:any) {
    this._fieldValuesRec = newValues
  }

  updateFieldValueRec(fieldName: string, newValue: string) {
    this.fieldValuesRec = fieldName === 'send_on'|| 'frequency'? {...this.fieldValuesRec, [fieldName]: Number(newValue)} :
      {...this.fieldValuesRec, [fieldName]: newValue}
  }

  updateFieldDateValue(type: string, event: any){
    this.fieldValuesRec = { ... this.fieldValuesRec, 'end_date': event}
  }

  setRecFieldToEdited(fieldName:string) {
    this.fieldRecWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationRecErrors = this.getRecValidationErrors(false)
    }
  }

  getRecValidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfigRec) { //loop through fields
      if(this.fieldRecWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValuesRec[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  repeatPaymentChoice() {
    this.repeat_payment = !this.repeat_payment
  }

  constructor(
    private transferLocalService : TransferLocalService,
    private zelleService : ZelleService,
    private recipientsService : RecipientsService

  ) { }

  updateFieldValue(fieldName: string, newValue: string) {
    this.fieldValues = {...this.fieldValues, [fieldName]: newValue}
  }

  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }


  verifyInformation():void{

    this.validationErrors = this.getvalidationErrors(true)

    if(this.repeat_payment == true){
      this.validationRecErrors = this.getRecValidationErrors(true)
      this.frequency_dict = { 3: 'Monthly',}
      this.frequency_words = this.frequency_dict[3]
    }

    if(Object.keys(this.validationErrors).length > 0 || Object.keys(this.validationRecErrors).length > 0) {
      return
    }

    // Verify Information: Get display information
    this.sender_account = this.accounts_sender.filter((acc:any) => acc[0] === this.fieldValues['loan_id'])[0][1]

    let receiver_selected = this.accounts_receiver.filter((acc: any) => acc[0] === this.fieldValues['receiver_id'])[0]
    this.receiver_id = receiver_selected[0]
    this.receiver_account = receiver_selected[1]


    if (this.fieldValuesRec['end_date'] !== "") {
      const tempTransfer = this.fieldValuesRec['end_date']
      this.displayEndDay = `${tempTransfer.year}-${tempTransfer.month}-${tempTransfer.day}`
    }


    this.isTransferInfo = !this.isTransferInfo
    this.isZelleTrHisto.emit(false)


  }

  submitZellePayment():void {
    this.isTransferSuccess = true

  }

  editTransferInformation():void{
    this.isZelleTrHisto.emit(true)
    this.isTransferInfo = ! this.isTransferInfo
    this.dateRecTransfer = this.fieldValuesRec['end_date']
  }

  makeNewTransfer():void {
    this.isTransferSuccess = false
    this.repeat_payment = false
    this.isTransferInfo = false
    this.isZelleTrHisto.emit(true)


    this.fieldWasEdited = {}
    this.validationErrors  = {}
    this._fieldValues = {}


    this.fieldRecWasEdited= {}
    this._fieldValuesRec = {}
    this.validationRecErrors  = {}


    this.ngOnInit()

    console.log(this.fieldValues)
  }

  createInitialPayloads():void {
    for (let field of this.fieldsConfig)
      this.fieldValues[field.name] = ''

    this.fieldValues['receiver_type'] = 0

    for (let field of this.fieldsConfigRec)
      this.fieldValuesRec[field.name] = ''
  }

  ngOnInit() {

    this.fieldsConfig  = this.zelleService.fieldsConfig

    this.fieldsConfigRec = this.zelleService.fieldsConfigRec

    this.createInitialPayloads()

    // Get Zelle recipients
    this.recipientsService.getRecipients().subscribe({ next:res =>{
        if(res.e_code == 1) {
          let recip_local = res.recipients.filter((rec:RecipientExtended)=> !rec.zelle == true)
          this.accounts_receiver = recip_local.map(
            (rec:RecipientExtended) => [rec.id , "Zelle " + rec.first_name +"-"+ rec.last_name])}
      }})
  };

}
