import {Component, Input, OnInit} from '@angular/core';
import {Alert, AlertsService} from "../../../../services/alerts.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-alerts-display',
  templateUrl: './alerts-display.component.html',
  styleUrls: ['./alerts-display.component.css']
})
export class AlertsDisplayComponent implements OnInit{

  @Input()picked_alert !:Alert
  alert !:Alert
  constructor(
    private alertsService : AlertsService,
    private router: Router,
  ) { }



  isReadAlert ():void {
    let payload = {
      notifications:[this.alert.id]
    }
    this.alertsService.readAlerts(payload).subscribe((res)=>{
      this.reloadPage()
     })


  }

  reloadPage(){
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    })
  }

  ngOnInit() {
    this.alert = this.picked_alert

  }
}
