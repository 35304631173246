import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-home-cash-back-alert',
  templateUrl: './home-cash-back-alert.component.html',
  styleUrls: ['./home-cash-back-alert.component.css']
})
export class HomeCashBackAlertComponent implements OnInit{

  brand :string = environment.brand
  alertMsg !:string
  constructor(
    public dialogRef: MatDialogRef<HomeCashBackAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  closeMessage(): void{
    this.dialogRef.close()
  }

  ngOnInit() {
    this.alertMsg = this.data.message
  }
}
