<div class="container-fluid h-100">

  <!-- Left side Image asset/logo/terms and policies and right side signin and links -->
  <div class="row h-100">

    <!-- Left side -->
    <div class="col-lg-6 d-none d-lg-inline" style="background-color: #4C49F5">

      <!-- Logo + Text -->
      <div class="row d-lg-flex d-none" style="height: 25%">
        <div class="get-started text-responsive d-flex align-items-end justify-content-center" [@slideInUpper] >
          Get Started
        </div>
        <div class="d-lg-flex d-none d-flex  justify-content-center">
          <img [src]= brand_img class="img-fluid" [@slideInUpper] style="scale: 70%" alt="">
        </div>
      </div>

      <!-- Image asset -->
      <div class="row d-lg-flex d-none" style="height: 70%" [@slideInRight]>
        <div class="d-flex justify-content-center">
          <img class="img-fluid" src="assets/images/login/login.svg" alt="">
        </div>
      </div>

      <!-- Standard terms and policies -->
      <div class="row d-lg-flex d-none" [@slideInLower]>
        <div class="text-center" style="font-weight: bold">
          <a  class="bottom-a button-pointer" id="btnPrivacyPolicy"
              (click)="redirectToFile(this.docsPrivatePolicy)"> Privacy Policy&nbsp;&nbsp;|&nbsp;</a>
          <a  class="bottom-a button-pointer" id="btnPrivacyPolicyCRB"
              (click)="redirectToFile(this.docsPrivatePolicyCRB)" > Legal&nbsp;&nbsp;|&nbsp;</a>
          <a  class="bottom-a button-pointer" id="btnContact" routerLink="/"> Contact&nbsp;&nbsp;</a>
        </div>
      </div>

    </div>

    <!-- Right side -->
    <div class="col-lg-4 offset-lg-1 col-12 h-100 py-0" [@slideInLeft]>

      <!-- Small screen-->
      <div class="row  d-lg-none mt-5 " >
        <p class="p-title mb-0 py-2 ">Success</p>
      </div>

      <!-- Large screen-->
      <div class="row  d-none d-lg-block mt-5  " >
        <p class="p-title mb-0 py-2">Success</p>
      </div>



      <div class="h-75 pt-5">
        <div class ="d-flex align-items-end justify-content-center my-2">
          <img src="assets/images/login/success_logo.svg" alt="Success">
        </div>

        <p class="text-center my-4"> Your account is now successfully connected.</p>

      </div>


      <div class="d-flex justify-content-center">
        <button id="btnContinue" class="violet-button  py-3 w-100 rounded-4 button-pointer "
                (click)="redirect()" >Continue</button>
      </div>


    </div>

  </div>

</div>

