<div  class="container-fluid" [@appearSlow]  >


  <div class="row">

    <div class="col-2 d-flex align-items-center justify-content-center" >
      <img  class="img-fluid"  src="assets/images/edit_profile/profile_img.svg" />
    </div>

    <div class="col-8 d-flex align-items-center" >
      <p class="p-full-name m-0" >{{profileData['first_name']}} {{profileData['last_name']}}</p>
    </div>

    <div class="col-2 d-flex align-items-center justify-content-center button-pointer">
      <img id="closeCross" class="img-fluid" (click)="cancelEditEmail()" src="assets/images/edit_profile/cancel_edit.svg"/>
    </div>

  </div>

  <!-- successEmail == false-->
  <div *ngIf="!successEmail" class="row my-3 d-flex justify-content-center" >

      <div class="row my-2 ">
        <p class="p-personalInfo">Edit Email Address</p>
      </div>

    <div class="row my-2 ">
      <p class="p-desc">Enter your new email address and check your new inbox to confirm</p>
    </div>

      <div class="row form-floating my-3">
        <input
          [id] = "'inputEmail'"
          class="form-control bg-color-clear"
          [ngClass] ="validationErrors[fieldsConfig[0].name]? ' formError': ''"
          [placeholder]="fieldsConfig[0].placeHolder"
          [type]="fieldsConfig[0].type"
          [ngModel]="fieldValues[fieldsConfig[0].name]"
          (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
          (blur)="setFieldToEdited(fieldsConfig[0].name)"
        >
        <label class="form-label ms-2 ">{{fieldsConfig[0].displayName}}</label>
      </div>
      <p id="error-email" class="error my-1" *ngIf="this.validationErrors[fieldsConfig[0].name]">
        {{this.validationErrors[fieldsConfig[0].name]}}
      </p>

    </div>

  <div *ngIf="!successEmail"  class="row">
      <div class="col-12 px-2 ">
        <button id="btnConfirm" (click)="confirmChanges()" class="violet-button rounded-4 w-100 py-3  ">
          Confirm Email
        </button>
      </div>
    </div>

  <!-- successEmail == true-->

  <div *ngIf="successEmail" class="row my-5" >
        <div class="offset-2 col-8 text-center py-3  ">
          <img class=" img-fluid"  src="assets/images/edit_profile/success_logo.svg" />
        </div>
        <div class="offset-2 col-8  p-0"  >
          <p id="msg_1" class="p-response text-center fw-bold my-2">You will be logged out shortly.</p>
        </div>
        <div class="offset-2 col-8  p-0">
          <p id="msg_2" class="p-response text-center fw-bold my-2">Please, check your email to confirm.</p>
        </div>
  </div>


  <div *ngIf="confirmChangesModal">
    <app-modal-new-acc>
      <app-profile-confirm-change
        (confirmChanges)="submitNewEmail($event)"
      ></app-profile-confirm-change>
    </app-modal-new-acc>
  </div>


</div>

