<div class="container-fluid  " >

    <div class="row mt-2">

      <div class="col-md-1 col-2 d-flex justify-content-center  p-0">
        <div class="w-100  h-100">
          <img class="img-fluid h-100" src="../../../../../assets/images/cards/activate_card.svg" alt="Card Img">
        </div>
      </div>

      <div class="col-md-10 col-8 p-0 d-flex align-items-center" >
        <p class="transfer-text  p-0 mb-0">Transfer wages to <strong>{{brand}}</strong> account</p>
      </div>

      <div class="col-md-1 col-2  p-0 d-flex align-items-center justify-content-center button-pointer">
        <img class=" w-75 img-fluid"  (click)="cancelActivate()"
             src="../../../../../assets/images/edit_profile/cancel_edit.svg"/>
      </div>

    </div>

    <div class="row my-4 px-2">
      <p class="p-info">Enter the information requested to start enjoying your <strong>{{brand}}</strong>
        benefits by transferring all of your wages.</p>
    </div>

    <div class="row mb-3 px-2 my-3" >
      <div class=" d-flex justify-content-center">
        <div class="form-floating w-100 " >
          <select class="form-select div-form"
                  [id]="'selectedTransfer'"
                  [ngModel]="fieldValues[fieldsConfig[0].name]"
                  (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
          >
            <option [value] = "'1'" *ngIf="percentAllocation === '1'" >Full Transfer</option>
            <option [value] = "'1'" *ngIf="percentAllocation !== '1'">Percentage Transfer</option>
            <option [value] = "'0'" *ngIf="percentAllocation !== '1'">Fixed Amount Transfer</option>
          </select>
          <label class="form-label ms-2">{{[fieldsConfig[0].displayName]}}</label>
        </div> <!--Close col -->
      </div>
      <p  class="error" *ngIf="this.validationErrors[fieldsConfig[0].name]">
        {{this.validationErrors[fieldsConfig[0].name]}}
      </p>
    </div> <!--Close row-->

    <div *ngIf="entrePercentage" class="row px-2 my-3">
    <div class=" d-flex justify-content-center">
      <div class="form-floating w-100 ">
        <input
          [id]="'selectedPercentage'"
          [class] ="validationErrors[fieldsConfig[3].name]? 'form-control formError ': 'form-control inputStyle'"
          [placeholder]="fieldsConfig[3].placeHolder"
          [type]="fieldsConfig[3].type"
          [ngModel]="fieldValues[fieldsConfig[3].name]"
          (ngModelChange)="updateFieldValue(fieldsConfig[3].name, $event)"
          (blur)="setFieldToEdited(fieldsConfig[3].name)"
        >
        <label class="form-label ms-2">{{fieldsConfig[3].displayName}}</label>
      </div>
    </div>
    <p  class="error" *ngIf="this.validationErrors[fieldsConfig[3].name]">
      {{this.validationErrors[fieldsConfig[3].name]}}
    </p>
  </div> <!--Close row-->

    <div *ngIf="enterFixedAmount" class="row px-2 my-3">
    <div class=" d-flex justify-content-center">
      <div class="form-floating w-100 ">
        <input
          [id]="'selectedFA'"
          [class] ="validationErrors[fieldsConfig[4].name]? 'form-control formError ': 'form-control inputStyle'"
          [placeholder]="fieldsConfig[4].placeHolder"
          [type]="fieldsConfig[4].type"
          [ngModel]="fieldValues[fieldsConfig[4].name]"
          (ngModelChange)="updateFieldValue(fieldsConfig[4].name, $event)"
          (blur)="setFieldToEdited(fieldsConfig[4].name)"
        >
        <label class="form-label ms-2">{{fieldsConfig[4].displayName}}</label>
      </div>
    </div>
    <p  class="error" *ngIf="this.validationErrors[fieldsConfig[4].name]">
      {{this.validationErrors[fieldsConfig[4].name]}}
    </p>
  </div> <!--Close row-->


  <div class="row mt-4 mb-2">
      <div class=" col-lg-6 col-12  d-flex justify-content-center align-items-center my-2  ">
        <button class="yellow-button w-lg-75 w-100 rounded-2 py-3" id="btnCancel"  (click)="cancelActivate()">Cancel</button>
      </div>
      <div class=" col-lg-6 col-12  d-flex justify-content-center align-items-center my-2  ">
        <button id="activateBtn" class="violet-button w-lg-75 w-100 rounded-2 py-3" id="btnContinue"  (click)="transferWages()">Continue</button>
      </div>

    </div> <!--Close row-->


</div>  <!--Close container-->
