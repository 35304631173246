<div class="container-fluid p-0">

  <div *ngIf="!isTransferInfo">
    <br>
    <p class="p-step">Select recipient:</p>
    <!-- First Row = Transfer from/to forms -->
    <div class="row mt-1">

      <div class="col-sm-6 col-12 my-2 ">
        <div class="form-floating ">
          <select
            id="sender"
            [class] ="validationErrors[fieldsConfig[0].name]? 'form-control formError ': 'form-control '"
            [ngModel]="fieldValues[fieldsConfig[0].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[0].name)"
          >
            <option  *ngFor="let option of accounts_sender" [value] = option[0] >{{"Zelle-" + option[1].toString().slice(-4)}}</option>

          </select>
          <label class="form-label" >{{fieldsConfig[0].displayName}}</label>
        </div>
        <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[0].name]"> {{this.validationErrors[fieldsConfig[0].name]}}
      </div> <!-- First Col ends -->

      <div class="col-sm-6 col-12 my-2">
        <div class="form-floating  ">
          <select
            id="receiver"
            class="form-control select-style"
            [ngClass] ="validationErrors[fieldsConfig[1].name]? 'formError': ''"
            [ngModel]="fieldValues[fieldsConfig[1].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[1].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[1].name)"
          >
            <option  *ngFor="let option of accounts_receiver" [value] = option[0] >{{option[1]}}</option>
          </select>
          <label class="form-label" >{{fieldsConfig[1].displayName}}</label>
        </div>
        <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[1].name]"> {{this.validationErrors[fieldsConfig[1].name]}}
      </div> <!-- Second Col ends -->

    </div> <!-- Second Row ends -->

    <!-- Second row = Add new recipient appears if Local transfer   -->
    <div class="row mt-2"  >
      <div class="d-flex justify-content-end text-decoration-underline new-recipient fw-bold "
      ><p id="newRecipient" routerLink="/main/accounts/recipients" class="button-pointer" >+ Add New Recipient</p>
      </div>
    </div> <!-- Second Row ends -->

    <!-- Third row = Amount/Memo -->
    <div class="row mt-4">

      <div class="col-sm-6 col-12 my-2">
        <div class="form-floating">
          <input
            id="amount"
            class="form-control"
            [ngClass] ="validationErrors[fieldsConfig[2].name]? ' formError': ''"
            [type]="fieldsConfig[2].type"
            [ngModel]="fieldValues[fieldsConfig[2].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[2].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[2].name)"
          >
          <label class="form-label">{{fieldsConfig[2].displayName}}</label>
        </div>
        <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[2].name]">
          {{this.validationErrors[fieldsConfig[2].name]}}
        </p>
      </div> <!-- First Col ends -->

      <div class="col-sm-6 col-12 my-2">
        <div class="form-floating">
          <input
            id="memo"
            [class] ="validationErrors[fieldsConfig[3].name]? 'form-control formError input-style': 'form-control input-style'"
            [type]="fieldsConfig[3].type"
            [ngModel]="fieldValues[fieldsConfig[3].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[3].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[3].name)"
          >
          <label class="form-label">{{fieldsConfig[3].displayName}}</label>
        </div>
        <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[3].name]">
          {{this.validationErrors[fieldsConfig[3].name]}}
        </p>
      </div> <!-- First Col ends -->

    </div> <!-- Third Row ends -->

    <!-- Fourth row = Repeat Payment choice col-xl-3 -->
    <div class="row my-3">
      <div class="col-12">
        <div class="form-check form-switch text-center d-flex p-0 align-items-center">
          <label class="form-check-label label-rep-pymnt me-2 " for="flexSwitchCheckDefault">
            <img class="p-0" src="assets/images/pay_tf/rec_payment.svg" alt="Icon">
            Repeat Payment
          </label>
          <input class="input-toggle form-check-input m-0 p-0"
                 type="checkbox"
                 role="switch"
                 [checked]="repeat_payment"
                 id="flexSwitchCheckDefault"
                 (click)="repeatPaymentChoice()" />

        </div>
      </div>
    </div> <!-- Fourth Row ends -->

    <!-- Fifth row = Frequency/send on/duration -->
    <div class="row mt-3" *ngIf="this.repeat_payment" [@appearSlow]>

      <div class="col-sm-4 col-12 my-2 ">
        <div class="mt-1">
          <div class="form-floating">
            <select
              class="form-select"
              [ngClass]="validationRecErrors[fieldsConfigRec[0].name]? 'formError ': ' '"
              [ngModel]="fieldValuesRec[fieldsConfigRec[0].name]"
              (ngModelChange)="updateFieldValueRec(fieldsConfigRec[0].name, $event)"
              (blur)="setRecFieldToEdited(fieldsConfigRec[0].name)"
            >
              <option value="" disabled selected></option>
              <option  value = 3 >Monthly</option>
            </select>
            <label class="form-label"> Frequency</label>
          </div>

          <p  class="error my-1" *ngIf="this.validationRecErrors[fieldsConfigRec[0].name]">
            {{this.validationRecErrors[fieldsConfigRec[0].name]}}
          </p>

        </div>
      </div> <!-- First Col ends -->

      <div class="col-sm-4 col-12 my-2 ">
        <div class="mt-1">
          <div class="form-floating">
            <select class="form-select"
                    [ngClass] ="validationRecErrors[fieldsConfigRec[1].name]? 'formError ': ''"
                    [ngModel]="fieldValuesRec[fieldsConfigRec[1].name]"
                    (ngModelChange)="updateFieldValueRec(fieldsConfigRec[1].name, $event)"
                    (blur)="setRecFieldToEdited(fieldsConfigRec[1].name)"
            >
              <option value="" disabled selected></option>
              <option  value = 1 >1</option>
              <option  value = 2 >2</option>
              <option  value = 3 >3</option>
              <option  value = 4 >4</option>
              <option  value = 5 >5</option>
              <option  value = 6 >6</option>
              <option  value = 7 >7</option>
              <option  value = 8 >8</option>
              <option  value = 9 >9</option>

              <option  value = 10 >10</option>
              <option  value = 11 >11</option>
              <option  value = 12 >12</option>
              <option  value = 13 >13</option>
              <option  value = 14 >14</option>
              <option  value = 15 >15</option>
              <option  value = 16 >16</option>
              <option  value = 17 >17</option>
              <option  value = 18 >18</option>
              <option  value = 19 >19</option>

              <option  value = 20 >20</option>
              <option  value = 21 >21</option>
              <option  value = 22 >22</option>
              <option  value = 23 >23</option>
              <option  value = 24 >24</option>
              <option  value = 25 >25</option>
              <option  value = 26 >26</option>
              <option  value = 27 >27</option>
              <option  value = 28 >28</option>
              <option  value = 29 >29</option>
              <option  value = 30 >30</option>

            </select>
            <label class="form-label"> Send on</label>
          </div>
          <div>
            <p  class="error my-1" *ngIf="this.validationRecErrors[fieldsConfigRec[1].name]">
              {{this.validationRecErrors[fieldsConfigRec[1].name]}}
            </p>
          </div>

        </div>
      </div> <!-- Second Col ends -->

      <div class="col-sm-4 col-12 my-2 ">
        <div class="input-group ">
          <input
            id ="inputDOB"
            placeholder="End Date"
            class="input-date form-control pt-3 pb-4"
            [ngClass]="validationRecErrors[fieldsConfigRec[2].name]? ' formError': ' '"
            name="dp"
            [ngModel]="dateRecTransfer"
            (ngModelChange)="updateFieldDateValue(fieldsConfigRec[2].name,$event)"
            (blur)="setRecFieldToEdited(fieldsConfigRec[2].name)"
            ngbDatepicker
            #dob="ngbDatepicker"
            [minDate] = minRecTransferEndDate
          />
          <button class="btn btn-outline-secondary bi bi-calendar3" (click)="dob.toggle()" type="button"></button>
        </div>
        <p *ngIf="this.validationRecErrors[fieldsConfigRec[2].name]"  class="error my-1"  >
          {{this.validationRecErrors[fieldsConfigRec[2].name]}}
        </p>
      </div> <!-- Third Col ends -->

    </div> <!-- Fifth Row ends -->


    <!-- Sixth row = Continue Button -->
    <div class="row py-3">
      <div class="col-lg-8 offset-lg-2 col-12">
        <div id="continueBtn" class="text-center violet-button rounded-3 py-3 w-100 button-pointer"
             (click)="verifyInformation()"> Continue
        </div>
      </div>
    </div>
  </div>


  <div *ngIf="isTransferInfo"  >
    <br>

    <div *ngIf="!isTransferSuccess" >

      <p class="p-step">Verify the information:</p>

      <!-- Transaction summary details are displayed to verify data or after the transfer was successfully performed  -->

      <div class="row text-center my-3">

        <div class="col-sm-5 col-12 text-center">
          <p class="p-verify" > ${{this.fieldValues['amount']|number :'.2'}} </p>
          <p class="p-text">Sender Information</p>
          <p class="p-account fw-bold">{{"Zelle-" + sender_account.toString().slice(-4)}}</p>
        </div>

        <div class="col-sm-2 col-12 text-center">
          <img src="assets/images/pay_tf/arrow.svg" class="img-fluid">
        </div>

        <div class="col-sm-5 col-12 text-center">
          <p class="p-verify">
            <img  [src]="'assets/images/paym-request/zelle_logo.svg'" class="img-fluid "> Transfer</p>
          <p class="p-text">Receiver Information</p>
          <p class="p-account fw-bold">{{this.receiver_account}}</p>
        </div>

      </div>

      <div *ngIf="repeat_payment">

        <p class="p-step">Scheduled as recurrent payment:</p>

        <div class="row text-center my-2 ">
          <div class="col-sm-4 col-12 my-2 text-center">
            <p class="p-text-rp mb-1 ">Frequency</p>
            <div class="p-rec-pay rounded-3 py-3">{{this.frequency_words}}</div>
          </div>
          <div class="col-sm-4 col-12 my-2 text-center">
            <p class="p-text-rp mb-1">Send On</p>
            <div class="p-rec-pay rounded-3 py-3 ">{{this.fieldValuesRec['send_day']}}</div>
          </div>
          <div class="col-sm-4 col-12 my-2 text-center">
            <p class="p-text-rp mb-1">Duration</p>
            <div class="p-rec-pay rounded-3 py-3 " >{{this.displayEndDay}}</div>
          </div>
        </div>

        <div class="row text-center">
          <p class="p-disclaimer">Only send money to people you know. Make sure that the phone number
            or email you are sending the money is correct. You will not be able to cancel a sent payment.</p>
        </div>

      </div>

      <div class="row py-5">
        <div class="col-lg-6  col-12 my-2">
          <div  class="text-center yellow-button rounded-3 py-3 w-100 button-pointer"
          (click)="editTransferInformation()" > Edit
          </div>
        </div>
        <div class="col-lg-6  col-12 my-2">
          <div  class="text-center violet-button rounded-3 py-3 w-100 button-pointer"
                (click)="submitZellePayment()"> Transfer
          </div>
        </div>
      </div>

    </div>

    <div *ngIf="isTransferSuccess">
      <br>

      <div class="row text-center">
        <div>
          <img src="assets/images/zelle/success_check.svg">
        </div>
        <div>
          <p class="p-success">Success!</p>
        </div>
      </div>

      <div class="row text-center">
        <div>
          <p class="p-success-black">Your money has been transfered.</p>
        </div>
      </div>

      <div class="row text-center my-3">

        <div class="col-5 text-center">
          <p class="p-verify" > ${{this.fieldValues['amount']|number :'.2'}} </p>
          <p class="p-text">Sender Information</p>
          <p class="p-account fw-bold">{{"Zelle-" + sender_account.toString().slice(-4)}}</p>
        </div>

        <div class="col-2 text-center">
          <img src="assets/images/pay_tf/arrow.svg" class="img-fluid">
        </div>

        <div class="col-5 text-center">
          <p class="p-verify">
            <img  [src]="'assets/images/paym-request/zelle_logo.svg'" class="img-fluid "> Transfer</p>
          <p class="p-text">Receiver Information</p>
          <p class="p-account fw-bold">{{this.receiver_account}}</p>
        </div>

      </div>

      <div class="row text-center">
        <p class="p-success">The recurrent payment has been scheduled</p>
      </div>

      <div class="row py-5">

        <div class="col-lg-8 offset-lg-2  col-12">
          <div  class="text-center violet-button rounded-3 py-3 w-100 button-pointer"
                (click)="makeNewTransfer()"> New Transfer
          </div>
        </div>
      </div>

    </div>

  </div>

</div>
