import {Component, Input, OnInit} from '@angular/core';
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import {TransferLocalService} from "../../../services/transfer-local.service";
import {Router} from "@angular/router";
import {NgbDate, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {appearSlow} from "../../Shared/animations";

@Component({
  selector: 'app-transfer-internal',
  templateUrl: './transfer-internal.component.html',
  styleUrls: ['./transfer-internal.component.css'],
  animations: [appearSlow]
})
export class TransferInternalComponent implements OnInit{
  @Input() accounts_sender !:string[][]
  accounts_receiver !:string[]
  repeat_payment: boolean = false
  transferInfo : boolean = false
  validateOn: string = 'change'

  message !:string
  message_rec_transfer !:string

  frequency_dict :any = {}

  receiver_id !: string
  receiver_account !:string
  sender_account !: string

  is_transfer_success !: string
  is_recPayment_success !:string
  frequency_words :string = ''
  displayEndDay !:string



  today = new Date()
  dateRecTransfer!: NgbDateStruct
  minRecTransferEndDate = new NgbDate(this.today.getFullYear(), this.today.getMonth()+1, (this.today.getDate() +1));


  fieldsConfig !: FormFieldConfig[]
  fieldsConfigRec !: FormFieldConfig[]


  fieldRecWasEdited: any = {}

  validationRecErrors : any = {}

  validationErrors : any = {}

  fieldWasEdited: any = {}
  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues: any) {
    this._fieldValues = newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  updateFieldValue(fieldName: string, newValue: string) {
    this.fieldValues = {...this.fieldValues, [fieldName]: newValue}
  }
  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }


  _fieldValuesRec: any = {}

  get fieldValuesRec() {
    return this._fieldValuesRec
  }
  set fieldValuesRec(newValues:any) {
    this._fieldValuesRec = newValues
  }


  constructor(
    private transferLocalService : TransferLocalService,
    private router : Router
  ) { }
  cancelTransfer():void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    })
  }

  createInitialPayloads():void {
    for (let field of this.fieldsConfig)
      this.fieldValues[field.name] = ''

    this.fieldValues['receiver_type'] = 0

    for (let field of this.fieldsConfigRec)
      this.fieldValuesRec[field.name] = ''
  }

  repeatPaymentChoice() {
    this.repeat_payment = !this.repeat_payment
  }

  updateFieldValueRec(fieldName: string, newValue: string) {
    this.fieldValuesRec = fieldName === 'send_on'|| 'frequency'? {...this.fieldValuesRec, [fieldName]: Number(newValue)} :
      {...this.fieldValuesRec, [fieldName]: newValue}
  }

  setRecFieldToEdited(fieldName:string) {
    this.fieldRecWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationRecErrors = this.getRecValidationErrors(false)
    }
  }

  getRecValidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfigRec) { //loop through fields
      if(this.fieldRecWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValuesRec[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  updateFieldDateValue(type: string, event: any){
    this.fieldValuesRec = { ... this.fieldValuesRec, 'end_date': event}
  }

  verifyInformation():void {

    this.message = ''
    this.is_transfer_success = ''

    this.validationErrors = this.getvalidationErrors(true)

    if(this.repeat_payment == true){
      this.validationRecErrors = this.getRecValidationErrors(true)
      this.frequency_dict = { 3: 'Monthly',}
      this.frequency_words = this.frequency_dict[3]
    }

    if(Object.keys(this.validationErrors).length > 0 || Object.keys(this.validationRecErrors).length > 0) {
      return
    }

    // Verify Information: Get display information
    this.sender_account = this.accounts_sender.filter((acc:any) => acc[0] === this.fieldValues['loan_id'])[0][1]

    let receiver_selected = this.accounts_receiver.filter((acc: any) => acc[0] === this.fieldValues['receiver_id'])[0]
    this.receiver_id = receiver_selected[0]
    this.receiver_account = receiver_selected[1]

    this.transferInfo = !this.transferInfo

    this.message = ''

    if (this.fieldValuesRec['end_date'] !== "") {
      const tempTransfer = this.fieldValuesRec['end_date']
      this.displayEndDay = `${tempTransfer.year}-${tempTransfer.month}-${tempTransfer.day}`
    }

  }

  editTransferInformation():void{
    this.transferInfo = ! this.transferInfo
    this.dateRecTransfer = this.fieldValuesRec['end_date']
  }

  submitForm(): void {
    this.validationErrors = this.getvalidationErrors(true)

    if(this.repeat_payment == true){
      this.validationRecErrors = this.getRecValidationErrors(true)
    }


    if(Object.keys(this.validationErrors).length > 0 || Object.keys(this.validationRecErrors).length > 0) {
      return
    }


    if(this.repeat_payment === true) {
      this.fieldValuesRec = { ... this.fieldValuesRec,...this.fieldValues }
      this.fieldValuesRec = { ... this.fieldValuesRec, 'network': 1} // 1: Kredio
      this.fieldValuesRec = { ... this.fieldValuesRec, 'recipient_id': this.receiver_id}
      //delete this.fieldValuesRec.receiver

      const tempTransfer = this.fieldValuesRec['end_date']
      this.displayEndDay = `${tempTransfer.year}-${tempTransfer.month}-${tempTransfer.day}`
      this.fieldValuesRec['end_date'] = this.displayEndDay
    }

    this.router.navigateByUrl('/main/paymentsTransfers') // CHANGE

    //////// Submit Internal Transfer /////
    // this.transferLocalService.makeInternalTransfer(this.fieldValues).subscribe(
    //   res => {
    //     if (res.e_code ===1 ){
    //       this.is_transfer_success = 'true'
    //       this.message = res.e_msg
    //
    //       // Post recurrent payment if needed
    //       if(this.repeat_payment == true){
    //         this.transferLocalService.addRecurrentPayment(this.fieldValuesRec).subscribe({
    //           next:(res)=>{
    //             if(res.e_code == 1){
    //               this.is_recPayment_success = 'true'
    //               this.message_rec_transfer = 'The recurrent payment was successfully added'
    //             }
    //           }})}
    //     }
    //   });
  }

  ngOnInit() {
    this.fieldsConfig  = this.transferLocalService.fieldsConfig
    this.fieldsConfigRec = this.transferLocalService.fieldsConfigRec
    this.createInitialPayloads()

    // this.recipientsService.getRecipients().subscribe({ next:res =>{
    //     if(res.e_code == 1) {
    //       let recip_local = res.recipients.filter((rec:RecipientExtended)=> rec.kredio == true)
    //       this.accounts_receiver = recip_local.map(
    //         (rec:RecipientExtended) => [rec.id , rec.first_name +"-"+ rec.last_name])}
    //   }})
  }
}
