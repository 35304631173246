import {Component, OnInit} from '@angular/core';
import {fromEvent, Subject, takeUntil} from "rxjs";
import {AuthService} from "./services/auth.service";
import {ApiService} from "./services/api.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'bank-front';

  private unsubscriber : Subject<void> = new Subject<void>();

  constructor(
    private api: ApiService,
    private authService: AuthService,
  ) {
  }

  // Prevent the user from browsing backwards
  // Logout if that is the case
  ngOnInit(): void {
    history.pushState(null, '');

    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
      if(sessionStorage.getItem('isLoggedIn')) {
        this.authService.logout()
      } else {
        this.authService.cancelProfile()
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }


}
