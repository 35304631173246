import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-filter-tr',
  templateUrl: './modal-filter-tr.component.html',
  styleUrls: ['./modal-filter-tr.component.css']
})
export class ModalFilterTrComponent {

}
