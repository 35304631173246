import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HomeService} from "../../../services/home.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {HomeCashBackAlertComponent} from "../home-cash-back-alert/home-cash-back-alert.component";
import {Router} from "@angular/router";
import {BenefitsAccount} from "../../../services/payroll.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-home-cash-back',
  templateUrl: './home-cash-back.component.html',
  styleUrls: ['./home-cash-back.component.css']
})
export class HomeCashBackComponent implements OnInit{


  brand :string = environment.brand
  isEnrolled !:boolean
  benefitsAccount !:BenefitsAccount
  cashBack_paid_high !:boolean
  cashBack_pending_high !:boolean

  @ViewChild('overlay') overlay !: ElementRef;
  constructor(
    private homeService: HomeService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  activateRewards():void{
    this.homeService.activateRewards().subscribe({
    next:(res)=>{
      const dialogConfig = new MatDialogConfig();
      if(res.e_code ===3){
        dialogConfig.data = { message: "In order to access " + this.brand + " benefits, " +
            " enroll to Live Payroll and transfer" +
            " 100% of your wage to the bank." }
      }
      if(res.e_code===1){
        dialogConfig.data = { message: "Your benefits are now active" }
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    })
      }
      dialogConfig.panelClass = 'full-25-dialog';
      this.dialog.open(HomeCashBackAlertComponent,dialogConfig)
    }})
  }


  ngOnInit() {
    this.homeService.getRewardsAccount().subscribe({ next:(res)=>{
      if (res.e_code==1){
        this.isEnrolled = true
        this.benefitsAccount = res.account
        this.cashBack_paid_high = Number(this.benefitsAccount.paid_cashback) >= 999.99
        this.cashBack_pending_high = Number(this.benefitsAccount.pending_cashback)  >= 999.99
        }
      else {
        this.isEnrolled = false
      }
    }})
  }
}
