import { Injectable } from '@angular/core';

import {ApiService} from "./api.service";
import {FormFieldConfig} from "../components/Shared/forms-shared/typesForm";
import {
  checkLengthChrValidator, checkMaxLengthValidator,
  checkMinValueValidator,
  isNotEmptyValidator
} from "../components/Shared/forms-shared/validators";


export interface ZelleRecipient {
  name: string,
  transferAddress: string,
  isFrequent: boolean
}
export interface ZelleProfile {
  userId: string,
  isEnrolled: boolean,
  contactInfo: string[],
  depositAccount: string,
  termsConditions: boolean

  recipients ?: ZelleRecipient[],

}

export interface ZelleSetup {
  email: boolean,
  phone: boolean,
  other: boolean,
  other_data?: string,
  account_id: string
}


@Injectable({
  providedIn: 'root'
})
export class ZelleService {
  formFieldsZelleSetup: FormFieldConfig[] = [
    {
      name: 'email',
      displayName: 'email',
      type: 'boolean',
      placeHolder: 'email',
      validators:[isNotEmptyValidator]
    },
    {
      name: 'phone',
      displayName: 'phone',
      type: 'boolean',
      placeHolder: 'phone',
      validators:[isNotEmptyValidator]
    },
    {
      name: 'account_id',
      displayName: 'Select Account',
      type: 'account_id',
      placeHolder: 'Select Account',
      validators:[isNotEmptyValidator]
    },
    {
      name: 'zelle_terms',
      displayName: 'zelle_terms',
      type: 'boolean',
      placeHolder: 'zelle_terms',
      validators:[isNotEmptyValidator]
    },
    {
      name: 'other',
      displayName: 'other',
      type: 'boolean',
      placeHolder: 'other',
      validators:[isNotEmptyValidator]
    },
    {
      name: 'other_data',
      displayName: 'Enter you phone or email',
      type: 'boolean',
      placeHolder: 'other_data'
    },
  ]

  fieldsConfig: FormFieldConfig[] = [
    {
      name: 'loan_id',
      displayName: 'Sender',
      type: 'dropdown',
      options: [],
      validators : [isNotEmptyValidator],
    },
    {
      name: 'receiver_id',
      displayName:'Receiver',
      type: 'dropdown',
      options: [],
      validators : [isNotEmptyValidator],
    },
    {
      name: 'amount',
      displayName:'Amount',
      type: 'string',
      validators : [isNotEmptyValidator, checkMinValueValidator(10)],
    },
    {
      name: 'memo',
      displayName:'Memo',
      type: 'string',
      validators : [checkMaxLengthValidator(100), isNotEmptyValidator],
    },
    {
      name: 'receiver_type',
      type: 'number',
      validators : [isNotEmptyValidator],
    }
  ]
  fieldsConfigRec: FormFieldConfig[] = [

    {
      name: 'frequency',
      displayName:'Frequency',
      type: 'dropdown',
      validators:[isNotEmptyValidator]
    },
    {
      name: 'send_day',
      displayName:'Send on',
      type: 'dropdown',
      validators:[isNotEmptyValidator]
    },

    {
      name: 'end_date',
      displayName:'Duration',
      type: 'string',
      validators:[isNotEmptyValidator]
    }

  ]
  constructor(private api: ApiService) { }

}

