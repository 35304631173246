import {Component, OnInit} from '@angular/core';
import {ZelleProfile, ZelleService} from "../../../../services/zelle.service";

@Component({
  selector: 'app-zelle',
  templateUrl: './zelle.component.html',
  styleUrls: ['./zelle.component.css']
})
export class ZelleComponent implements OnInit{

  your_route = 'zelle'
  current_option = 'recipients'
  optionSelected(data:String):void{


  }

  chooseOption(data:string):void{
    this.current_option = data

    // if (this.current_option != 'active'){
    //   this.discountService.getDiscounts().subscribe({
    //     next:(res) => {
    //       this.CsAndDs = res.ret
    //     }})
    // } else {
    //   this.discountService.getActiveDiscounts().subscribe({
    //     next:(res) => { this.CsAndDs = res.ret }})
    // }
  }
  ngOnInit() {
    // this.userProfile = this.myZelleService.getZelleProfile()
  }

}
