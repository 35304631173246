import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import {map, Observable} from 'rxjs';
import {ErrorDialogService} from "../services/error-dialog.service";

@Injectable()
export class RequestsInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Request Intercepts
    req = req.clone({
      withCredentials: true
    });

    // Response Intercepts
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
// <<<<<<< HEAD
// <<<<<<< HEAD
        if (event instanceof HttpResponse && (event.body.e_msg === 'User not authenticated' ||
        !sessionStorage.getItem('isLoggedIn'))) {
// =======
//         if (event instanceof HttpResponse && event.body.e_msg === 'User not authenticated') {
//           const errMsg = "Session lost connection to the server, please sign-in again.";
// >>>>>>> master
// =======
//         if (event instanceof HttpResponse && event.body.e_msg === 'User not authenticated') {
//           const errMsg = "Session lost connection to the server, please sign-in again.";
// >>>>>>> 79bacc9d297170b645894840ba524f5a248539ad
          sessionStorage.clear()
          this.router.navigateByUrl('login')
        }
        return event;
      }));
  }
}
