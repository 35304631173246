import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormFieldConfig} from "../../../../Shared/forms-shared/typesForm";
import {
  checkLengthChrValidator,
  emailOrEmptyValidator,
  isNotEmptyValidator, usaPhoneOrEmptyValidator, usaPhoneorEmptyValidator
} from "../../../../Shared/forms-shared/validators";
import {RecipientsService} from "../../../../../services/recipients.service";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SharedataService} from "../../../../../services/sharedata.service";
import {DeviceDetectorService} from "ngx-device-detector";

@Component({
  selector: 'app-zelle-recip-add',
  templateUrl: './zelle-recip-add.component.html',
  styleUrls: ['./zelle-recip-add.component.css']
})
export class ZelleRecipAddComponent implements OnInit{


  @Input() validateOn: string = 'change'
  fieldsConfig : FormFieldConfig[] = [
    {
      name: 'first_name',
      displayName: 'First Name',
      type: 'string',
      placeHolder: 'First Name',
      validators:[isNotEmptyValidator, checkLengthChrValidator(2)]
    },
    {
      name: 'last_name',
      displayName: 'Last Name',
      type: 'string',
      placeHolder: 'Last Name',
      validators:[isNotEmptyValidator, checkLengthChrValidator(2)]
    },
    {
      name: 'email',
      displayName: 'Email',
      type: 'string',
      placeHolder: 'id',
      validators:[emailOrEmptyValidator]
    },
    {
      name: 'phone',
      displayName: 'Phone',
      type: 'string',
      placeHolder: 'Phone',
      validators:[ usaPhoneorEmptyValidator]
    },
  ]
  isLargeScreen !:boolean
  selectedFile !: any
  add_new_rec : boolean = true

  constructor(
    private deviceService: DeviceDetectorService,
    private shareDataService: SharedataService,
    private recipientsService: RecipientsService,
    private router: Router,
    public dialogRef: MatDialogRef<ZelleRecipAddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  fieldWasEdited: any = {}
  validationErrors : any = {}
  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }

  set fieldValues(newFieldValues:any) {
    this._fieldValues= newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  updateFieldValue(fieldName:string, newValue:string) {
    this.fieldValues = { ... this.fieldValues, [fieldName]: newValue}
  }

  handleFileInput(event:any) {
    this.selectedFile = event.target.files[0]
    this.fieldValues = {... this.fieldValues, ['file']: event.target.files[0]}
  }
  submitNewRecipient(): void {
    this.fieldsConfig[2].validators = [ emailOrEmptyValidator]
    this.fieldsConfig[3].validators = [ usaPhoneOrEmptyValidator ]

    // Check user inputs either email or phone number

    if( (!this.fieldValues.email) && (!this.fieldValues.phone) ) {
      this.fieldsConfig[2].validators = [ isNotEmptyValidator, emailOrEmptyValidator]
      this.fieldsConfig[3].validators = [ usaPhoneOrEmptyValidator ]
    }

    // Pass form data to parent component
    this.validationErrors = this.getvalidationErrors(true)

    if (Object.keys(this.validationErrors).length > 0)
      return

    const formData = new FormData()

    formData.append('first_name', this.fieldValues['first_name'].toString())
    formData.append('last_name', this.fieldValues['last_name'].toString())
    formData.append('email', this.fieldValues['email'])
    formData.append('phone', this.fieldValues['phone'])
    formData.append('recipient_img', this.selectedFile)

    this.add_new_rec  = false

    // this.recipientsService.addRecipient(formData).subscribe({ next: (res) => {
    //     if(res.e_code ==1){
    //       this.add_new_rec  = false
    //     }}
    // })
  }

  cancelNewRec():void{
    this.dialogRef.close()
  }

  getScreenSize():void{
    this.isLargeScreen = !this.deviceService.isMobile();
  }

  backToPayments():void{
    this.router.navigateByUrl('/main/paymentsTransfers')
  }
  ngOnInit() {
    this.getScreenSize()
    for (let field of this.fieldsConfig) {
      this.fieldValues[field.name] = ''
    }
  }


}
