import {Component, Inject} from '@angular/core';
import {AuthService} from "../../../../services/auth.service";
import {Router} from "@angular/router";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-alerts-socket',
  templateUrl: './alerts-socket.component.html',
  styleUrls: ['./alerts-socket.component.css']
})
export class AlertsSocketComponent {
  message !:string
  brand :string = environment.brand
  constructor(
    public dialogRef: MatDialogRef<AlertsSocketComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  closeMessage():void{
    this.dialogRef.close()
  }
  ngOnInit( ) {
    this.message = this.data.message
  }
}
