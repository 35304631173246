import { Component } from '@angular/core';
import {appearSlow} from "../../Shared/animations";

@Component({
  selector: 'app-msgs-docs',
  templateUrl: './msgs-docs.component.html',
  styleUrls: ['./msgs-docs.component.css'],
  animations: [ appearSlow ],
})
export class MsgsDocsComponent {

  your_route = "Messages & Documents"

  current_option :string = "alerts"
  chooseOption(data:string):void {
    if(data == 'alerts'){
      this.current_option = 'alerts'
    }
    if(data == 'statements'){
      this.current_option = 'statements'
    }
    if(data == 'documents'){
      this.current_option = 'documents'
    }
    if(data == 'reports'){
      this.current_option = 'reports'
    }
  }

}
