import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {PayrollService} from "../../../services/payroll.service";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-home-carousel',
  templateUrl: './home-carousel.component.html',
  styleUrls: ['./home-carousel.component.css']
})
export class HomeCarouselComponent implements OnInit{

  brand :string = environment.brand
  userIsEnrolled !:boolean
  add_external_account :boolean = false
  displayDiscountsCoupons:boolean = false

  payrollPeriod !: string
  payrollAmount : string = "0.00"

  amount_high !: boolean
  @Output() extAccount = new EventEmitter<boolean>()
  @Output() showDAndC = new EventEmitter<boolean>()

  constructor(
    private payrollService: PayrollService,
    private router: Router,

  ) { }

  displayCouponsDiscounts(): void {
    this.displayDiscountsCoupons = ! this.displayDiscountsCoupons
    this.showDAndC.emit(this.displayDiscountsCoupons)
  }
  addExternalAccount():void{
    this.add_external_account = !this.add_external_account
    this.extAccount.emit(this.add_external_account)
  }


  displayPayroll(): void {
    this.router.navigateByUrl('/main/home/payroll')
  }

  getPayrollData(data:string){
    let payload = {
      argyle_account_id :data, from_date :"",
      end_date :"", limit : ""
    }
    this.payrollService.getArgylePaystubs(payload).subscribe({
      next:(res) =>{
        if(res.e_code === 1) {
          this.payrollAmount = res.res.paystub[0].gross_pay
           this.amount_high =  Number(this.payrollAmount) >= 1000
          let d = new Date(res.res.paystub[0].date)
          this.payrollPeriod = d.toLocaleString('default', { month: 'long' }) + ' ' + d.getDate().toString() +','+d.getFullYear().toString()
        }
      }
    })
  }
  ngOnInit() {
    this.payrollService.getPayrollUser().subscribe({ next:(res)=>{
        if (res.e_code === 1 ){
          this.userIsEnrolled = true
          let accountIdArgyle = res.res.payroll_accounts[0].account_id
          this.getPayrollData(accountIdArgyle)
        } else {
          this.userIsEnrolled = false
        }
      }})
  }
}
