<div class="container-fluid">

  <div class="row my-4 mx-3" >

      <div class="col-9 col-lg-8 col-xl-9  d-flex align-items-center" >
        <p id="route_title" class="page-title m-0 " *ngIf="current_route!='zelle'">{{current_route}}</p> <!-- Replace with dynamic name -->
        <div   *ngIf="current_route =='zelle'">
          <img src="assets/images/zelle/zelle_logo.svg">
        </div> <!-- Replace with dynamic name -->
      </div>

      <div class="col-3 col-lg-4 col-xl-3" >
        <div class="row h-100">

          <div class=" col-lg-8 col-0 px-3 py-1 d-none d-lg-block  ">
            <button id="btn_pt" (click)="makeTransfer()"
                 class="button-all  rounded-4 pt-1 pb-2 w-100  button-pointer ">
              <p class="my-2"  >Pay & Transfer</p>
            </button>
          </div>

          <div class="col-lg-2 col-6 p-0 text-center" >
            <img id="alerts_no" *ngIf="alerts_count===0"  src="assets/images/nav-buttons/Alert.svg"
                 routerLink="/main/alerts"
                 class="p-0 m-0 h-100 img-fluid button-pointer"
                 alt="Alerts">
            <img id="alerts_yes" *ngIf="alerts_count !==0"  src="assets/images/nav-buttons/Alerts_new.svg"
                 routerLink="/main/alerts"
                 class="p-0 m-0 h-100 img-fluid button-pointer"
                 alt="Alerts">

          </div>

          <div  id="btn_um" class="col-lg-2  col-6 p-0 text-center " (click)="userMenuDisplay()" >
            <img src="assets/images/nav-buttons/Profile.svg"
                 alt="Profile" class="p-0 m-0 h-100 img-fluid button-pointer" >
          </div>

        </div>
      </div>

  </div> <!-- Close row -->

  <div *ngIf="isUserMenu" >
    <app-modal-vertical >
      <app-user-menu
        (redirect)="navigate($event)"
      ></app-user-menu>
    </app-modal-vertical>
  </div>

  <div *ngIf="isDisplayProfile">
    <app-modal-vertical>
      <app-profile-display
        (submit)= "navigate($event)"
      ></app-profile-display>
    </app-modal-vertical>
  </div>

  <div *ngIf="isEditProfile">
    <app-modal-vertical>
      <app-profile-edit
        (submit)= "navigate($event)"
      ></app-profile-edit>
    </app-modal-vertical>
  </div>

  <div *ngIf="isEmailAddress">
    <app-modal-vertical>
      <app-profile-email-edit
      ></app-profile-email-edit>
    </app-modal-vertical>
  </div>

  <div *ngIf="isPhoneNumber">
    <app-modal-vertical>
      <app-profile-phone-edit
      ></app-profile-phone-edit>
    </app-modal-vertical>
  </div>

  <div *ngIf="isPostalAddress" >
    <app-modal-vertical>
      <app-profile-address-edit>
      </app-profile-address-edit>
    </app-modal-vertical>
  </div>

</div>
