import {Component, OnInit} from '@angular/core';
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import {isNotEmptyValidator} from "../../Shared/forms-shared/validators";
import {SharedataService} from "../../../services/sharedata.service";
import {ProfileService} from "../../../services/profile.service";
import {Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {AuthService} from "../../../services/auth.service";
import {appearSlow} from "../../Shared/animations";

@Component({
  selector: 'app-profile-ph-code-edit',
  templateUrl: './profile-ph-code-edit.component.html',
  styleUrls: ['./profile-ph-code-edit.component.css'],
  animations: [ appearSlow],
})
export class ProfilePhCodeEditComponent implements OnInit{

  brand :string = environment.brand

  // Display response from the BE
  isSecurityCode : boolean = false
  successPhoneCode !:boolean
  msg_response : string = ""

  constructor(
    private router: Router,
    private profileService : ProfileService,
    private sharedDataService : SharedataService,
    private authService: AuthService
  ) { }
  fieldsConfig :FormFieldConfig[] = [
    {
      name: 'phone_id',
      displayName:'PhoneId',
      type: 'string',
      validators : [isNotEmptyValidator],
    },
    {
      name: 'code',
      displayName:'Security Code',
      type: 'string',
      validators : [isNotEmptyValidator]
    },
  ]

  // Variables used by forms

  validateOn: string = 'change'
  validationErrors: any = {}
  fieldWasEdited: any = {}
  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues:any) {
    this._fieldValues= newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  updateFieldValue(fieldName:string, newValue:string) {
    this.fieldValues = { ... this.fieldValues, [fieldName]: parseInt(newValue) }
  }

  getvalidationErrors(isSubmit:boolean){

    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit === true){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }

    return errors
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  activatePhone():void {

    this.validationErrors = this.getvalidationErrors(true)
    if(Object.keys(this.validationErrors).length > 0) {
      return
    }
    this.profileService.activatePhoneAuth(this.fieldValues).subscribe({
      next:(res)=>{
        if(res.e_code == 1) {
          this.isSecurityCode = true
          this.successPhoneCode = true
        }
      }})
  }

  onCodeCompleted(code: string) {
    this.updateFieldValue('code', code)
  }

  // Go back to initial page
  cancelEditPhone():void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    })
  }

  sendCodeAgain(): void{
    if(this.fieldValues.phone_id){
      this.authService.validatePhoneNumber(
        {'phone':this.fieldValues.phone_id}).subscribe({ next:(res)=>{
        }})}
  }

  ngOnInit() {
    // Set initial values and phone id
     for (let field of this.fieldsConfig)
       this.fieldValues[field.name] = ""

     // Retrieve phone id
    this.sharedDataService.getSecurityCodeId().subscribe({next:value => {
        this.fieldValues = {...this.fieldValues, 'phone_id': value }
      }})
  }

}
