
<div class="container-fluid h-100" [@appearSlow]>

        <app-nav-buttons
          [current_route]= your_route
        ></app-nav-buttons>


        <!--SelectedCard row my-3 px-0 px-lg-3-->
        <div *ngIf="selectedCard">
          <div class="row rounded-4 mt-2 px-1  px-lg-4 ">
            <div class="card bg-color-clear" >
              <div class="card-body">
                <!--Card img and data-->
                <div class="row rounded-4 ">

                  <!--Card image-->
                  <div class="col-lg-4 col-12 ">
                    <div class="p-0 m-0 w-100">
                      <img src='assets/images/cards/card_template_black.svg' class="img-fluid w-100 rounded-4 shadow-lg" alt="Card Img">
                    </div>
                  </div> <!--Close col4-->

                  <!--Card info: Large Screen-->
                  <div class="col-lg-8 col-12  d-none d-lg-block ">
                    <!--Card data-->
                    <div class="row w-100 h-75 " >
                      <!--Large Screen align-content-center-->
                      <div class="col-6  ps-4 h-100 align-content-center " >
                        <div class="d-flex flex-column justify-content-between py-0 h-75">
                          <p id="cardNameLS" class="p-cp mb-0">{{selectedCard.name}}</p>
                          <p class ="p-sl" style="cursor: pointer">Spending Limits</p>
                          <div class="progress">
                            <div class="progress-bar" role="progressbar" style="width:{{percentage}}% ; background-color:#4437B5"
                                 aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <p id="cardSpendingLS" class="p-l">$ {{selectedCard.current_spending|number :'.2'}} spend of $ {{selectedCard.balance|number :'.2'}}</p>
                          <p class="p-al"  title="Limits" (click)="displayCardLimits(true)">
                            See/Edit all limits consumption
                          </p>
                        </div>

                      </div><!--Close col-->
                      <!--Large Screen-->
                      <div class="col-6  h-100  ">
                        <div class=" py-0  h-75 align-content-center">
                          <p id="cardBalanceLS" class="balance mb-0">$ {{available_spend|number :'.2'}}</p>
                          <p class="p-sl mb-0" >Available to Spend</p>
                          <p id="cardLimitLS"  class="p-l" *ngIf="selectedCard.total_amount" ><b>${{selectedCard.total_amount|number :'.2'}}</b> {{budgetLimit}} budget</p>
                          <p class="p-l" *ngIf="!selectedCard.total_amount" ><b>No spending limit</b></p>
                        </div>
                      </div> <!--Close col-->

                    </div><!--Close row-->
                    <!--Card buttons-->
                    <div class="row w-100  h-25 ">

                      <div  class="col-lg-3 col-6 my-lg-0 my-2 p-0 d-flex justify-content-around  h-75" >
                        <button id="btnSend" class="button-option w-75 d-flex justify-content-center align-items-center  rounded-4 "
                                routerLink="/main/paymentsTransfers" title="Send">
                          <img class="img-button" src="assets/images/cards/send.svg" alt="Send">
                          <p class ="p-option mt-3 ms-2" >Send</p>
                        </button>
                      </div>

                      <div  class="col-lg-3 col-6 my-lg-0 my-2 p-0 d-flex justify-content-around h-75"  >
                        <button id="btnLimits" class="button-option w-75 d-flex justify-content-center align-items-center rounded-4"
                                (click)="setSpendingLimits(true)" title="Limits">
                          <img class="img-button" src="assets/images/cards/limits.svg" alt="Limits">
                          <p class ="p-option mt-3 ms-2">Limits</p>
                        </button>
                      </div>

                      <div  class="col-lg-3 col-6 my-lg-0 my-2 p-0 d-flex justify-content-around h-75"  >
                        <button id="btnLock" *ngIf="selectedCard.is_locked==false"
                                class="button-option  w-75 d-flex justify-content-center align-items-center rounded-4 "
                                (click)="toggleLock(selectedCard.account_id, selectedCard.id)" title="Lock">
                          <img class="img-button" src="assets/images/cards/lock_card.svg" alt="Lock">
                          <p class ="p-option mt-3 ms-2">Lock</p>
                        </button>

                        <button id="btnUnlock" *ngIf="selectedCard.is_locked==true"
                                class="button-option-l w-75 d-flex justify-content-center align-items-center rounded-4 "
                                (click)="unlockCard(selectedCard.account_id, selectedCard.id)" title="Unlock">
                          <img class="img-button" src="assets/images/cards/lock_active.svg" alt="Unlock">
                          <p class ="p-option mt-3 ms-2">Unlock</p>
                        </button>

                      </div>

                      <div  class="col-lg-3 my-lg-0 my-2 col-6 p-0 d-flex justify-content-around h-75">
                        <button id="btnMore" *ngIf="(selectedCard.is_primary == false) || (selectedCard.physical_active == 1)"
                                class="button-option w-75 d-flex justify-content-center align-items-center rounded-4 "
                                (click)="seeCardOptions()" title="More">
                          <img class="img-button" src="assets/images/cards/more.svg" alt="More">
                          <p class ="p-option mt-3 ms-2">More</p>
                        </button>
                        <button  *ngIf="selectedCard.physical_active == 0  && selectedCard.is_primary == true"
                                 class="button-activate w-75 d-flex justify-content-center align-items-center rounded-4 "
                                 (click)="activateCard(this.selectedCard.account_id, this.selectedCard.id, this.selectedCard.number)"
                                 title="Activate">
                          <img class="img-button" src="assets/images/cards/activate.svg" alt="Activate">
                          <p class ="p-activate mt-3 ms-2">Activate</p>
                        </button>
                      </div>

                    </div>

                  </div> <!--Close col-8-->

                  <!--Card info: Small Screen-->
                  <div class="col-lg-8 col-12 d-block  d-md-block d-lg-none ">
                    <!--Card data-->
                    <div class="row w-100 " >

                      <div class="col-6 my-2 " >
                        <p id="cardNameSS" class="p-cp mb-0 fw-bold">{{selectedCard.name}}</p>
                        <p class ="p-sl" style="cursor: pointer">Spending Limits</p>
                        <div class="progress">
                          <div class="progress-bar" role="progressbar"  aria-valuemin="0" aria-valuemax="100"
                               style="background-color:#4437B5 ;width :{{percentage}}%">
                          </div>
                        </div>
                        <p id="cardSpendingSS" class="p-l">$ {{selectedCard.current_spending|number :'.2'}} spend of $ {{selectedCard.balance|number :'.2'}}</p>
                        <p class="p-al"  id="btnEditLimits" title="Limits" (click)="displayCardLimits(true)">
                          See/Edit all limits consumption
                        </p>
                      </div><!--Close col-->

                      <div class="col-6 mt-3 text-end align-content-center ">
                        <p id="cardBalanceSS" class="balance mb-0">$ {{available_spend|number :'.2'}}</p>
                        <p class="p-sl mb-0">Available to Spend</p>
                        <p id="cardLimitSS"  class="p-l" *ngIf="selectedCard.total_amount" ><b>${{selectedCard.total_amount|number :'.2'}}</b> {{budgetLimit}} budget</p>
                        <p class="p-l" *ngIf="!selectedCard.total_amount" ><b>No spending limit</b></p>
                      </div> <!--Close col-->

                    </div><!--Close row-->
                    <!--Card buttons-->
                    <div class="row w-100 ">

                      <div  class="col-lg-3 col-6 my-lg-0 my-2 p-0 d-flex justify-content-around  h-75" >
                        <button id="btnSendS" class="button-option w-75 d-flex justify-content-center align-items-center  rounded-4 "
                                routerLink="/main/paymentsTransfers" title="Send">
                          <img class="img-button" src="assets/images/cards/send.svg" alt="Send">
                          <p class ="p-option mt-3 ms-2" >Send</p>
                        </button>
                      </div>

                      <div  class="col-lg-3 col-6 my-lg-0 my-2 p-0 d-flex justify-content-around h-75"  >
                        <button id="btnLimitsS" class="button-option w-75 d-flex justify-content-center align-items-center rounded-4"
                                (click)="setSpendingLimits(true)" title="Limits">
                          <img class="img-button" src="assets/images/cards/limits.svg" alt="Limits">
                          <p class ="p-option mt-3 ms-2">Limits</p>
                        </button>
                      </div>

                      <div  class="col-lg-3 col-6 my-lg-0 my-2 p-0 d-flex justify-content-around h-75"  >
                        <button id="btnLockS" *ngIf="selectedCard.is_locked==false"
                                class="button-option  w-75 d-flex justify-content-center align-items-center rounded-4 "
                                (click)="toggleLock(selectedCard.account_id, selectedCard.id)" title="Lock">
                          <img class="img-button" src="assets/images/cards/lock_card.svg" alt="Lock">
                          <p class ="p-option mt-3 ms-2">Lock</p>
                        </button>

                        <button id="btnUnlockS" *ngIf="selectedCard.is_locked==true"
                                class="button-option-l w-75 d-flex justify-content-center align-items-center rounded-4 "
                                (click)="unlockCard(selectedCard.account_id, selectedCard.id)" title="Unlock">
                          <img class="img-button" src="assets/images/cards/lock_active.svg" alt="Unlock">
                          <p class ="p-option mt-3 ms-2">Unlock</p>
                        </button>

                      </div>

                      <div  class="col-lg-3 my-lg-0 my-2 col-6 p-0 d-flex justify-content-around h-75">
                        <button id="btnMoreS" *ngIf="(selectedCard.is_primary == false) || (selectedCard.physical_active == 1)"
                                class="button-option w-75 d-flex justify-content-center align-items-center rounded-4 "
                                (click)="seeCardOptions()" title="More">
                          <img class="img-button" src="assets/images/cards/more.svg" alt="More">
                          <p class ="p-option mt-3 ms-2">More</p>
                        </button>
                        <button  *ngIf="selectedCard.physical_active == 0  && selectedCard.is_primary == true"
                                 class="button-activate w-75 d-flex justify-content-center align-items-center rounded-4 "
                                 (click)="activateCard(this.selectedCard.account_id, this.selectedCard.id, this.selectedCard.number)"
                                 title="Activate">
                          <img class="img-button" src="assets/images/cards/activate.svg" alt="Activate">
                          <p class ="p-activate mt-3 ms-2">Activate</p>
                        </button>
                      </div>

                    </div>
                  </div> <!--Close col-8-->

                </div> <!--Close row-->

              </div> <!--Close card-body-->
            </div> <!--Close card-->
          </div> <!--Row-->

          <!--Card transactions-->
          <div class="row rounded-4 mt-3 px-1  px-lg-4">
            <div class="card  bg-color-clear rounded-3 border-0  h-100">
              <div class="card-body" >
                <div class=" py-0 ">
                  <app-card-tr-display
                    [productId]= "this.selectedCard.account_id"
                    [cardID]="this.selectedCard.id"
                    [month]=this.home_default_month
                    [year]=this.home_default_year
                  ></app-card-tr-display>
                </div>
              </div> <!--Close card body-->
            </div> <!--Close card-->
          </div> <!--Close row-->

          <div class="divider-from-nav d-lg-none d-block" style="height: 3rem;"></div>

        </div> <!--Close ngif-->





    <!--Show Spend Limits-->
    <div *ngIf="showSpendLimits === true">
      <app-modal-card-limits>
        <app-card-spend-limit-set
          [loan_id]="this.selectedCard.account_id"
          [card_id] ="this.selectedCard.id"
          (closeSpendLimit)="setSpendingLimits($event)"
        ></app-card-spend-limit-set>
      </app-modal-card-limits>
    </div>

    <!--Show Limit Summary-->
    <div *ngIf="showLimitSummary === true">
      <app-modal-card-limits>
        <app-card-l-sum-edit
          [loan_id]="this.selectedCard.account_id"
          [card_id] ="this.selectedCard.id"
          (closeEditFeature) = displayCardLimits(false)
        ></app-card-l-sum-edit>
      </app-modal-card-limits>
    </div>

    <div class="d-lg-none d-block" style="height: 5rem;"></div>

</div>  <!--Close container h-100-->









