<div class="container-fluid bg-color-clear">

  <div class="row ">
    <div id="divAvailable" class="col-4 col-lg-3  div-hover "
         (click)="chooseOption('available')">
      <p id="pAvailable" class="p-title w-100 mx-0 text-center py-2"
         [ngClass]="{'p-clicked' : current_option ==='available'}" >Available</p>
    </div>
    <div id="divEarned" class="col-4 col-lg-3  div-hover "
         (click)="chooseOption('earned')">
      <p  id="pEarned" class="p-title w-100 mx-0 text-center py-2"
         [ngClass]="{'p-clicked' : current_option ==='earned'}">Earned</p>
    </div>
    <div id="divActive" class="col-4 col-lg-3 div-hover "
         (click)="chooseOption('active')">
      <p id="pActive" class="p-title w-100 mx-0 text-center py-2"
         [ngClass]="{'p-clicked' : current_option ==='active'}">Active</p>
    </div>

    <div id="divDescription" class="col-3 d-none d-lg-inline  div-hover"
         (click)="chooseOption('description')">
      <p id="pDescription" class="p-title w-100 mx-0 text-center py-2"
         [ngClass]="{'p-clicked' : current_option ==='description'}"
      >How Coupons & Discount Work</p>
    </div>
  </div>

  <div class="row" >

    <div class="col-md-4 mb-3 mb-lg-1 " >
      <div class="card bg-color-clear " >
        <div class="card-body py-1 "  >
          <div class="d-block "  >
              <p class="p-summary-title mb-0">Paid Cash Back</p>
              <p *ngIf="isEnrolled" id="bPaid"  class="p-balance my-1">$ {{benefitsAccount.paid_cashback}}</p>
              <p *ngIf="!isEnrolled"   class="p-balance my-1">$ 0.00</p>
          </div> <!--Close row-->

        </div> <!--Close card body-->
      </div> <!--Close card-->

    </div> <!--Close col-->

    <div class="col-md-4 mb-3 mb-lg-1" >
      <div class="card bg-color-clear " >
        <div class="card-body py-1"  >
          <div class="d-block"  >
              <p class="p-summary-title mb-0">Pending Cash Back:</p>
              <p id="bPending" *ngIf="benefitsAccount" class="p-balance-pending my-1">$ {{benefitsAccount.pending_cashback}}</p>
              <p  *ngIf="!benefitsAccount" class="p-balance-pending my-1">$ 0.00</p>
          </div> <!--Close row-->

        </div> <!--Close card body-->
      </div> <!--Close card-->

    </div> <!--Close col-->

    <div class="col-md-4 mb-3 mb-lg-1">

      <div class="card bg-color-clear">
        <div class="card-body py-1">
          <div class="d-block">
              <p class="p-summary-title mb-0">Total Saved/Coupon Savings</p>
              <p *ngIf="benefitsAccount" id="bSaved" class="p-balance my-1">$ {{benefitsAccount.total_saved}}</p>
              <p *ngIf="!benefitsAccount" class="p-balance my-1">$ 0.00</p>
          </div> <!--Close row-->

        </div> <!--Close card body-->
      </div> <!--Close card-->

    </div> <!--Close col-->

  </div> <!--Close row-->

</div>


