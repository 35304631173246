import {Component, OnInit} from '@angular/core';
import {distinctUntilChanged, tap} from "rxjs";
import {BreakpointObserver , Breakpoints} from "@angular/cdk/layout";
import {DeviceDetectorService} from "ngx-device-detector";
import {environment} from "../../../../environments/environment";
import {ProfileService} from "../../../services/profile.service";
import {slideInLeft, slideInLower, slideInRight, slideInUpper} from "../../Shared/animations";
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  animations: [slideInUpper,slideInLower,slideInLeft, slideInRight],
})
export class ProfileComponent implements OnInit{

  brand :string = environment.brand
  brand_img  :string = 'assets/images/bank_imgs/logo_' + environment.brand + '.svg'
  displayLarge !:boolean

  docsPrivatePolicy !:string
  docsPrivatePolicyCRB !:string
  constructor(
    private deviceService: DeviceDetectorService,
    private profileService:ProfileService
  ) {}

  getLegalTerms():void{
    this.profileService.getLegalDicsKYC().subscribe({
      next:(res)=>{
        this.docsPrivatePolicy = res.res[2].doc
        this.docsPrivatePolicyCRB = res.res[4].doc
      }})
  }

  redirectToFile(url:string){
    window.open(url, "_blank");
  }

  ngOnInit() {
    this.getLegalTerms()
    this.displayLarge = !this.deviceService.isMobile();
  }

}
