import {Component, Input, OnInit} from '@angular/core';
import {Transaction, TransactionFilter} from "../../../services/transaction.service";
import {HomeService} from "../../../services/home.service";
import {TransactionService} from "../../../services/transaction.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {SharedataService} from "../../../services/sharedata.service";
import {AccountService} from "../../../services/account.service";
import {TrDisputeMsgComponent} from "../tr-dispute-msg/tr-dispute-msg.component";



@Component({
  selector: 'app-transactions-display',
  templateUrl: './transactions-display.component.html',
  styleUrls: ['./transactions-display.component.css']
})

export class TransactionsDisplayComponent implements OnInit{

  @Input() productId !:string
  @Input() cardID !:string
  @Input() year!:number
  @Input() month!: number
  @Input() wordSearch !:string
  @Input() isHome :boolean = false
  @Input() transactions_list !: Transaction[]
  @Input()  transactions_display !: Transaction[]

  monthNames = ['', "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

  displayTransactions :boolean = false
  isFilterTransactions :boolean = false
  search_word !: string


  showAll : boolean = false
  buttonName : string = 'See all Activity'
  constructor(
    private homeService: HomeService,
    private accountsService: AccountService,
    private shareDataService: SharedataService,
    private transactionService: TransactionService,
    public dialog : MatDialog
  ) { }


  filterTransaction(fieldName:string, newValue:string):void{
    if(newValue){
      const filteredList = this.transactions_list.filter(t=>t.desc.toLowerCase().includes(newValue.toLowerCase()))
        this.transactions_display = filteredList
    } else {
      this.transactions_display = this.transactions_list
    }
  }
  getTransactionsList (data:string , period:number = 0):void {
    let payload = {
      loan_id: data,
       year:this.year, month:this.month,
      type_in: [], limit: 1000, period: period
    }

    this.transactionService.getTransactions(payload).subscribe({ next:(res)=>{
        if(res.e_code === 1){
          this.transactions_list = res.transfers.reverse()
          this.transactions_display =  this.transactions_list.length<=3 ?
            this.transactions_list : this.transactions_list.slice(0,3)
          if(this.transactions_list.length > 0) {
            this.displayTransactions = true
          } else {
            this.displayTransactions = false
          }
        }
      }})
  }

  // Executed by Transactions component (parent) when the user picks a different month
  getTransactionsMonthList (data:string, mon: number, yr: number):void {
    this.year = yr
    this.month = mon
    let payload = {
      loan_id: data,
      year:this.year, month:this.month,
      type_in: [], limit: 1000, period: 0
    }
    this.transactionService.getTransactions(payload).subscribe({ next:(res)=>{
        if(res.e_code === 1){
          this.transactions_list = res.transfers.reverse()

          this.transactions_display =  this.transactions_list.length<=3 ?
            this.transactions_list : this.transactions_list.slice(0,3)
          if(this.transactions_list.length > 0) {
            this.displayTransactions = true
          } else {
            this.displayTransactions = false
          }
        }
      }})

  }


  filterTransactions(data:boolean):void{
    this.isFilterTransactions = data
  }

  selectFilterTransactions (data:TransactionFilter):void {

    this.isFilterTransactions = false

    let payload = {
      loan_id: this.productId,
      year: Number(data.year), month: Number(data.month) ,
      type_in: [], limit: Number(data.limit) , period: Number(data.period)
    }

    this.transactionService.filterTransactions(payload).subscribe({ next:(res)=>{
        if(res.e_code === 1){
          this.transactions_list = res.transfers.reverse()
          this.transactions_display =  this.transactions_list.length<=3 ?
            this.transactions_list : this.transactions_list.slice(0,3)
          if(this.transactions_list.length > 0) {
            this.displayTransactions = true
          } else {
            this.displayTransactions = false
          }
        }}
    })
  }

  getNumber(data:string){
    return Number(data)
  }
  disputeThisTransaction(data: any): void {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title:"Dispute Transaction",
      msg:"Please, contact customer service to dispute this transaction."
    }
    dialogConfig.panelClass = 'full-30-dialog';
    this.dialog.open(TrDisputeMsgComponent, dialogConfig)

    // this.disputeTransaction = true
    // this.dispute_info = data
    //
    // if (this.cardID){
    //   this.dispute_info = {...this.dispute_info, 'source': 1}
    // } else {
    //   this.dispute_info = {...this.dispute_info, 'source': 0}
    // }
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.data = { info:this.dispute_info }
    // dialogConfig.panelClass = 'full-50-dialog';
    //
    // this.dialog.open(TrDisputeComponent, dialogConfig)
  }

  seeAllActivity(): void {

    this.showAll = !this.showAll

    const displayShortList = (this.transactions_list.length<=3 ?
      this.transactions_list : this.transactions_list.slice(0,3))

    this.transactions_display = (this.showAll == true) ? this.transactions_list : displayShortList

    this.buttonName = (this.showAll == true) ? "Hide Activity" : "See all Activity"
  }

  getMonthName(data:string){
    return this.monthNames[Number(data)]
  }
  ngOnInit(): void {

    // Home Component request
    if(this.isHome) {
      if (this.productId!=undefined) {
        this.getTransactionsList(this.productId)
      } else { this.displayTransactions = false}
    }
    // Transactions Component: default view : Main account transactions
    else if (!this.isHome){
      if(this.productId ===undefined) { // Default display: Main Account
        this.accountsService.getMainAccount().subscribe({next:(res=>{
            let d = new Date()
            this.year = d.getFullYear()
            this.month = d.getMonth()+1
            this.getTransactionsList(res.account.id)
            this.productId = res.account.id
          })})
      }
      else { this.displayTransactions = false }
    }
  }

}

