import {Component, OnInit} from '@angular/core';
import {appearSlow} from "../../../Shared/animations";
import {PaymentRequest, PaymentRequestHisto} from "../../../../services/req-payments.service";
import {environment} from "../../../../../environments/environment";
import {SharedataService} from "../../../../services/sharedata.service";


@Component({
  selector: 'app-payments-request',
  templateUrl: './payments-request.component.html',
  styleUrls: ['./payments-request.component.css'],
  animations: [ appearSlow ],
})
export class PaymentsRequestComponent implements OnInit{
  your_route = "Request Money"

  isLargeScreen !:boolean

  // Change view
  isPaymentsRequest :boolean = false
  brand : string = environment.brand
  requests_pending !:PaymentRequest[]
  pending_high !: boolean
  requests_histo !:PaymentRequestHisto[]
  requests_histo_all !:PaymentRequestHisto[]
  histo_high !: boolean
  isFilterPaymentRequest :boolean = false

  search_word !:string



  constructor(
    private shareDataService: SharedataService
  ) { }
  filterAlert(data:string){
    if(data !==undefined){
      const requestList = this.requests_histo_all.filter(a=>a.description.toLowerCase().includes(data.toLowerCase()))
      this.requests_histo = requestList
    } else{
      this.requests_histo = this.requests_histo_all
    }

  }
  isFilter():void{
    this.isFilterPaymentRequest = !this.isFilterPaymentRequest
  }
  filterRequests(data:Object){
    let selectedChannels : String [] = []
    let filterObject = new Map(Object.entries(data));
    filterObject.forEach((value, key) => {
      if(value==true) { selectedChannels.push(key) }
    })

    selectedChannels = selectedChannels.map((item:String) => item =='isBank'? this.brand : item)

    this.requests_histo =   this.requests_histo_all
      .filter((req:PaymentRequestHisto)=> selectedChannels.includes(req.channel))

    this.histo_high = this.requests_histo_all.length > 3
  }


  addNewRequest():void{
    this.isPaymentsRequest = true
  }
  ngOnInit() {
    // this.shareDataService.getScreenSizeLarge().subscribe(
    // (value)=> {this.isLargeScreen = value})


    this.requests_pending = [
      { name: 'Adam',
        surname: 'Smith',
        date: '10-01-2024',
        amount: '15.00',
        description: "Coffee Date",
      },
      { name: 'Tommy',
        surname: 'Brown',
        date: '10-01-2024',
        amount: '150.00',
        description: "Dinner Date",
      },
      { name: 'Adam',
        surname: 'Smith',
        date: '10-01-2024',
        amount: '15.00',
        description: "Coffee Date",
      },
      { name: 'Tommy',
        surname: 'Brown',
        date: '10-01-2024',
        amount: '150.00',
        description: "Dinner Date",
      }
    ]

    this.pending_high = this.requests_pending.length > 3

    this.requests_histo_all = [
      { name: 'Adam',
        surname: 'Smith',
        date: '10-01-2024',
        amount: '15.00',
        description: "Coffee Date",
        status: "Pending",
        channel: 'Thrive'

      },
      { name: 'Tommy',
        surname: 'Brown',
        date: '10-01-2024',
        amount: '150.00',
        description: "Dinner Date",
        status: "Approved",
        channel: 'Zelle'
      },
      { name: 'Adam',
        surname: 'Smith',
        date: '10-01-2024',
        amount: '15.00',
        description: "Coffee Date",
        status: "Declined",
        channel: 'Zelle'
      },
      { name: 'Tommy',
        surname: 'Brown',
        date: '10-01-2024',
        amount: '150.00',
        description: "Dinner Date",
        status: "Approved",
        channel: 'Thrive'
      }
    ]

    this.requests_histo =  this.requests_histo_all

    this.histo_high = this.requests_histo_all.length > 3
  }
}
