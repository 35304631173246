<div class="container-fluid   h-100 align-items-center" >

  <div class="row rounded-4 pt-2 bg-color-clear ">

    <div id="available"
         class="col-3   px-0 d-flex align-items-center "
         routerLink="/main/couponsDiscounts"
    ><p class="p-title w-100 mx-0  text-center mb-0 "
        (click)="optionSelected('available')"
    >Available</p>
    </div>

    <div id="earned"
         class="col-3 px-0 d-flex align-items-center"
         routerLink="/main/couponsDiscounts"
    ><p class="p-title w-100 mx-0  text-center mb-0"
        (click)="optionSelected('earned')"
    >Earned</p>
    </div>

    <div id="active"
         class="col-3 px-0 d-flex align-items-center"
         routerLink="/main/couponsDiscounts"
    ><p class="p-title w-100 mx-0  text-center mb-0"
        (click)="optionSelected('active')"
    >Active</p>
    </div>

    <div id="coupons"
         class="col-3 px-0 d-lg-none d-flex align-items-center"
         routerLink="/main/couponsDiscounts"
    ><p class="p-title w-100 mx-0   text-center mb-0"
        (click)="optionSelected('description')"
    >Coupons</p>
    </div>

    <div  id="couponsWork"
          class="col-3 d-none d-lg-block div-hover px-0 text-center d-flex align-items-center"
         routerLink="/main/couponsDiscounts"
    ><p class="p-title w-100  text-center mb-0"
        (click)="optionSelected('description')"
    >How Coupons & Discount Work</p>
    </div>

  </div>

  <div class="row h-100  ">

    <div class="col-lg-6 col-12  h-75 d-flex align-items-center my-1" >

      <div class="card  w-100 h-75 bg-color-clear" >
        <div class="card-body  px-0 py-1  ">
          <div class="row ps-2  h-100"  >
            <div class="col-6 d-flex align-items-center"  >
              <div>
                <p class=" p-balance-title m-0 ">Paid Cash Back</p>
                <p *ngIf="!isEnrolled" class="p-balance mb-0" >$0.00</p>
                <p *ngIf="isEnrolled" id="paidCash" class="p-balance mb-0" >${{benefitsAccount.paid_cashback}}</p>
              </div>

            </div>
            <div class="col-6 d-flex align-items-center" >
              <div>
                <p class="p-balance-title m-0">Pending Cash Back:</p>
                <p *ngIf="!isEnrolled"  class="p-balance mb-0">$0.00</p>
                <p *ngIf="isEnrolled" id="pedingCash" class="p-balance mb-0">${{benefitsAccount.pending_cashback}}</p>
              </div>

            </div>
          </div> <!--Close row-->

        </div> <!--Close card body-->
      </div> <!--Close card-->

    </div> <!--Close col-->

    <div class="col-lg-6 col-12  h-75 d-flex align-items-center my-1">

      <div class="card   w-100 h-75 bg-color-clear">
        <div class="card-body px-0 py-1">
          <div class="row ps-2 h-100">
            <div class="col-12 d-flex align-items-center ">
              <div>
                <p class="p-balance-title m-0">Total Saved/Coupon Savings</p>
                <p *ngIf="!isEnrolled"  class="p-balance mb-0">$0.00</p>
                <p *ngIf="isEnrolled" id="savedCash" class="p-balance mb-0">${{benefitsAccount.total_saved}}</p>
              </div>
            </div>
          </div> <!--Close row-->
        </div> <!--Close card body-->
      </div> <!--Close card-->

    </div> <!--Close col-->

  </div> <!--Close row-->

</div>

