import {Component, OnInit} from '@angular/core';
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import {ProfileService} from "../../../services/profile.service";
import {Router} from "@angular/router";
import {appearSlow} from "../../Shared/animations";

@Component({
  selector: 'app-profile-address-edit',
  templateUrl: './profile-address-edit.component.html',
  styleUrls: ['./profile-address-edit.component.css'],
  animations: [ appearSlow ],
})
export class ProfileAddressEditComponent implements OnInit{


  // Confirm changes modal
  confirmChangesModal !:boolean
  first_name !:string
  last_name !:string
  isDataEdited:boolean = false
  dataEditSuccess !:boolean
  fieldsConfig !:FormFieldConfig[]

  constructor(
    private profileService : ProfileService,
    private router:Router
  ) { }


  validateOn:string = 'change'
  validationErrors : any = {}
  fieldWasEdited: any = {}
  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues:any) {
    this._fieldValues = newFieldValues
    if (this.validateOn == 'change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  updateFieldValue(fieldName:string, newValue:string) {
    this.fieldValues = { ... this.fieldValues, [fieldName]: newValue}
  }
  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }


  cancelEditAddress():void {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl])})
  }

  summitEditAddress(data:boolean):void{

    this.confirmChangesModal = data


    this.profileService.editPostalAddress(this.fieldValues).subscribe({
      next:(res)=>{
        this.isDataEdited  = true

        if (res.e_code ===1) {
          this.dataEditSuccess  = true
        }

      //  setTimeout(()=>{
          this.cancelEditAddress()
      //  },2000)
      }
    })

  }

  confirmChanges():void{

    this.validationErrors = this.getvalidationErrors(true)
    if(Object.keys(this.validationErrors).length > 0)
      return


    this.confirmChangesModal = false
  }
  ngOnInit() {
    this.fieldsConfig = this.profileService.formFieldsAddress
    this.profileService.getUserInfo().subscribe({
      next:(res)=>{
        for (let field of this.fieldsConfig)
          this.fieldValues[field.name] = res.res[field.name]

        this.first_name = res.res['first_name']
        this.last_name = res.res['last_name']
      }})
  }

}
