<div class="container-fluid">

  <div class="row mt-2 px-2">

    <div class="col-md-1 col-2 d-flex justify-content-center  p-0">
      <div class="w-100  h-100">
        <img class="img-fluid h-100"  src="assets/images/user-menu/tAndc.svg"  alt="DDA Img">
      </div>
    </div>

    <div class="col-md-10 col-8 p-0 d-flex align-items-center" >
      <p class="daa-text  p-0 mb-0">Deposit Account Agreement</p>
    </div>

    <div class="col-md-1 col-2  p-0 d-flex align-items-center justify-content-center button-pointer" >
      <img class=" w-75 img-fluid"  id="closeBtn" (click)="closePolicy()"
           src="assets/images/edit_profile/cancel_edit.svg"/>
    </div>

  </div>

  <div class="row px-2 my-3 ">
    <div class="col-12 scroll-daa">
      <div class="p-daa">{{this.daaPolicy}}</div>
    </div>
  </div>

  <div class="row px-1 my-3">
    <div class="offset-md-2 col-md-8  offset-lg-3 col-lg-6 col-12  d-flex justify-content-center align-items-center my-2  ">
      <button id="activateBtn" class="violet-button w-lg-75 w-100 rounded-2 py-3" (click)="closePolicy()">Close</button>
    </div>
  </div>

</div>
