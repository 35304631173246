<!--*ngIf="!displayDiscountsCoupons"-->
<div  class="container-fluid h-100" [@appearSlow]  >

  <app-nav-buttons [current_route]="your_route"></app-nav-buttons>

      <!--First Row-->
      <div class="row my-3 px-0 px-lg-3 " >

        <div class="col-lg-6 col-12">
          <div class="card h-100 bg-color-clear" >
            <div class="card-body pb-0 ">

              <div class="row px-0">

                <div class="col-6" >
                  <p class="text-bank-hint color-bank ">Accounts</p>
                </div>

                <div class="col-6 d-flex justify-content-end" >
                  <p class="text-bank-hint color-bank button-pointer p-add ">Add New Account  +</p>
                </div>

              </div>

              <div class="row  ">
                <app-accounts-display></app-accounts-display>
              </div>

            </div> <!--Close card body-->
          </div> <!--Close card-->
        </div>

        <div class="col-lg-6 col-12 mt-3 mt-lg-0 ">

          <!--Large Screen -->
          <div class="card h-100 d-none d-lg-block bg-color-clear ">

            <div class="card-body h-100  pb-0">

              <!-- Title -->
              <div class="row h10 ">
                <div class="d-flex align-items-center">
                  <p class="text-bank-hint color-bank m-0">I want to ...</p>
                </div>

              </div>

              <div class="row" >

                <div class="col-lg-4 col-6 py-2 px-3 d-flex align-items-center align-content-center  " >
                  <button class="h-100 w-100 border-0 accounts_option  rounded-4 py-3" id="creditScoreBtn">
                    <div class="text-center">
                      <img src="assets/images/accounts/accounts_simbol.svg" alt="Symbol" >
                    </div>
                    <p class="p-button m-0" >See my </p>
                    <p class="p-button m-0" >Credit Score</p>
                  </button>
                </div>

                <div class="col-lg-4 col-6 py-2 px-3 d-flex align-items-center align-content-center " >
                  <button class="h-100 w-100  accounts_option border-0 rounded-4 py-3"
                          routerLink="/main/paymentsTransfers" id="paymentsTransfersBtn">
                    <div class="text-center">
                      <img src="assets/images/accounts/accounts_simbol.svg" alt="Symbol" >
                    </div>
                    <p class="p-button m-0" >Make a</p>
                    <p class="p-button m-0" >Transfer</p>
                  </button>
                </div>

                <div class="col-lg-4 col-6 py-2 px-3  d-flex align-items-center align-content-center" >
                  <button class="h-100 w-100  accounts_option border-0 rounded-4 py-3"
                          (click)="reportToCreditBureau()"  id="reportBtn">
                    <div class="text-center">
                      <img src="assets/images/accounts/accounts_simbol.svg" alt="Symbol" >
                    </div>
                    <p class="p-button m-0" >Report to</p>
                    <p class="p-button m-0" >Credit Bureau</p>
                  </button>

                </div>

              </div>

              <div class="row">

                <div class="col-lg-4 col-6 py-2 px-3 d-flex align-items-center align-content-center">
                  <button class="h-100 w-100  accounts_option border-0 rounded-4 py-3"
                          routerLink="/main/cards" id="cardSettingBtn">
                    <div class="text-center">
                      <img src="assets/images/accounts/accounts_simbol.svg" alt="Symbol" >
                    </div>
                    <p class="p-button m-0" >Card</p>
                    <p class="p-button m-0" >Settings</p>
                  </button>
                </div>

                <div class="col-lg-4 col-6 py-2 px-3 d-flex align-items-center align-content-center" >
                  <button class="h-100 w-100  accounts_option border-0 rounded-4 py-3"
                          (click)="displayAccountRoutingNumber()" id="routingBtn">
                    <div class="text-center">
                      <img src="assets/images/accounts/accounts_simbol.svg" alt="Symbol" >
                    </div>
                    <p class="p-button m-0" >Account</p>
                    <p class="p-button m-0" >Information</p>
                  </button>
                </div>

                <div class="col-lg-4 col-6 py-2 px-3 d-flex align-items-center align-content-center" >
                  <button class="h-100 w-100  accounts_option border-0 rounded-4 py-3"
                          title="Report to Credit Bureau"  id="discountsBtn" routerLink="/main/couponsDiscounts">
                    <div class="text-center">
                      <img src="assets/images/accounts/accounts_simbol.svg" alt="Symbol" >
                    </div>
                    <p class="p-button m-0" >Coupons &</p>
                    <p class="p-button m-0" >Discounts</p>
                  </button>
                </div>

              </div> <!--Close row-->

            </div> <!--Close card body-->
          </div> <!--Close card-->

          <!--Small Screen d-none  d-sm-block d-md-none -->
          <div class="card h-100 d-lg-none  bg-color-clear  ">

            <div class="card-body">

              <!-- Title -->
              <div class="row  ">
                <div class="d-flex align-items-center">
                  <p class="text-bank-hint color-bank m-0">I want to ...</p>
                </div>

              </div>

              <div class="row">
                <div class="col-lg-4 col-6 py-2 px-3  align-content-center" >
                  <div class="card  accounts_option rounded-3 pb-1   "
                       id="creditScoreBtnSmall"
                       routerLink="/main/accounts/creditScore" >
                    <div class="card-body  py-1  align-content-center ">
                      <div class="text-center">
                        <img src="assets/images/accounts/accounts_simbol.svg" alt="Symbol" >
                      </div>
                      <div class="text-center  my-2">
                        <p class="p-button m-0" >See my </p>
                        <p class="p-button m-0" >Credit Score</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-6 py-2 px-3 align-content-center " >
                  <div class="card  accounts_option rounded-3 pb-1  "
                       id="paymentsTransfersBtnSmall"
                       routerLink="/main/paymentsTransfers" >
                    <div class="card-body  py-1 align-content-center">
                      <div class="text-center">
                        <img src="assets/images/accounts/accounts_simbol.svg" alt="Symbol" >
                      </div>
                      <div class="text-center my-2">
                        <p class="p-button m-0" >Make a </p>
                        <p class="p-button m-0" >Transfer</p>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
              <div class="row">
                <div class="col-lg-4 col-6 py-2 px-3  align-content-center" >
                  <div class="card  accounts_option rounded-3 pb-1 "
                       id="reportBtnSmall"
                       (click)="reportToCreditBureau()" title="Report to Credit Bureau">
                    <div class="card-body  py-1 align-content-center">
                      <div class="text-center">
                        <img src="assets/images/accounts/accounts_simbol.svg" alt="Symbol" >
                      </div>
                      <div class="text-center  my-2">
                        <p class="p-button m-0" >Report to</p>
                        <p class="p-button m-0" >Credit Bureaus</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-6 py-2 px-3 align-content-center " id="statementBtnSmall">
                  <div class="card  accounts_option rounded-3  pb-1" >
                    <div class="card-body  py-1  align-content-center">
                      <div class="text-center">
                        <img src="assets/images/accounts/accounts_simbol.svg" alt="Symbol" >
                      </div>
                      <div class="text-center my-2">
                        <p class="p-button m-0" >Bank</p>
                        <p class="p-button m-0" >Statements</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-6 py-2 px-3 align-content-center" >
                  <div class="card  accounts_option rounded-3 pb-1" id="routingBtnSmall"
                       (click)="displayAccountRoutingNumber()" >
                    <div class="card-body  py-1 align-content-center">
                      <div class="text-center">
                        <img src="assets/images/accounts/accounts_simbol.svg" alt="Symbol" >
                      </div>
                      <div class="text-center my-2">
                        <p class="p-button m-0" >Account</p>
                        <p class="p-button m-0" >Information</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-4 col-6 py-2 px-3 align-content-center" >
                  <div class="card  accounts_option rounded-3 pb-1"
                       id="discountsBtnSmall" routerLink="/main/couponsDiscounts" >
                    <div class="card-body  py-1 align-content-center">
                      <div class="text-center">
                        <img src="assets/images/accounts/accounts_simbol.svg" alt="Symbol" >
                      </div>
                      <div class="text-center my-2">
                        <p class="p-button m-0" >Coupons &</p>
                        <p class="p-button m-0" >Discounts</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div> <!--Close card body-->
          </div> <!--Close card-->

        </div> <!--Close col-->

      </div>


      <!--Second Row-->
      <div class="row my-3 px-0 px-lg-3"  >
        <div class="col-12 ">
          <div class="card bg-color-clear ">
            <div class="card-body p-0">
              <app-coupons-discounts-info></app-coupons-discounts-info>
            </div> <!--Close card body-->
          </div> <!--Close card-->
        </div> <!--Close row-->
      </div> <!--Close row-->


      <!--Third Row-->
      <div class="row my-3 px-0 px-lg-3" >

        <div class="col-lg-9 col-12 ">
          <div class="card w-100  h-100 bg-color-clear">
            <div class="card-body px-0 ">

              <div class="row  my-1">
                <div class="col-6 col-lg-3">
                  <p id="p_ra" [ngClass]="show_activity ? 'p-title-selected w-100 mx-0 text-center py-1 button-pointer' :
                            'p-title w-100 mx-0 text-center py-1' "
                     (click)="showStatements('activity')">Recent Activity</p>
                </div>
                <div class="col-6 col-lg-3">
                  <p id="p_stat" [ngClass]="show_statements ? 'p-title-selected w-100 mx-0 text-center py-1 button-pointer' :
                         'p-title w-100 mx-0 text-center py-1' "
                     (click)="showStatements('statements')">Statement & Documents</p>
                </div>
              </div>

              <div *ngIf="show_activity" class=" px-0">
                <app-accounts-tr-display
                  [year] = home_default_year
                  [month]=home_default_month
                ></app-accounts-tr-display>
              </div>

              <div *ngIf="show_statements">
                <app-acc-statements
                  [url_display]="'Accounts'"
                ></app-acc-statements>
              </div>

            </div> <!--Close card body-->
          </div> <!--Close card-->
        </div><!--Close col-->

        <div class="col-lg-3 col-12 mt-4 mt-lg-0" >
          <div class="card h-100 bg-color-clear" >
            <div class="card-body">

              <div class="row ">
                <p class="text-bank-hint color-bank ps-2 ">Frequent Recipients</p>
              </div>
              <div class="  h-100">
                <app-recipients-display></app-recipients-display>
              </div>

            </div> <!--Close card body-->
            <div class="card-footer card-footer-div border-top-solid">
              <div class=" d-flex justify-content-center align-items-end" >
                <button routerLink="/main/accounts/recipients" class="fr-button py-1 " >Manage Recipients</button>
              </div>
            </div>
          </div> <!--Close card-->
        </div><!--Close col-->

      </div>


    <div class="d-lg-none d-block" style="height: 5rem;"></div>

    <!--NgIf displayDiscountsCoupons-->
<!--    <div *ngIf="displayDiscountsCoupons">-->
<!--      <app-modal>-->
<!--        <app-coupons-discounts-msg></app-coupons-discounts-msg>-->
<!--      </app-modal>-->
<!--    </div>-->

    <!--NgIf showAccountRoutingNumber-->
    <div *ngIf="showAccountRoutingNumber==true">
      <app-modal-new-acc>
        <app-account-info></app-account-info>
      </app-modal-new-acc>
    </div>

    <!--NgIf report_credit_bureau-->
    <div *ngIf="report_credit_bureau==true">
      <app-modal-mid>
        <app-acc-report-bureau></app-acc-report-bureau>
      </app-modal-mid>
    </div>

</div> <!--Close container-->



<!--NgIf add_new_account-->
<!--<div *ngIf="this.add_new_account==true">-->
<!--  <app-modal-new-acc>-->
<!--    <app-account-new-->
<!--      [account_id]="this.account_main.id"-->
<!--    ></app-account-new>-->
<!--  </app-modal-new-acc>-->
<!--</div>-->
