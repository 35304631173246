<div>

  <div class="row">

    <div class="col-2 d-flex align-items-center justify-content-center" >
      <img  class=" img-fluid"  src="assets/images/edit_profile/profile_img.svg" />
    </div>

    <div class="col-8 d-flex align-items-center" >
      <p class="p-full-name m-0"  >Edit Personal Information</p>
    </div>

    <!--    <div class="cancel-edit d-flex justify-content-end">-->
    <div class="col-2  d-flex align-items-center justify-content-center button-pointer">
      <img class="img-fluid"  (click)="cancelEdit()"  src="assets/images/edit_profile/cancel_edit.svg"/>
    </div>

  </div>

  <div class="row my-3">
    <div class="offset-2 col-8 text-center py-3  ">
      <img  class=" img-fluid"  src="assets/images/edit_profile/profile_warning.svg" />
    </div>

    <div class="offset-2 col-8  p-0"  >
      <p class="p-question text-center fw-bold my-2">Confirm you want to make these changes to your profile.</p>
    </div>

    <div class="offset-2 col-8  p-0"  >
      <p class="p-text text-center fw-bold my-2">
        This means that all your communications will be redirected considering this update.
      </p>
    </div>

  </div>

  <div class="row my-1 ">

    <div  class="col-lg-6 col-12 d-flex justify-content-center ">
      <button id="btnCancel" (click)="cancelEdit()"  class="yellow-button rounded-4 w-100 py-3 px-2 my-2 ">
        Cancel
      </button>
    </div>

    <div class="col-lg-6 col-12 d-flex justify-content-center ">
      <button id="btnUpdate" class="violet-button rounded-4 w-100 py-3 px-2 my-2 " (click)="updateInformation()">
        Update Information
      </button>
    </div>

  </div>

</div>
