import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import {ProfileEdit, ProfileService} from "../../../services/profile.service";
import {Router} from "@angular/router";
import {appearSlow} from "../../Shared/animations";


@Component({
  selector: 'app-profile-display',
  templateUrl: './profile-display.component.html',
  styleUrls: ['./profile-display.component.css'],
  animations: [ appearSlow],
})
export class ProfileDisplayComponent implements OnInit{

  @Output() submit = new EventEmitter<string>()

  fieldsConfig !:FormFieldConfig[]

  profileData !: ProfileEdit

  idTypes :any = {}

  profileDataKeys !:string[]
  _fieldValues: any = {}
  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues:any) {
    this._fieldValues = newFieldValues
  }
  constructor(
    private profileService : ProfileService,
    private router: Router,
  ) { }

  cancelDisplayProfile():void{
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl])})
  }

  goToEditProfile():void{
    this.submit.emit('isEditProfile')
  }

  getIDTypeList():void {
    this.profileService.getIdType().subscribe({next:(res)=>{
        for(let key in res) {
          this.idTypes[res[key]] = key
        }
        const idTypeCustomer = this.idTypes[this.fieldValues['id_type']]
        this.fieldValues = {...this.fieldValues, 'id_type': idTypeCustomer}
      }})
  }
  ngOnInit() {

    this.fieldsConfig = this.profileService.formFieldsDisplay
    this.profileService.getUserInfo().subscribe(
      {
        next: (res) => {
          if (res.e_code == 1) {
            this.profileData = res.res
            let profileFields : string[] = Object.keys(this.profileData)
            let profileValues : string[] = Object.values(this.profileData)

            for (let field of this.fieldsConfig){
              if (field.name !=='ssn'){
                let idx = profileFields.indexOf(field.name)
                this.fieldValues[field.name] =  profileValues[idx] || ''
              }}

             // Get Id Types from BE
              this.getIDTypeList()
          }
        }
      })

  }
}
