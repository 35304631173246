import {Component, OnInit} from '@angular/core';
import { RecipientExtended} from "../../../services/recipients.service";
import {RecipientsService} from "../../../services/recipients.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-recipients-display',
  templateUrl: './recipients-display.component.html',
  styleUrls: ['./recipients-display.component.css']
})
export class RecipientsDisplayComponent implements OnInit{
  brand : string = environment.brand
  recipients!: RecipientExtended[]
  recipients_display!: RecipientExtended[]

  constructor(
    private recipientsService: RecipientsService,
  ) {}


  ngOnInit() {
    this.recipientsService.getRecipients().subscribe({ next:(res)=>{
     if(res.e_code === 1) {
        this.recipients = res.recipients
        this.recipients_display = (this.recipients.length<=3 ? this.recipients :this.recipients.slice(0,3))
     }
    }})
  }


}
