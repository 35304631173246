<div class="container-fluid h-75 d-flex flex-column justify-content-evenly  " >

      <div id="0" class="row  px-0 my-1  " *ngFor="let rec of recipients_display ">

<!--          <div class="col-1 d-flex align-content-center align-items-center">-->
<!--            <img *ngIf="rec.zelle == true" [src]="'assets/images/paym-request/zelle_logo.svg'" class="img-fluid">-->
<!--            <img *ngIf="rec.kredio == true " [src]="'assets/images/paym-request/logo_' + brand + '.svg'" class="img-fluid">-->
<!--          </div>-->

          <div class="col-6 px-0 " >
              <p id="fullName" class="fr-data mx-0 my-0 " >{{rec.first_name}} {{rec.last_name}}</p>
              <p id="email" class="fr-source m-0" >{{rec.email.toString().split('@')[0]}}</p>
          </div>

          <div class="col-3 fr-button d-flex justify-content-center align-items-center  "  >
            <div class="text-center button-pointer " >
              <div id="routLinkTransfer" routerLink="/main/paymentsTransfers" >
                <img src="assets/images/recipients/transfer_icon.svg"
                     class="img-fluid" routerLink="/main/paymentsTransfers"
                     alt="Transfer">
              </div>
              <p class="p-button mb-0 d-block d-lg-none d-xl-block" >Transfer</p>
            </div>
          </div>

          <div class="col-2 fr-button d-flex justify-content-center align-items-center">
            <div class="text-center button-pointer" >
              <div id="routLinkMore" routerLink="/main/recipients"  >
                <img src="assets/images/recipients/more_icon.svg" routerLink="/main/recipients"
                     class="img-fluid"
                     alt="More Options">
              </div>
              <p class="p-button mb-0 ">More</p>
            </div>
          </div>

      </div>  <!--Close row-->

</div>  <!--Close container-->




