import {Component, OnInit} from '@angular/core';
import {PayrollRecord} from "../../../../services/payroll.service";
import {Router} from "@angular/router";
import {PayrollService} from "../../../../services/payroll.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {PayrollTransferFormComponent} from "../payroll-transfer-form/payroll-transfer-form.component";
import {environment} from "../../../../../environments/environment";
import {animate, style, transition, trigger} from "@angular/animations";
import {appearSlow} from "../../../Shared/animations";

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll.component.html',
  styleUrls: ['./payroll.component.css'],
  animations: [ appearSlow ],
})
export class PayrollComponent implements OnInit{

  your_route:string = 'Live Payroll'
  brand :string = environment.brand


  loan_id !:string
  accountIdArgyle !:string
  unenrolled !: boolean
  payroll_histo!:PayrollRecord[]

  payrollHisto_display: PayrollRecord[] = []

  showAll : boolean = false
  buttonName : string = 'See All Activity'

  isEnrolledPayroll !:boolean
  hasAddedPayroll : boolean = false
  constructor(
    private payrollService: PayrollService,
    private router: Router,
    public dialog : MatDialog,
  ) {
  }

  cancelEnrollmentModal():void{
    this.hasAddedPayroll = false
  }
  cancelEnrollment():void{
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl])})
  }

  transferWages(): void {
    let payload = {
      argyle_account_id : this.accountIdArgyle
    }
    this.payrollService.getPayrollAccount(payload).subscribe({
      next:(res) =>{
        if(res.e_code == 1) {
          // let allocNumber =  res.account.deposit_features.max_allocations || "1" // allow
          let allocNumber =  "1" // allow full transfer only
          const dialogConfig = new MatDialogConfig();
          dialogConfig.data = { loan_id: this.loan_id , allocations : allocNumber }
          dialogConfig.panelClass = 'full-30-dialog';
          this.dialog.open(PayrollTransferFormComponent,dialogConfig)
        }
      }
    })




  }

  unenrollPayroll(): void {
      this.unenrolled = true
  }
  enrollPayroll(): void {
    this.hasAddedPayroll = true
  }

  checkPayrolluserAccountExists():void{
    this.payrollService.getPayrollUser().subscribe({ next:(res)=>{
        // Create Payroll user in bank and redirect to Argyle
        if (res.e_code ===1){  // User is enrolled - No accounts connected
          if(res.res.payroll_accounts.length == 0) {
            this.router.navigateByUrl('/enrollpayroll')
          }}
        else {
          // Create Payroll user in bank and redirect to Argyle
          this.redirectToArgylePayroll()
            }
    }})
  }
  redirectToArgylePayroll():void{
    this.payrollService.createPayrollUser({}).subscribe({
      next:(res)=>{
        if (res.e_code==1){
          this.router.navigateByUrl('/enrollpayroll')
        }}
    })
  }

  getArgyleData(data:any):void {
    this.payrollService.getArgylePaystubs(data).subscribe({
      next:(res)=>{

        if(res.e_code == 1){
          this.payroll_histo = res.res['paystub'].map((tr:any) => {
            const dateString = tr.date
            const D = new Date(dateString);
            let year = D.getFullYear()
            let month = D.getMonth() + 1
            let day = D.getDate()

            let new_date = month + '/' + day + '/' + year
            return {date: new_date, description: tr.description, amount:tr.net_pay}
          })
          this.payrollHisto_display = this.payroll_histo.length<=3 ? this.payroll_histo : this.payroll_histo.slice(0,3)

          let payload = { argyle_account_id: this.accountIdArgyle }
        }
      }})
  }

  ngOnInit() {
    this.payrollService.getPayrollUser().subscribe({ next:(res)=>{
       if (res.e_code ===1){  // User is enrolled - No accounts connected
         if(res.res.payroll_accounts.length == 0) {
           this.isEnrolledPayroll = false
         }
         else { // User is enrolled - At least one account is connected
           this.isEnrolledPayroll = true
           this.loan_id = res.res.account_id
           this.accountIdArgyle = res.res.payroll_accounts[0].account_id

           let payload = { argyle_account_id:this.accountIdArgyle ,from_date:'', end_date :'', limit :''}

           this.getArgyleData(payload)
         }
       } else { /// User not enrolled
         this.isEnrolledPayroll = false
       }
      }})
  }

}
