import { Component ,Input} from '@angular/core';
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import {
  checkLengthChrValidator,
  emailValidator,
  isNotEmptyValidator, isPasswordValidator
} from "../../Shared/forms-shared/validators";
import {Router} from "@angular/router";
import {SignupService} from "../../../services/signup.service";
import {SharedataService} from "../../../services/sharedata.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent {

  brand_img  :string = 'assets/images/bank_imgs/logo_blue_' + environment.brand + '.svg'
  newEmail !:string
  errorSignUp !:string

  fieldsConfig: FormFieldConfig[] = [
    {
      name: 'email',
      displayName: 'Email',
      placeHolder: 'Email',
      type: 'email',
      validators : [isNotEmptyValidator, emailValidator],
    },
    {
      name: 'password',
      displayName:'Password',
      placeHolder: 'Enter your password',
      type: 'password',
      validators : [isNotEmptyValidator, isPasswordValidator],
    },
    {
      name: 'password_2',
      displayName:'Re-enter password',
      placeHolder: 'Confirm password',
      type: 'password',
      validators : [isNotEmptyValidator, isPasswordValidator],
    }
  ]

  validateOn: string = 'change'
  fieldWasEdited: any = {}
  validationErrors : any = {}

  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues: any) {
    this._fieldValues = newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  constructor(
    private router: Router,
    private signUpService: SignupService,
    private shareService : SharedataService,
    public dialog : MatDialog
  ) {}


  // Handle input values
  updateFieldValue(fieldName: string, newValue: any) {
    this.fieldValues = {...this.fieldValues, [fieldName]: newValue}
  }

  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)

    }
  }

  submitForm(): void {
    this.validationErrors = this.getvalidationErrors(true)
    if(Object.keys(this.validationErrors).length > 0) {
      return
    }

    // Post form to signUp service
    this.signUpService.signUp(this.fieldValues).subscribe((res) => {
        if (res.e_code == 1) {
          sessionStorage.setItem('isUserKYC', 'true');

          // Share email with other components
          this.shareService.setUserId(res.user_id)
          this.shareService.setUserEmail(res.user_email)
          this.router.navigateByUrl('/signup/legal')
        }
      });
  }

  ngOnInit() {
    // this.errorSignUp = this.signUpService.getError()
    for (let field of this.fieldsConfig)
      this.fieldValues[field.name] = ''
  }


}
