import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router'
import {AgreeemailService} from "../../../services/agreeemail.service";
import {AuthService} from "../../../services/auth.service";
import {subscribeOn, switchMap} from "rxjs";
import {environment} from "../../../../environments/environment";
import {ProfileService} from "../../../services/profile.service";
import {animate, style, transition, trigger} from "@angular/animations";
import {slideInLeft, slideInLower, slideInRight, slideInUpper} from "../../Shared/animations";
@Component({
  selector: 'app-verify-complete',
  templateUrl: './verify-complete.component.html',
  styleUrls: ['./verify-complete.component.css'],
  animations: [ slideInLeft, slideInLower, slideInRight, slideInUpper ],
})
export class VerifyCompleteComponent implements OnInit{

  brand :string = environment.brand
  tokenNew !:string
  emailNew !:string
  isEmailVerified : boolean = false

  docsPrivatePolicy !:string
  docsPrivatePolicyCRB !:string

  constructor(
    private activatedRoute: ActivatedRoute,
    private agreeEmailService : AgreeemailService,
    private profileService:ProfileService
  ) {
    this.getDataUrl()
  }

  getDataUrl():void{
    this.emailNew = this.activatedRoute.snapshot.paramMap.get('email') || ""
    this.tokenNew = this.activatedRoute.snapshot.paramMap.get('token') || ""
  }

  getLegalTerms():void{
    this.profileService.getLegalDicsKYC().subscribe({
      next:(res)=>{
        this.docsPrivatePolicy = res.res[2].doc
        this.docsPrivatePolicyCRB = res.res[4].doc
      }})
  }

  redirectToFile(url:string){
    window.open(url, "_blank");
  }
  ngOnInit() {

    this.getLegalTerms()

    const dataObj = { token: this.tokenNew, email: this.emailNew }

    this.agreeEmailService.verifyToken(dataObj).subscribe({
      next: (res) => {

        if (res.e_code == 1) {
          this.isEmailVerified = true
        } else {
          this.isEmailVerified = false
        }

      }})
  }



}
