import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ProfileEdit, ProfileService} from "../../../services/profile.service";
import * as url from "url";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {PayrollTransferFormComponent} from "../../Home/Payroll/payroll-transfer-form/payroll-transfer-form.component";
import {UserMenuDaaComponent} from "../user-menu-daa/user-menu-daa.component";
import {environment} from "../../../../environments/environment";
import {animate, style, transition, trigger} from "@angular/animations";
import {appearSlow} from "../../Shared/animations";

const hidden = { transform: 'translateX(100%)' };
const visible = { transform: 'translateX(0)' };


@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.css'],
  animations: [ appearSlow,
    trigger('openClose',[
      transition(':enter', [
        style(hidden),
        animate('500ms ease-in', style(visible))
      ]),
      transition(':leave', [
        style(visible),
        animate('200ms ease-out', style(hidden))
      ])
    ])
  ]
})
export class UserMenuComponent implements OnInit{

  brand :string = environment.brand
  profileData!: ProfileEdit
  docsPrivatePolicy !:string
  policy_depositAccount !:string
  isPersonalDetails :boolean = false
  @Output() redirect = new EventEmitter<string>()

  constructor(
    private profileService: ProfileService,
    public dialog : MatDialog,
  ) { }

  redirectToUrl(str:string) {
    this.redirect.emit(str)
  }

  displayDAA():void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { policy: this.policy_depositAccount }
    dialogConfig.panelClass = 'full-40-dialog';
    this.dialog.open(UserMenuDaaComponent,dialogConfig)
  }


  redirectToFile(url:string){
    window.open(url, "_blank");
  }
  displayPersonalDetails() :void{
    this.isPersonalDetails = true
  }

  getLegalTerms():void{
    this.profileService.getLegalDicsKYC().subscribe({
      next:(res)=>{
        this.docsPrivatePolicy = res.res[2].doc
        this.policy_depositAccount = res.res[0].content

      }})
  }
  ngOnInit() {
    this.getLegalTerms()
    this.profileService.getUserInfo().subscribe({
      next:(res)=>{
         this.profileData = res.res
      }})
  }

}
