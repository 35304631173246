import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {ZelleAddContactComponent} from "../zelle-add-contact/zelle-add-contact.component";

@Component({
  selector: 'app-zelle-settings',
  templateUrl: './zelle-settings.component.html',
  styleUrls: ['./zelle-settings.component.css']
})
export class ZelleSettingsComponent implements OnInit{

  zelleContactData !: string []
  zelleDisplaytData !: string []
  isZelleContactData !: boolean

  constructor(
    public dialog : MatDialog
  ) { }

  editZelle():void{
  }
  deleteZelle():void{
  }

  selectZelleOption(data: string , idx: number):void{

    let elem = document.getElementById(idx.toString())

    let num = this.zelleContactData.filter((a:string)=> a == data)
    let numLength = num.length

    if(numLength == 1  && this.zelleContactData.length > 1 ) { // Remove element if there is at least one Zelle reference option
      // Change css class
      if(elem !=null){
        // Remove element from zelleContactData
        this.zelleContactData = this.zelleContactData.filter((a:string)=> a != data)
        elem.className = 'btnSelect'
      }
    } else {
      // Change css class
      if(elem !=null && numLength == 0 ){
        this.zelleContactData = [ ... this.zelleContactData , data]
        elem.className = 'btnSelected'
      }
    }
  }
  deleteZelleOption(data:string):void{
    // Remove element from zelleContactData
    this.zelleContactData = this.zelleContactData.filter((a:string)=> a != data)
    // Remove element from zelleDisplaytData
    this.zelleDisplaytData = this.zelleDisplaytData.filter((a:string)=> a != data)
  }
  saveZelleChanges():void{

  }

  cancelChanges():void{

  }

  addNewContactInfo():void{
      const dialogConfig = new MatDialogConfig();
      // dialogConfig.data = {  }
      dialogConfig.panelClass = 'full-30-dialog';
      this.dialog.open(ZelleAddContactComponent ,dialogConfig)

  }

  ngOnInit() {
    // Get zelle contact data from
    this.zelleContactData = [ 'email@examle.com', '123-456-7890']
    this.zelleDisplaytData = [...this.zelleContactData]

    this.isZelleContactData = this.zelleContactData.length > 0 ? true  : false
  }

}
