import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SharedataService} from "../../../../services/sharedata.service";
import {HomeService} from "../../../../services/home.service";
import {BenefitsAccount} from "../../../../services/payroll.service";

@Component({
  selector: 'app-coupons-discounts-info',
  templateUrl: './coupons-discounts-info.component.html',
  styleUrls: ['./coupons-discounts-info.component.css']
})
export class CouponsDiscountsInfoComponent implements OnInit{
  benefitsAccount !:BenefitsAccount
  isEnrolled :boolean = false

  constructor(
    private sharedDataService : SharedataService,
    private homeService: HomeService
  ) { }

  optionSelected(data:string)  {
    this.sharedDataService.setOption(data)
  }

  ngOnInit() {
    this.homeService.getRewardsAccount().subscribe({ next:(res)=>{
        if (res.e_code===1){
          this.isEnrolled = true
          this.benefitsAccount = res.account
        }
      }})
  }
}
