<div class="container-fluid ">

  <div *ngIf="!successNewRequest" class="p-0 m-0">

    <div class="row">
      <div class="form-floating me-3 my-1" >
        <input
          class="form-control me-2 input-search"
          type="string"
          style="background: #FFFEFA url('assets/images/accounts/magnifier.svg') 95%  no-repeat "
          [placeholder]="'Search'"
          [ngModel]="search_word"
          (ngModelChange)="filterRecipient($event)"
        >
        <label class="form-label ms-2">Search for a recipient here</label>
        <ul class="ps-0 rounded-1" *ngIf="recipients_display" [ngClass]="display_filtered_all? 'scroll-filter': ''" >
          <div *ngFor="let rec of recipients_display">
            <li class="py-2 ps-1 d-flex ">
              <div class="row w-100">
                <div class="col-11 d-flex">
                  <img *ngIf="rec.zelle == true" [src]="'assets/images/paym-request/zelle_logo.svg'" class="img-fluid ">
                  <p *ngIf="rec.zelle == true " class="mb-0 ms-3 align-content-center">{{rec.first_name}} {{rec.last_name}} </p>

                  <img *ngIf="rec.kredio == true " [src]="'assets/images/paym-request/logo_' + brand + '.svg'" class="img-fluid ">
                  <p *ngIf="rec.kredio == true " class="mb-0 ms-2 align-content-center">{{rec.first_name}} {{rec.last_name}} </p>
                </div>
                <div class="col-1 text-center btn-add button-pointer">
                  <p class=" mb-0 button-pointer me-3 "
                     (click)="addRecipient(rec.id, rec)">Add</p>
                </div>
              </div>
            </li>
          </div>
        </ul>
        <div class="p-subtitle-small my-2">You can request multiple payments from up to 15 people.</div>
      </div>
    </div>

    <div class="row my-2 px-2" >

      <div *ngIf="display_selected" class="bg-color-clear rounded-1 ">

        <div class="p-subtitle my-1">Selected Recipients</div>

        <div class="row px-2 row_selected " [ngClass]="display_selected_all ? 'scroll-requests': ''">
          <div *ngFor="let recs of recipients_selected" >
            <div class="row py-2 row_selected">
              <div class="col-1  d-flex align-items-center p-0 text-center "
                   (click)="removeRecipient(recs.id)">
                <div class="p-0 button-pointer mb-0 " (click)="removeRecipient(recs.id)" >
                  <img *ngIf="recs.zelle == true" [src]="'assets/images/paym-request/zelle_logo.svg'" class="img-fluid">
                  <img *ngIf="recs.kredio == true " [src]="'assets/images/paym-request/logo_' + brand + '.svg'" class="img-fluid">
                </div>
              </div>
              <div class="col-8 d-flex align-items-center py-0  ">
                <div class="w-100 py-0 my-0">
                  <p *ngIf="recs.zelle == true " class="mb-0 p-name ">{{recs.first_name}} {{recs.last_name}} </p>
                  <p *ngIf="recs.kredio == true " class="mb-0 p-name">{{recs.first_name}} {{recs.last_name}} </p>
                  <p *ngIf="recs.email" class="mb-0 p-email">{{recs.email}}</p>
                  <p *ngIf="!recs.email" class="mb-0 p-email">{{recs.phone}}</p>
                </div>
              </div>
              <div class="col-3  btn-add button-pointer px-0 ">
                <div class="form-floating py-0" >
                  <input
                    class="form-control input-selected text-end"
                    type="string"
                    [placeholder]="'Amount'"
                    [ngModel]="select_amount"
                    (ngModelChange)="updateRecipientAmount(recs.id , $event)"
                  >
                  <label class="form-label">Amount</label>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

      <div *ngIf="display_selected" class="p-0">
        <div class="col-12   bg-color-clear py-3 d-flex align-items-center justify-content-end">
          <p class="p-totAmount mb-0 h-100 me-2 py-2 ">Total Amount:
            <span class="p-totAmountNum align-items-center">
              ${{ this.totalAmount|number :'.2'}}</span></p>
        </div>
      </div>

    </div>

    <div *ngIf="display_selected" class=" mt-3">
      <div class="row  ">
        <div class="form-floating px-1 " >
          <input
            class="form-control input-memo"
            type="string"
            [placeholder]="'Memo'"
            [ngModel]="select_memo"
            (ngModelChange)="addMemo($event)"
          >
          <label class="form-label input-memo-label ms-2">Memo (Optional)</label>
        </div>
      </div>
    </div>

    <div *ngIf="display_selected" >
      <div class="row mb-2 mt-5">
        <div class="col-10 offset-1">
          <button class="yellow-button rounded-4 w-100 py-3"
                  routerLink="/main/paymentsTransfers" id="btnCancel">
            Cancel</button>
        </div>
      </div>
      <div class="row my-2">
        <div class="col-10 offset-1">
          <button class="violet-button rounded-4 w-100 py-3"
                  (click)="sendRequest()" id="btnSend"
          ><img src="assets/images/paym-request/send-request.svg" class="img-fluid pe-2">
            Send Request</button>
        </div>
      </div>
    </div>

    <div *ngIf="!display_selected " class="text-center">
      <br>
      <br>
      <p class="p-no-select">No recipients have been selected yet.</p>
      <p class="p-no-select" >Add a recipient by searching above.</p>
      <br>
      <br>

      <div class="row mb-2 mt-5">
        <div class="col-10 offset-1">
          <button class="yellow-button rounded-4 w-100 py-3"
                  routerLink="/main/paymentsTransfers" id="btnCancelNoSelected">
            Cancel</button>
        </div>
      </div>
    </div>

  </div>


  <div *ngIf="successNewRequest" class="p-0 m-0">
    <app-pay-req-success
      [totalAmount] = totalAmount
      [amountObject] = amountObject
      [recipients_selected] = recipients_selected
      (newRequest)="startNewRequest($event)"
    ></app-pay-req-success>

  </div>


</div>
