<div class="container-fluid"  [@appearSlow] >
    <div class="row my-5 text-center">
      <div>
        <img src="assets/images/errors/failure.svg" class="img-fluid">
      </div>
    </div>
    <div class="row text-center my-2">
      <p class="p-title">Something went wrong</p>
    </div>
    <div class="row text-center my-2">
      <p id="status" class="p-text fw-bold">{{status}}</p>
      <p id="msg" class="p-text fw-bold">{{statusText}}</p>

      <p class="p-text fw-bold my-2">Close this message and continue banking with <strong>{{brand}}</strong>.</p>
    </div>
    <div class="row mb-3">
      <div class=" col-lg-8 offset-lg-2  col-12 text-center">
        <button id="closeBtn" class=" py-3 rounded-4
         w-100 px-2 " (click)="closeMessage()"> Close </button>
      </div>
    </div>
</div>


