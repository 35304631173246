import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ProfileService} from "../../../../services/profile.service";

@Component({
  selector: 'app-zelle-enroll',
  templateUrl: './zelle-enroll.component.html',
  styleUrls: ['./zelle-enroll.component.css']
})
export class ZelleEnrollComponent implements OnInit{

  email !:string
  phone !:string

  startEnrollment :boolean = false
  constructor(
    public profileService : ProfileService,
    public dialogRef: MatDialogRef<ZelleEnrollComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  enroll():void{
    this.startEnrollment = true
  }
  cancel():void{
    this.dialogRef.close()
  }

  ngOnInit() {
    this.profileService.getUserInfo().subscribe({
      next: (res) => {
        if (res.e_code == 1) {
          this.email = res.res.email
          this.phone = res.res.phone
        }}
    });
  }

}
