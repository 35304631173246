import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormFieldConfig} from "../../../../Shared/forms-shared/typesForm";
import {
  emailOrPhoneValidator,
  emailUSAPhoneValidator,
  isNotEmptyValidator
} from "../../../../Shared/forms-shared/validators";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-zelle-add-contact',
  templateUrl: './zelle-add-contact.component.html',
  styleUrls: ['./zelle-add-contact.component.css']
})
export class ZelleAddContactComponent implements OnInit{
  isSuccess :boolean = false
  isConfirmNewContact :boolean = false
  phone_number !:string
  constructor(

    public dialogRef: MatDialogRef<ZelleAddContactComponent>,
  ) {
  }

  fieldsConfig : FormFieldConfig[] = [
    {
      name: 'zelleInfo',
      displayName: 'Zelle Contact Info',
      type: 'string',
      placeHolder: 'Zelle Contact Info',
      validators: [emailOrPhoneValidator, isNotEmptyValidator]
    },
  ]

  @Input() validateOn: string = 'change'
  fieldWasEdited: any = {}
  validationErrors : any = {}
  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues:any) {
    this._fieldValues= newFieldValues
    if (this.validateOn=='change') {
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  cancelAddContact():void{
   this.dialogRef.close()
  }


  getvalidationErrors(isSubmit:boolean){
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit === true){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    console.log('errors', errors)
    return errors
  }

  setFieldToEdited(fieldName:string) {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)
    }
  }

  updateFieldValue(fieldName:string, newValue:string) {
    this.fieldValues = { ... this.fieldValues, [fieldName]: newValue}
  }


  submitNewContact():void {
    this.validationErrors = this.getvalidationErrors(true)
    if(Object.keys(this.validationErrors).length > 0)
      return

    this.isConfirmNewContact = true

  }

  sendCodeAgain(): void{
  }

  onCodeCompleted(code: string) {
  //  this.updateFieldValue('code', code)
    this.isSuccess = true
  }

  addNewContact():void{
    this.isSuccess = false
    this.isConfirmNewContact = false
    this.getEmptyFields()
  }

  getEmptyFields ():void{
    for (let field of this.fieldsConfig)
      this.fieldValues[field.name] = ""
  }
  ngOnInit() {
    // Set initial values
    this.getEmptyFields()
  }

}
