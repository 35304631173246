import {Component, Input, OnInit} from '@angular/core';
import {Discount, DiscountService} from "../../../../services/discount.service";
import {Account, MainAccount} from "../../../../services/account.service";
import {SharedataService} from "../../../../services/sharedata.service";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-coupons-discounts',
  templateUrl: './coupons-discounts.component.html',
  styleUrls: ['./coupons-discounts.component.css'],
  animations: [
    trigger('appearSlow', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('1000ms ease-out',
          style({ opacity: 1 }),
        ),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('500ms ease-out',
          style({ opacity: 0 }),
        ),
      ]),
    ]),
  ],
})
export class CouponsDiscountsComponent implements OnInit{

  account !:MainAccount
  your_route = 'Your Coupons & Discounts'
  CsAndDs !: Discount[]
  discountInfo !: Discount
  activateMessage :string = "The coupon is now active"
  displayDescInfo: boolean = false
  selected_option !:string
  activate_coupon: boolean = false

  constructor(
    private discountService: DiscountService,
    private sharedDataService : SharedataService,

  ) {}

  goToSelectedDiscounts(data:Discount):void {
    if(this.selected_option!=='available'){
      this.displayDescInfo = false
    } else {
      this.displayDescInfo = true
      this.discountInfo = data
    }
  }

  seeAllDiscounts(): void {
    this.displayDescInfo = false
  }

  activateCoupon( disc_id:string ):void{
    this.discountService.activateDiscount({loan_id:this.account.id ,discount_id:disc_id})
      .subscribe({ next:(res)=>{
      this.activate_coupon =  true
      this.activateMessage = res.e_msg
    }})

    setTimeout(()=>{
      this.displayDescInfo = false
      this.activateMessage= ''
      this.activate_coupon =  false
    },1000)

  }

  changeOption(data:string):void{
    this.displayDescInfo = false
    this.selected_option = data
    if (this.selected_option != 'active'){
      this.discountService.getDiscounts().subscribe({
        next:(res) => {
         this.CsAndDs = res.ret
        }})
    } else {
      this.discountService.getActiveDiscounts().subscribe({
        next:(res) => { this.CsAndDs = res.ret }})
    }
  }

  getMainAccountData ():void {
    this.sharedDataService.getMainAccountData().subscribe({next:(value) => this.account = value})
  }

  ngOnInit(): void {
    this.getMainAccountData()
    // Selection coming from /accounts
    this.sharedDataService.getOption().subscribe({
      next: (res) => {
        if(!res) { this.changeOption('available') }
        else { this.changeOption(res)}
      }
    })
    // this.discountService.getDiscounts().subscribe({
    //   next:(res) => { this.CsAndDs = res.ret }});
  }

}
