<div class="container-fluid">

  <div *ngIf="!isSuccess">

    <div *ngIf="!isConfirmNewContact">
      <div class="row pt-1">
        <div class="col-1 p-0 d-flex justify-content-center align-items-center ">
          <img class="img-fluid " src="assets/images/zelle/zelle_icon.svg" >
        </div> <!-- Second Col ends -->
        <div class="col-10 d-flex align-items-center  ps-0">
          <p class="p-title mb-0 pb-0  ">Add New Contact Info</p>
        </div> <!-- Col ends -->
        <div class="col-1  p-0 d-flex justify-content-center align-items-center button-pointer">
          <img (click)="cancelAddContact()"
               src="assets/images/pay_tf/exit_button.svg" class="img-fluid" alt="exit_button">
        </div> <!-- Second Col ends -->
      </div> <!-- First Row ends -->

      <div class="row d-none d-md-block my-2">
        <p class="p-title-sm ">You can add a mobile number and up to 5 email addresses</p>
      </div>

      <div class="row d-md-none my-2">
        <p class="p-title-sm text-center">You can add a mobile number and up to 5 email addresses</p>
      </div>

      <div class="row">
        <div class="form-floating">
          <input
            [class]="'form-control'"
            [ngClass] ="validationErrors[fieldsConfig[0].name]? 'formError': ''"
            [placeholder]="fieldsConfig[0].placeHolder"
            [type]="fieldsConfig[0].type"
            [ngModel]="fieldValues[fieldsConfig[0].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
          >
        </div>
        <p  class="error my-1" id="error_name" *ngIf="this.validationErrors[fieldsConfig[0].name]">
          {{this.validationErrors[fieldsConfig[0].name]}}
        </p>
      </div>

      <div  class="row text-center  my-3">
        <div class="col-md-6 offset-md-3 col-12">
          <button class="violet-button py-3  w-100 rounded-4 border-0" id="btnClose"
                  (click)="submitNewContact()">Continue</button>
        </div>
      </div>

    </div>

    <div *ngIf="isConfirmNewContact">

      <div class="row pt-1">
        <div class="col-1 p-0 d-flex justify-content-center align-items-center ">
          <img class="img-fluid " src="assets/images/zelle/zelle_icon.svg" >
        </div> <!-- Second Col ends -->

        <div class="col-10 d-flex align-items-center  ps-0">
          <p class="p-title mb-0 pb-0  ">Zelle Setup</p>
        </div> <!-- Col ends -->

        <div class="col-1  p-0 d-flex justify-content-center align-items-center button-pointer">
          <img (click)="cancelAddContact()"
               src="assets/images/pay_tf/exit_button.svg" class="img-fluid" alt="exit_button">
        </div> <!-- Second Col ends -->

      </div> <!-- First Row ends -->

      <p class="p-ver-code">Enter verification code</p>

      <p class="p-ver-code-sm">Provide the code we have sent to the phone number you provided during the sign up process.</p>


      <div class="row">
        <div class="col-12 px-0">
          <code-input
            id="inputCode"
            [isCodeHidden]="true"
            [codeLength]="4"
            (codeCompleted)="onCodeCompleted($event)">
          </code-input>
        </div>

        <!--      <div class="col-11 my-2 ">-->
        <!--        <p id="errorMsg" class="error my-1" *ngIf="this.validationErrors[fieldsConfig[1].name]">-->
        <!--          {{this.validationErrors[fieldsConfig[1].name]}}-->
        <!--        </p>-->
        <!--      </div>-->

      </div>


      <div class="row my-3">
        <div id="sendCodeAgain" (click)="sendCodeAgain()">
          <img (click)="sendCodeAgain()"
               src="../../../../assets/images/signup/send_again.svg"
               class="img-fluid button-pointer">
        </div>
        <p class="p-resend my-2 fw-bold" >Send code again</p>
      </div>
    </div>

  </div>

  <div *ngIf="isSuccess">

    <div class="row pt-1">
      <div class="col-1 p-0 d-flex justify-content-center align-items-center ">
        <img class="img-fluid " src="assets/images/zelle/zelle_icon.svg" >
      </div> <!-- Second Col ends -->

      <div class="col-10 d-flex align-items-center  ps-0">
        <p class="p-title mb-0 pb-0  ">Add New Contact Info</p>
      </div> <!-- Col ends -->

      <div class="col-1  p-0 d-flex justify-content-center align-items-center button-pointer">
        <img (click)="cancelAddContact()"
             src="assets/images/pay_tf/exit_button.svg" class="img-fluid" alt="exit_button">
      </div> <!-- Second Col ends -->

    </div> <!-- First Row ends -->

    <div class="row text-center">
        <div>
          <img src="assets/images/zelle/success_check.svg" alt="Check Success">
        </div>
        <div>
          <p class="p-success my-2">Success!</p>
          <p class="p-success-sm my-2">You have added a new way to contact you on Zelle</p>
        </div>
    </div>

    <div class="row d-flex justify-content-around my-3 px-1">
      <div class="col-md-6 col-12 my-2 " >
        <button class="yellow-button  rounded-3 w-100 py-3"
                (click)="addNewContact()" >Add New Contact</button>
      </div>
      <div class="col-md-6 col-12 my-2" >
        <button class="violet-button rounded-3 w-100 py-3"
                (click)="cancelAddContact()">Done</button>
      </div>
    </div>

  </div>


</div>
