import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-user-menu-daa',
  templateUrl: './user-menu-daa.component.html',
  styleUrls: ['./user-menu-daa.component.css']
})
export class UserMenuDaaComponent implements OnInit{

  daaPolicy !:string

  constructor(
    public dialogRef: MatDialogRef<UserMenuDaaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  closePolicy():void{
    this.dialogRef.close()
  }

  ngOnInit() {
    this.daaPolicy = this.data.policy
  }


}
