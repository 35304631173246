<div class="container-fluid h-100 ">

  <div class="d-block d-lg-none">
    <app-nav-bar-horizontal></app-nav-bar-horizontal>
  </div>
  <div class="row h-100 mb-0">

    <div class="col-lg-2 bg-white  d-none d-lg-block">
      <app-nav-bar></app-nav-bar>
    </div>

    <!-- Screen >100 rem  lrg-display-->
    <div class="col-lg-10 col-sm-12 p-0 h-100 mb-0 ">
      <div *ngIf="displayLarge">
        <div class="row">
<!--          <div class="col-1 "></div>-->
          <div class="col-12 min-vh-100 p-0">
            <router-outlet></router-outlet>
          </div>
<!--          <div class="col-1 "></div>-->
        </div >
      </div>
      <div *ngIf="!displayLarge">
        <router-outlet></router-outlet>
      </div>

<!--    </div> &lt;!&ndash;Close col-10&ndash;&gt;-->

<!--    &lt;!&ndash; Screen < 100 rem avg-display  &ndash;&gt;-->
<!--    <div class="col-lg-10 col-sm-12 p-0 ">-->
<!--      <router-outlet></router-outlet>-->
    </div> <!--Close col-10-->

  </div> <!--Close row-->

</div> <!--Close container-->

