<div class="container-fluid" >
  <div class="row my-5 text-center">
    <div>
      <img src="assets/images/alerts/warning_triangle.svg" class="img-fluid">
    </div>
  </div>
  <div class="row text-center px-2 my-2">
    <p class="p-wording">{{alertMsg}}</p>
  </div>
  <div class="row text-center my-2">
    <p class="p-text fw-bold my-2">Close this message and continue banking with {{brand}}.</p>
  </div>
  <div class="row mb-3">
    <div class=" col-12 text-center">
      <button id="btnClose" class="violet-button py-3 rounded-4 w-75 px-2" (click)="closeMessage()"> Close </button>
    </div>
  </div>
</div>
