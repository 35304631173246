<div class="container-fluid">

  <div class="row bg-color-clear rounded-3  px-1 ">
    <div class="col-6 text-center py-1 button-pointer" >
      <div class=" py-2 rounded-3 button-pointer my-0" (click)="changeClass()"
           [ngClass]="isRequest ? 'p-on' :'p-off' "
      >Request</div>
    </div>
    <div class="col-6 text-center py-1 button-pointer " (click)="changeClass()">
      <div class=" py-2 rounded-3 button-pointer my-0"
           [ngClass]="!isRequest ? 'p-on' :'p-off' ">Histo</div>
    </div>
  </div>

  <br>
  <div *ngIf="!isRequest" class="row bg-color-clear">
    <div class="m-0 p-0 w-100" [ngClass]="pending_high? 'scroll-requests-small':''">
      <div *ngFor="let req of requests_pending"  >
        <div class="row mx-2 py-2 r-underline">
          <div class="col-6">
            <div class="row">
              <div class="col-3  p-0 ">
                <img src="assets/images/paym-request/person_logo.svg" class="img-fluid ">
              </div>
              <div class="col-9  px-0 ">
                <p class="p-name mb-0 ">{{req.name}} {{req.surname}}</p>
                <p class="p-desc mb-0 ">{{req.description}}</p>
                <p class="p-date mb-0 ">{{req.date}}</p>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="row text-end align-items-center mt-3">
              <p class="p-amount mb-0 pe-2 ">${{req.amount}}</p>
            </div>
            <div class="row">
              <div class="col-6 px-0">
                <div class="btn-cancel rounded-3 text-center py-1 mx-1 button-pointer">Decline</div>
              </div>

              <div class="col-6 px-0">
                <div class="btn-ok rounded-3 text-center py-1 mx-1 button-pointer ">Approve</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isRequest" class="row ">

    <div *ngIf="!isPaymentsRequest">
      <div class="col-12 ">
        <div class="row ">
          <div class="form-floating  px-0">
            <input
              id="'inputFilter'"
              class = "form-control border-0 bg-color-clear"
              [placeholder]="'Search'"
              [type]="'string'"
              [ngModel]="search_word"
              (ngModelChange)="filterAlert($event)"
              style="background: #F5F5F2 url('assets/images/accounts/magnifier.svg') 95%  no-repeat"
            >
            <label class="form-label ms-2">Search</label>
          </div>
        </div>

        <div class="row">
          <div class="col-3 offset-3 d-flex justify-content-center my-2 px-1 ">
            <button class="text-center py-2 w-100 py-2 rounded-3 p-filter"
                    (click)="isFilter()"  id="btn_filter">
              <img src="assets/images/recipients/filter_recp_white.svg">
              Filters
            </button>
          </div>
          <div class=" col-6 d-flex justify-content-center my-2 px-1 ">
            <button class="violet-button text-center py-2 w-100  rounded-3"
                    (click)="addNewRequest()"  id="btn_add">
              + New Request
            </button>
          </div>
        </div>

        <br>
        <div class="row bg-color-clear" [ngClass]="histo_high? 'scroll-requests-histo-small':''">
          <div *ngFor="let reqHisto of requests_histo"   >
            <div class="row mx-2 py-2 r-underline">
              <div class="col-7">
                <div class="row">
                  <div class="col-3  p-0 ">
                    <img src="../../../../../assets/images/paym-request/person_logo.svg" class="img-fluid ">
                  </div>
                  <div class="col-9  px-0 ">
                    <p class="p-name mb-0 ">{{reqHisto.name}} {{reqHisto.surname}}</p>
                    <p class="p-desc mb-0 ">{{reqHisto.description}}</p>
                    <p class="p-date mb-0 ">{{reqHisto.date}}</p>
                  </div>
                </div>
              </div>
              <div class="col-5">
                <div class="row">
                  <div class="text-end">
                    <img *ngIf="reqHisto.channel =='Zelle'"
                         src="assets/images/paym-request/zelle_logo.svg" class="img-fluid ">
                    <img *ngIf="reqHisto.channel == brand"
                         [src]="'assets/images/paym-request/logo_' + brand + '.svg'" class="img-fluid ">
                  </div>
                </div>
                <div class="row text-end py-1">
                  <p class="p-amount mb-0 pe-1 ">${{reqHisto.amount}}</p>
                </div>
                <div class="row text-end mt-1">
                  <p *ngIf="reqHisto.status == 'Approved'" class="p-approved mb-0" >Approved</p>
                  <p *ngIf="reqHisto.status == 'Declined'" class="p-declined mb-0" >Declined</p>
                  <p *ngIf="reqHisto.status == 'Pending'" class="p-pending mb-0">Pending</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div *ngIf="isPaymentsRequest" class=" p-0">
      <app-pay-req-new-sm></app-pay-req-new-sm>
    </div>

  </div>


  <div class="d-lg-none d-block" style="height: 5rem;"></div>
  <div *ngIf="isFilterPaymentRequest">
    <app-modal-filter-tr>
      <app-pay-req-filter
        (closeFilter)="isFilter()"
        (filterRequests)="filterRequests($event)"
      ></app-pay-req-filter>
    </app-modal-filter-tr>
  </div>

</div>
