import {Component, Input} from '@angular/core';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-coupons-add-success',
  templateUrl: './coupons-add-success.component.html',
  styleUrls: ['./coupons-add-success.component.css']
})
export class CouponsAddSuccessComponent {

  @Input() activateMsg !:string

  brand :string = environment.brand
}
