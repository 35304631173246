<div class="container-fluid ">

  <div class="row">

    <div class="col-lg-3 col-12 my-2 ">
      <div>
        <div class="form-floating div-form" >
          <select class="form-select div-form"
                  id="selectedAccount"
                  [ngModel]="fieldValues[fieldsConfig[0].name]"
                  (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
          >
            <option id="selectedOption"
                    *ngFor="let option of accounts_data ; index as i "
                    [value] = option[0]
            >{{option[1]}}</option>
          </select>
          <label class="form-label">{{[fieldsConfig[0].displayName]}}</label>
        </div> <!--Close col -->
      </div>
      <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[0].name]">
        {{this.validationErrors[fieldsConfig[0].name]}}
      </p>
    </div>

    <div class="col-lg-3 col-12 my-2 ">
      <div>
        <div class="form-floating div-form" >
          <select class="form-select div-form"
                  id="selectedMonth"
                  [ngModel]="fieldValues[fieldsConfig[1].name]"
                  (ngModelChange)="updateFieldValue(fieldsConfig[1].name, $event)"
          >
            <option value="" [disabled] = true [selected]="true" >Select Month</option>
            <option [value] = 1 >January</option>
            <option [value] = 2 >February</option>
            <option [value] = 3 >March</option>
            <option [value] = 4 >April</option>
            <option [value] = 5 >May</option>
            <option [value] = 6 >June</option>
            <option [value] = 7 >July</option>
            <option [value] = 8 >August</option>
            <option [value] = 9 >September</option>
            <option [value] = 10 >October</option>
            <option [value] = 11 >November</option>
            <option [value] = 12 >December</option>
          </select>
          <label class="form-label">{{[fieldsConfig[1].displayName]}}</label>

        </div> <!--Close form -->
      </div>
      <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[1].name]">
        {{this.validationErrors[fieldsConfig[1].name]}}
      </p>
    </div>

    <div class="col-lg-3 col-12  my-2">
      <div>
        <div class="form-floating div-form" >
          <select class="form-select div-form"
                  id="selectedYear"
                  [ngModel]="fieldValues[fieldsConfig[2].name]"
                  (ngModelChange)="updateFieldValue(fieldsConfig[2].name, $event)"
          >
            <option value="" [disabled] = true [selected]="true" >Select Year</option>
            <option
              *ngFor="let yr of years"
              [value] = yr
            >{{yr}}</option>
          </select>
          <label class="form-label">{{[fieldsConfig[2].displayName]}}</label>

        </div> <!--Close form -->
      </div>
      <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[2].name]">
        {{this.validationErrors[fieldsConfig[2].name]}}
      </p>
    </div>

    <div class="col-lg-3  col-12   d-flex justify-content-center align-items-center my-2">
      <button class="yellow-button  w-100 py-3 rounded-4 button-pointer  "
              id="btnSubmit" (click)="selectStatement()">Submit Request</button>
    </div>

  </div>


  <div *ngIf="isRequested">
    <div>
      <p id="title_found" class="p-button my-2" *ngIf="statements_data">View your Statements:</p>
    </div>

    <div *ngIf="url_display=='Accounts'"  class="row my-2">
      <div class=" d-flex flex-wrap justify-content-start scroll-statements ">
        <div *ngFor="let stat of statements_data"  >
          <div class="bg-color-clear button-pointer px-3 my-1">
            <img id="imgFile" src="assets/images/accounts/statement.svg" class="d-flex">
            <p class="p-button mb-0" (click)="redirectToFile(stat[1])" >{{stat[0]}}</p>
          </div>
        </div>
      </div>
    </div>

    <div  *ngIf="url_display=='msgs-docs' && statements_data" [ngClass]="display_scroll? 'row scroll-statements-msgDocs' : 'row'">
      <div  *ngFor="let stat of statements_data" class="col-lg-3 col-md-4 col-12 px-1"  >
          <div class="my-2 button-pointer" (click)="redirectToFile(stat[1])">
            <div class="div-statement rounded-4 p-1 bg-color-clear d-flex px-2 py-2">
              <div class="mx-1 align-content-center">
                <img src="assets/images/accounts/statement.svg" class="img-fluid" id="stat-img">
              </div>
              <div class="mx-1">
                <p  class="p-statement mb-0 ">Statement</p>
                <p class="d-none d-xl-block p-date mb-0 my-1" id="stat-title" >{{'Period: ' + stat[0]}}</p>
                <p class="d-xl-none p-date mb-0 my-1" >{{stat[0]}}</p>
                <div class="d-none d-md-block ">
                  <div class="d-flex my-1">
                    <div class="d-flex mx-1 " (click)="redirectToFile(stat[1])" id="stat-view">
                      <img src="assets/images/accounts/statement_view.svg"  class="img-fluid">
                      <p class="p-btn mb-0 ms-1">view</p>
                    </div>
                    <div class="d-flex mx-1" id="stat-download">
                      <img src="assets/images/accounts/statement_download.svg" class="img-fluid">
                      <p class="p-btn mb-0 ms-1">download</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-md-none d-block w-100" >
                <div class="d-flex my-1 align-content-center justify-content-end ">
                  <div class="d-flex mx-1">
                    <img src="assets/images/accounts/statement_view.svg" class="img-fluid">
                    <p class="p-btn mb-0 ms-1">view</p>
                  </div>
                  <div class="d-flex mx-1">
                    <img src="assets/images/accounts/statement_download.svg" class="img-fluid">
                    <p class="p-btn mb-0 ms-1">download</p>
                  </div>
                </div>
              </div>
            </div> <!--close -->
          </div><!--close discount card-->
        </div> <!--close ngfor-->
    </div> <!--close row-->


    <div>
      <p id="title_notfound" class="p-display my-2" *ngIf="!statements_data">No statements found</p>
    </div>

  </div>


</div>
