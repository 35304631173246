import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Payroll, PayrollService} from "../../../../services/payroll.service";
import {environment} from "../../../../../environments/environment";
import {appearSlow} from "../../../Shared/animations";

@Component({
  selector: 'app-payroll-unenroll',
  templateUrl: './payroll-unenroll.component.html',
  styleUrls: ['./payroll-unenroll.component.css'],
  animations: [ appearSlow]
})
export class PayrollUnenrollComponent implements OnInit{
  brand :string = environment.brand
  isConfirmed !:boolean
  constructor(
    private router: Router,
    private payrollService: PayrollService
  ) {}

  cancelUnenrollment():void{
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    })
  }
  unrollUser ():void{
    this.payrollService.deletePayrollUser().subscribe({
      next:(res)=>{
        this.isConfirmed = true
      }
    })
  }


  ngOnInit() {
    this.isConfirmed = false
  }

}
