<div class="container-fluid p-0 h-100">

  <!--   Display Transaction List-->
    <div *ngIf="this.displayTransactions">

      <div class ="row" *ngIf="!isHome"  >
          <div class="col-sm-7 col-12 py-lg-2 py-3">
            <div class="form-floating ">
              <input  id="searchWord" class ='form-control inputStyle'
                     [placeholder]="'Search'"
                     [ngModel]="search_word"
                     (ngModelChange)="filterTransaction('search_word', $event)"
                     style="background: #F5F5F2 url('assets/images/accounts/magnifier.svg') 95%  no-repeat"
              >
              <label class="form-label">Search</label>
            </div>  <!--Close form -->
          </div> <!--Close col -->

        <div class="col-sm-2 col-12 py-lg-2 py-3">
          <button class="text-center py-3 w-100  rounded-4 p-filter" id="btn_filter"
                  (click)="filterTransactions(true)">
          <img src="../../../../../assets/images/recipients/filter_recp_white.svg">
          Filters
        </button>
        </div> <!--Close col -->

      </div>

      <!--Table displayed on large screens -->

      <div class="d-none d-sm-block">
        <div [ngClass]=" transactions_display.length > 4 ? 'scroll-transactions' : '' ">
          <table id="tableTransactions" class="table">
            <thead>
            <tr>
              <th>Transactions</th>
              <th>Description</th>
              <th>Status</th>
              <th class="text-end">Amount</th>
              <th class="text-end">Balance</th>
            </tr>
            </thead>
            <tbody  *ngFor="let tr of transactions_display">
            <tr id="transactions">
              <td class="td-when">
                {{getMonthName(tr.dt_when.split("-")[1])}} {{tr.dt_when.split("-")[2]}} -
                {{ tr.dt_when.split("-")[0]}}
              </td>
              <td class="td-desc">{{tr.desc}}</td>
              <td class="td-desc">{{tr.status}}</td>
              <td class="td-amount text-center"  (click)="disputeThisTransaction(tr)" >
                <button  class="span-btn rounded-4 text-end "
                         data-toggle="tooltip"
                         title="Dispute Transaction"
                > ${{tr.amount|number :'.2'}}
                </button>
              </td>
              <td id="balance" class="td-balance text-end  ">${{tr.account_balance|number :'.2'}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--Table displayed on small screens -->

      <div class=" d-sm-none ">

        <div class ="row" *ngIf="isHome"  >
          <div class="col-sm-7 col-12 py-lg-2 py-3">
            <div class="form-floating ">
              <input   class ='form-control inputStyle '
                      [placeholder]="'Search'"
                      [ngModel]="search_word"
                      (ngModelChange)="filterTransaction('search_word', $event)"
                      style="background: #F5F5F2 url('assets/images/accounts/magnifier.svg') 95%  no-repeat"
              >
              <label class="form-label">Search</label>
            </div>  <!--Close form -->
          </div> <!--Close col -->
        </div>

        <div [ngClass]=" transactions_display.length >=3  ? 'scroll-transactions'  : '' " >
          <div class="row responsive-tran  px-0" *ngFor="let tr of transactions_display">
            <div class="col-8 ">
                <p class="p-desc mb-0 ">{{tr.desc}}</p>
                <p class="p-date-sm mb-1">
                  {{getMonthName(tr.dt_when.split("-")[1])}} {{tr.dt_when.split("-")[2]}} -
                  {{ tr.dt_when.split("-")[0]}}
                </p>
            </div>
            <div class="col-4 text-end ps-0 ">
              <p *ngIf="getNumber(tr.amount)>=0" (click)="disputeThisTransaction(tr)" class="mb-0" >
                <button  class="p-amount-green text-end border-0 "
                         data-toggle="tooltip"
                         title="Dispute Transaction"
                > ${{tr.amount|number :'.2'}}
                </button>
              </p>
              <p *ngIf="getNumber(tr.amount)<0" (click)="disputeThisTransaction(tr)" class="mb-0" >
                <button  class="p-amount-red text-end border-0"
                         data-toggle="tooltip"
                         title="Dispute Transaction"
                > ${{tr.amount|number :'.2'}}
                </button>
              </p>
              <p class="p-amount-align text-muted pe-2 mb-0 ">{{tr.account_balance}}</p>
            </div>
          </div>
        </div>

      </div>

      <div class="border-top-solid d-sm-none d-md-none d-lg-block">
        <button id="buttonSeeAll1"  class=" button-toggle mb-0 pt-2" (click)="seeAllActivity()">{{buttonName}}</button>
      </div>

      <div class="d-none d-sm-block d-md-block d-lg-none ">
        <button id="buttonSeeAll" class=" button-toggle mb-0 pt-2" (click)="seeAllActivity()">{{buttonName}}</button>
      </div>

    </div>

  <!--   Display No Transaction found message-->
    <div *ngIf="!this.displayTransactions" >
      <div class ="row" *ngIf="!isHome"  >
        <div class="col-sm-7 col-12 py-lg-2 py-3">
          <div class="form-floating ">
            <input  id="searchWord_nt" class ='form-control inputStyle'
                    [placeholder]="'Search'"
                    [ngModel]="search_word"
                    (ngModelChange)="filterTransaction('search_word', $event)"
                    style="background: #F5F5F2 url('assets/images/accounts/magnifier.svg') 95%  no-repeat"
            >
            <label class="form-label">Search</label>
          </div>  <!--Close form -->
        </div> <!--Close col -->

        <div class="col-sm-2 col-12 py-lg-2 py-3">
          <button class="text-center py-3 w-100  rounded-4 p-filter" id="btn_filter_nt"
                  (click)="filterTransactions(true)">
            <img src="assets/images/recipients/filter_recp_white.svg">
            Filters
          </button>
        </div> <!--Close col -->

      </div>
      <div class="row" >
        <div class="  h-100 d-flex justify-content-center text-center align-items-center">
          <div>
            <div class="d-flex justify-content-center text-center mb-3">
              <img src="assets/images/transactions/dollar.svg">
            </div>
            <p id="emptyMsg1" class="p-b-nt" >No transactions found</p>
            <p id="emptyMsg2" class="p-b-nt-small">Start using your card and you will see the transactions displayed here</p>
          </div>
        </div>
      </div>

    </div>

  <div *ngIf="isFilterTransactions">
    <app-modal-filter-tr>
      <app-tr-filter
        (isFilterTransactions)="filterTransactions($event)"
        (filterData)="selectFilterTransactions($event)"
      ></app-tr-filter>
    </app-modal-filter-tr>
  </div>

</div>




