import {Component, OnInit} from '@angular/core';
import {SharedataService} from "../../../services/sharedata.service";
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {FormFieldConfig} from "../../Shared/forms-shared/typesForm";
import {environment} from "../../../../environments/environment";
import {ProfileService} from "../../../services/profile.service";
import {animate, style, transition, trigger} from "@angular/animations";
import {slideInLeft, slideInLower, slideInRight, slideInUpper} from "../../Shared/animations";

@Component({
  selector: 'app-login-device',
  templateUrl: './login-device.component.html',
  styleUrls: ['./login-device.component.css'],
  animations: [ slideInLeft, slideInLower, slideInRight, slideInUpper ],
})
export class LoginDeviceComponent implements OnInit{

  brand  :string =  environment.brand

  docsPrivatePolicy !:string
  docsPrivatePolicyCRB !:string

  user_id !:string

  checkCode :boolean = false
  fieldsConfig!: FormFieldConfig[]

  validateOn: string = 'change'
  fieldWasEdited: any = {}
  validationErrors : any = {}

  _fieldValues: any = {}

  get fieldValues() {
    return this._fieldValues
  }
  set fieldValues(newFieldValues: any) {
    this._fieldValues = newFieldValues
  }

  constructor(
    private shareService: SharedataService,
    private authService: AuthService,
    private router: Router,
    private profileService:ProfileService
  ) { }


  updateFieldValue(fieldName: string, newValue: any):void {
    this.fieldValues = {...this.fieldValues, [fieldName]: newValue}
  }

  setFieldToEdited(fieldName:string):void {
    this.fieldWasEdited[fieldName] = true
    if (this.validateOn === 'change') { // recalculate validation Errors if validation is to be performed on change
      this.validationErrors = this.getvalidationErrors(false)

    }
  }

  getvalidationErrors(isSubmit:boolean):void{
    let errors:any = {} // initialize to empty to erase past trials
    for (let field of this.fieldsConfig) { //loop through fields
      if(this.fieldWasEdited[field.name] || isSubmit){
        for (let validator of field.validators || []) { //loop through validation Fns
          const isValid = validator.checkFn(this.fieldValues[field.name])
          if (!isValid) {
            errors[field.name] = validator.errorMsg
            break
          }
        }
      }
    }
    return errors
  }

  registerDevice():void {
    this.validationErrors = this.getvalidationErrors(true)

    if(Object.keys(this.validationErrors).length > 0) {
      return
    }
    this.authService.activateDevice(this.fieldValues).subscribe({next:(res)=>{
      this.checkCode = true
      }})
  }

  redirectToWelcome():void {
    sessionStorage.clear()
    this.router.navigateByUrl('/')
  }

  getLegalTerms():void{
    this.profileService.getLegalDicsKYC().subscribe({
      next:(res)=>{
        this.docsPrivatePolicy = res.res[2].doc
        this.docsPrivatePolicyCRB = res.res[4].doc
      }})
  }

  redirectToFile(url:string){
    window.open(url, "_blank");
  }

  ngOnInit() {
    this.getLegalTerms()

   this.fieldsConfig = this.authService.formFieldsDevice

    for (let field of this.fieldsConfig)
      this.fieldValues[field.name] = ''

    this.shareService.getUserId().subscribe((value:string) =>{
      this.fieldValues['user_id'] = value
    })
  }

}
