import {Component, OnInit } from '@angular/core';
import {AccountService, MainAccount,} from "../../../services/account.service";
import {environment} from "../../../../environments/environment";
import { Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {ProfileService} from "../../../services/profile.service";
import {HomeService} from "../../../services/home.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {AlertsSocketComponent} from "../../MsgsDocs/Alerts/alerts-socket/alerts-socket.component";
import {AccountExtAddComponent} from "../../Accounts/account-ext-add/account-ext-add.component";
import {
  CouponsDiscountsMsgComponent
} from "../CouponsDiscounts/coupons-discounts-msg/coupons-discounts-msg.component";
import {appearSlow} from "../../Shared/animations";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [ appearSlow ],
})
export class HomeComponent implements OnInit{

  // Notifications websocket
  socket !: WebSocket
  spinningOn !:boolean
  wsMessage !:string


  your_route = 'Home'

  amount_high !: boolean
  logInEmail !:string
  isEmailVerified: string = 'true'
  main_account !: MainAccount
  displayDiscountsCoupons : boolean = false


  // Set default year and month for transactions
  d = new Date()
  home_default_year:number = this.d.getFullYear()
  home_default_month:number = this.d.getMonth()+1

  constructor(
    private accountService: AccountService,
    private router: Router,
    private auth: AuthService,
    private profileService: ProfileService,
    private homeService : HomeService,
    public dialog : MatDialog
  ) { }

  showCouponsDiscounts(data:boolean): void {
    this.displayDiscountsCoupons = data
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'full-30-dialog';
    this.dialog.open(CouponsDiscountsMsgComponent,dialogConfig)


  }

  addNewExternalAccount(data:boolean):void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { account_id :this.main_account.id }
    dialogConfig.panelClass = 'full-30-dialog';
    this.dialog.open(AccountExtAddComponent,dialogConfig)

  }

  requestEmailClose(data:string){
    this.isEmailVerified = data
  }

  // Get token requested by the websocket
  openSSE ():void{
    this.homeService.getWSToken().subscribe(
      (res) => { if (res.e_code == 1) {
          // Open websocket to receive notifications from the bank
          this.startSSE(res.Token)
        } else { return }
      });
  }

  // Open websocket
  startSSE(data:string):void{
    const websocketUrl = environment.wsUrl + '/notification/?Token='+data;
    this.socket = new WebSocket(websocketUrl);

    this.socket.onopen = () => { this.spinningOn = true };
    this.socket.onmessage = (event) => {

      this.wsMessage = JSON.parse(event.data).message

      if(JSON.parse(event.data).type !== 0 &&
        JSON.parse(event.data).additional_info.status !== "Fail"){

        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = { message :this.wsMessage }
        dialogConfig.panelClass = 'full-30-dialog';
        this.dialog.open(AlertsSocketComponent,dialogConfig)
      }

      if(JSON.parse(event.data).additional_info.status === "Fail"){
        this.closeSSE()
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = { message :this.wsMessage }
        dialogConfig.panelClass = 'full-30-dialog';
        this.dialog.open(AlertsSocketComponent,dialogConfig)
        // setTimeout(()=>{
          this.auth.logout()
        // localStorage.clear();
        sessionStorage.clear();
        this.router.navigateByUrl('login')
        //},3000) // CHECK
      }
    };}

  // Close websocket
  closeSSE() :void {
    this.socket.close(1000, "CLOSE SUCCESS")
    this.spinningOn = false
  }

  ngOnInit():void {
    this.accountService.getMainAccount().subscribe({ next:(res) =>{
        if(res.e_code === 1) {
          this.main_account = res.account
          this.amount_high =  Number(this.main_account.balance) >= 10000
          this.isEmailVerified = res.account.is_email_verified === 1 ? "true" : "false"
          this.logInEmail = res.account.email
          // Open SEE websocket
          this.openSSE()
        }}
    })
  }


}
