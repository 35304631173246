<div class="container-fluid vh-100 " [@appearSlow] >

  <app-nav-buttons
    [current_route]= your_route
  ></app-nav-buttons>

  <div class="row bg-color-clear mt-3 mx-3 my-sm-0 my-3 rounded-3 ">
    <div id="divMsgsNots" class="col-sm-3 col-6 button-pointer"
         (click)="chooseOption('alerts')">
      <p id="pAvailable" class="p-title w-100 mx-0 text-center py-2"
         [ngClass]="{'p-clicked' : current_option =='alerts'}" >Alerts & Notifications</p>
    </div>
    <div id="divStatDisc" class="col-sm-3 col-6 button-pointer"
         (click)="chooseOption('statements')">
      <p  id="pStatements" class="p-title w-100 mx-0 text-center py-2"
          [ngClass]="{'p-clicked' : current_option =='statements'}">Statements & Disclosures</p>
    </div>
    <div id="divTaxDocs" class="col-sm-3 col-6 button-pointer"
         (click)="chooseOption('documents')">
      <p id="pActive" class="p-title w-100 mx-0 text-center py-2"
         [ngClass]="{'p-clicked' : current_option =='documents'}">Tax Documents</p>
    </div>
    <div id="divReports" class="col-sm-3 col-6 button-pointer h-100"
         (click)="chooseOption('reports')">
      <p id="pDescription" class="p-title w-100 mx-0 text-center py-2"
         [ngClass]="{'p-clicked' : current_option =='reports'}"
      >Customer Reports</p>
    </div>
  </div>

  <div class="row bg-color-clear  mx-3 rounded-3 h-75  " >
    <div class=" col-12 h-100">

      <div *ngIf="current_option =='alerts'" >
        <app-alerts></app-alerts>
      </div> <!--Close row-->

      <div *ngIf="current_option =='statements'" >
        <app-acc-statements
        [url_display]="'msgs-docs'"
        ></app-acc-statements>
      </div> <!--Close row-->

    </div> <!--Close col-->
  </div>

  <div class="divider-from-nav d-lg-none d-block" style="height: 5rem;"></div>

</div>  <!--Close container-->
