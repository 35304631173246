import { Injectable } from '@angular/core';
import {ApiService} from "./api.service";
import {FormFieldConfig} from "../components/Shared/forms-shared/typesForm";
import {ishigherThan2020Validator, isNotEmptyValidator} from "../components/Shared/forms-shared/validators";


export interface TransferPayload {
  loan_id: string,
  year: number,
  month:number,
  type_in: string[],
  limit: number, // number of transactios to show
  period: number // in months
}
export interface CardTransferPayload {
  loan_id: string,
  card_id:string,
  type_in: string[],
  year: number,
  month:number,
  period: number // in months
  limit: number, // number of transactios to show
}
export interface Transaction {

  trans_id: string,
  type: string,
  dt_when: string,
  amount: string,
  status:string,
  account_balance:string,
  bucket_name:string,
  desc: string,
  month:number,
  year:number
}

export interface TransactionFilter {
  month:number,
  year:number,
  limit: number,
  period: number
}

export interface TransactionFilterExt extends TransactionFilter{
  loan_id: string,
  type_in: string[],
}

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  filterFormFields : FormFieldConfig[] = [
    {
      name: 'year',
      type: 'string',
      displayName: 'Select Year',
    },
    {
      name: 'month',
      type: 'string',
      displayName: 'Select Month',
    },
    {
      name: 'period',
      type: 'string',
      displayName: 'Select Period (months)',
    },
    {
      name: 'limit',
      type: 'number',
    },

  ]

  constructor(private api: ApiService) { }

  getTransactions(data: TransferPayload) {
    return this.api.post('/get_transfers/', data)
  }

  filterTransactions(data: TransactionFilterExt) {
    return this.api.post('/get_transfers/', data)
  }

  getCardTransactions(data: CardTransferPayload) {
    return  this.api.post('/get_card_transfers/', data)
  }

}
