<div class="container-fluid" [@appearSlow]>

  <div class="row">

    <div class="col-2  d-flex align-items-center justify-content-center" >
      <img  class="img-fluid"  src="assets/images/edit_profile/profile_img.svg" />
    </div>

    <div class="col-8 d-flex align-items-center px-0" >
      <p class="p-full-name m-0"  >{{profileData.first_name}} {{profileData.last_name}}</p>
    </div>

    <div class="col-2  d-flex align-items-center justify-content-center button-pointer">
      <img class="img-fluid" id="btnCancelDisplay" (click)="cancelDisplayProfile()"
           src="assets/images/edit_profile/cancel_edit.svg"/>
    </div>

  </div>

  <div class="row py-2" >
    <div class="col-9" >
      <p class="p-title-pi mb-0">Personal information</p>
    </div>
    <div class="col-2 text-center div-edit" >
      <p class="div-edit mb-0" id="btnEdit" (click)="goToEditProfile()">Edit</p>
    </div>
  </div>


  <div class="row" class="edit-padding">

    <div *ngFor="let field of fieldsConfig ; let idx = index " id="dataDisplay">

      <div *ngIf="field.name !='ssn'">
        <div class="form-floating py-1 " >
          <input
            id = "{{idx}}"
            [disabled]="true"
            [class] ="'form-control bg-color-clear '"
            [value] = "this.fieldValues[field.name]"
          >
          <label class="py-2">{{field.displayName}}</label>
        </div>
      </div>


    </div> <!-- Close ngIf -->

  </div> <!-- Close row -->
  <div class="d-lg-none d-block" style="height: 3rem;"></div>

</div> <!-- Close container -->


