import {Component, Inject, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AccountService, MainAccount} from "../../../../services/account.service";
import {SharedataService} from "../../../../services/sharedata.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-coupons-discounts-msg',
  templateUrl: './coupons-discounts-msg.component.html',
  styleUrls: ['./coupons-discounts-msg.component.css']
})
export class CouponsDiscountsMsgComponent implements OnInit{

  main_account !: MainAccount

  brand :string = environment.brand

  constructor(
    private router: Router,
    private accountService : AccountService,
    private shareService : SharedataService,

    public dialogRef: MatDialogRef<CouponsDiscountsMsgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any

  ) {}

  navigateFc():void{
    this.dialogRef.close()
    this.shareService.setMainAccount(this.main_account)
    this.router.navigateByUrl("/main/couponsDiscounts")
  }
  closeCoupons():void{
    this.dialogRef.close()
  }

  ngOnInit() {
    this.accountService.getMainAccount().subscribe({
      next:(res)=>{ this.main_account = res.account
      }
    })
  }

}
