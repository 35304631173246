import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {appearSlow} from "../../../../Shared/animations";
import {RecipientExtended} from "../../../../../services/recipients.service";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-pay-req-success',
  templateUrl: './pay-req-success.component.html',
  styleUrls: ['./pay-req-success.component.css'],
  animations: [ appearSlow ],
})
export class PayReqSuccessComponent implements OnInit{

  brand : string = environment.brand

  @Input() totalAmount !:number
  @Input() amountObject !:any
  @Input() recipients_selected !:RecipientExtended[]

  @Output() newRequest = new  EventEmitter<boolean>()
  display_selected_all !:boolean

  startNewRequest():void{
    this.newRequest.emit(false)
  }
  ngOnInit() {
    this.display_selected_all = this.recipients_selected.length > 3 ? true : false
  }
}
