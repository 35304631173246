<div class="container-fluid"  [ngClass]="!isLargeScreen ? 'scroll-new-rec': ''">

  <div class="row  d-md-none p-2  ">

    <div class="col-1 p-0 d-flex justify-content-around" >
      <img class="img-fluid" src="assets/images/zelle/zelle_icon.svg"/>
    </div >

    <div class="col-10  d-flex align-items-center   "  >
      <p class="p-title mb-0 ">Add Zelle® Recipient</p>
    </div>

    <div class="col-1 p-0 d-flex justify-content-around button-pointer" >
      <img (click)="cancelNewRec()" class="img-fluid " src="assets/images/edit_profile/cancel_edit.svg"/>
    </div>

  </div>

  <div class="d-none d-md-block">
    <br>
    <br>
    <div class="row text-center">
      <p class="div-gi-lg ps-3" >Add Zelle® Recipient</p>
    </div>
  </div>

  <!--  this.add_new_rec == true-->
  <div *ngIf="this.add_new_rec" >

    <div class="row" >
      <div class="col-4 offset-4">
        <div class="text-center my-1 ">
          <img src="assets/images/recipients/new_picture.svg" class="img-fluid">
        </div>
      </div>
    </div>

    <div class="row  my-3 ">
      <div class="col-lg-4 offset-lg-4 col-12 text-center" >
        <div >
          <input id="inputImage" class = "file-input" type="file"
                 (change)="handleFileInput($event)"
          >
        </div>
      </div>
    </div>

    <div class="row" >

      <div class="row text-center px-2 my-3 ">
        <div class=" offset-md-1 col-md-10 col-12 div-gi-sm " >Only include people you know and make sure theor contact info is
          updated, since you will not be able to cancel a payment.</div>
      </div>

      <div class="col-sm-5 offset-sm-1 col-12 my-3 " >

        <div class="form-floating" >
          <input
            [id]="'inputFName'"
            [class] ="validationErrors[fieldsConfig[0].name]? 'form-control formError': 'form-control'"
            [placeholder]="fieldsConfig[0].placeHolder"
            [type]="fieldsConfig[0].type"
            [ngModel]="fieldValues[fieldsConfig[0].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[0].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[0].name)"
          >
          <label class="form-label  ">{{fieldsConfig[0].displayName}}</label>
        </div>
        <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[0].name]">
          {{this.validationErrors[fieldsConfig[0].name]}}
        </p>
      </div>

      <div class="col-sm-5  col-12 my-3">
        <div class="form-floating">
          <input
            [id]="'inputLName'"
            [class] ="validationErrors[fieldsConfig[1].name]? 'form-control formError': 'form-control'"
            [placeholder]="fieldsConfig[1].placeHolder"
            [type]="fieldsConfig[1].type"
            [ngModel]="fieldValues[fieldsConfig[1].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[1].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[1].name)"
          >
          <label class="form-label ">{{fieldsConfig[1].displayName}}</label>
        </div>
        <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[1].name]">
          {{this.validationErrors[fieldsConfig[1].name]}}
        </p>
      </div>

      <div class="row  px-2 my-3 ">
        <div class=" offset-md-1 col-md-10 col-12  text-center">
          <p class="div-gi-sm">Provide at least one way to contact your recipient.</p>
        </div>
      </div>

      <div class="col-sm-5 offset-sm-1 col-12 my-3 " >

        <div class="form-floating" >
          <input
            [id]="'inputEmail'"
            [class] ="validationErrors[fieldsConfig[2].name]? 'form-control formError': 'form-control'"
            [placeholder]="fieldsConfig[2].placeHolder"
            [type]="fieldsConfig[2].type"
            [ngModel]="fieldValues[fieldsConfig[2].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[2].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[2].name)"
          >
          <label class="form-label  ">{{fieldsConfig[2].displayName}}</label>
        </div>
        <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[2].name]">
          {{this.validationErrors[fieldsConfig[2].name]}}
        </p>
      </div>

      <div class="col-sm-5 col-12 my-3">
        <div class="form-floating">
          <input
            [id]="'inputPhone'"
            [class] ="validationErrors[fieldsConfig[3].name]? 'form-control formError': 'form-control'"
            [placeholder]="fieldsConfig[3].placeHolder"
            [type]="fieldsConfig[3].type"
            [ngModel]="fieldValues[fieldsConfig[3].name]"
            (ngModelChange)="updateFieldValue(fieldsConfig[3].name, $event)"
            (blur)="setFieldToEdited(fieldsConfig[3].name)"
          >
          <label class="form-label ">{{fieldsConfig[3].displayName}}</label>
        </div>
        <p  class="error my-1" *ngIf="this.validationErrors[fieldsConfig[3].name]">
          {{this.validationErrors[fieldsConfig[3].name]}}
        </p>
      </div>

    </div>

    <br>
    <div class="row py-3" >
      <div class="col-md-4 offset-md-2 d-flex justify-content-center" >
        <button id="btnCancel"  class="yellow-button w-100 rounded-4 px-2 py-3 my-3  "
                (click)="cancelNewRec()">Cancel</button>
      </div>

      <div class="col-md-4  d-flex justify-content-center" >
        <button id="btnSubmit"  class="violet-button w-100 rounded-4 px-2 py-3 my-3  "
                (click)="submitNewRecipient()"> Save</button>
      </div>
    </div>

  </div>

  <!--  this.add_new_rec == false-->
  <div *ngIf="!this.add_new_rec">

    <div class="row text-center">
        <div class="my-3">
          <img src="assets/images/recipients/success_recipient.svg"
               class="img-fluid">
        </div>

        <div class="my-3">
          <p class="p-message">Success!</p>
        </div>

      </div>

    <div class="row  px-2 my-3 ">
        <div class=" offset-md-1 col-md-10 col-12  text-center">
          <p class="div-gi-sm">The new Zelle recipient has been included.</p>
        </div>
      </div>

    <div class="row" >

        <div class="col-sm-8 offset-sm-2 col-12 my-3" >
          <div class="d-flex justify-content-between py-3 input-summary rounded-3">
            <p class=" mb-0 label-new-recip ps-2">{{fieldsConfig[0].displayName}}</p>
            <p class="mb-0 input-new-recip pe-2" >{{fieldValues[fieldsConfig[0].name]}}</p>
          </div>
        </div>

        <div class="col-sm-8 offset-sm-2  col-12 my-3">
          <div class="d-flex justify-content-between py-3 input-summary rounded-3">
            <p class=" mb-0 label-new-recip ps-2">{{fieldsConfig[1].displayName}}</p>
            <p class="mb-0 input-new-recip pe-2" >{{fieldValues[fieldsConfig[1].name]}}</p>
          </div>
        </div>


        <div class="col-sm-8 offset-sm-2 col-12 my-3 " >
          <div class="d-flex justify-content-between py-3 input-summary rounded-3">
            <p class=" mb-0 label-new-recip ps-2">{{fieldsConfig[2].displayName}}</p>
            <p class="mb-0 input-new-recip pe-2" >{{fieldValues[fieldsConfig[2].name]}}</p>
          </div>
        </div>

        <div class="col-sm-8 offset-sm-2 col-12 my-3">
          <div class="d-flex justify-content-between py-3 input-summary rounded-3">
            <p class=" mb-0 label-new-recip ps-2">{{fieldsConfig[3].displayName}}</p>
            <p class="mb-0 input-new-recip pe-2" >{{fieldValues[fieldsConfig[3].name]}}</p>
          </div>
        </div>

      </div>

    <br>
    <div class="row py-3" >
      <div class="col-md-4 offset-md-2 d-flex justify-content-center" >
        <button id="btnClose"  class="yellow-button w-100 rounded-4 px-2 py-3 my-3  "
                (click)="cancelNewRec()">Close</button>
      </div>

      <div class="col-md-4  d-flex justify-content-center" >
        <button id="btnContinue"  class="violet-button w-100 rounded-4 px-2 py-3 my-3  "
                (click)="backToPayments()">Continue</button>
      </div>
    </div>


  </div>

</div> <!--Close container-->

