<div class="container-fluid bg-color-clear ">

  <div class="row" *ngIf="!successNewRequest">

    <div class="col-md-8 px-md-3 col-12  my-2">

      <div class="row">
        <div class="form-floating me-3 my-1" >
          <input
            class="form-control me-2"
            type="string"
            style="background: #F5F5F2 url('assets/images/accounts/magnifier.svg') 95%  no-repeat "
            [placeholder]="'Search'"
            [ngModel]="search_word"
            (ngModelChange)="filterRecipient($event)"
          >
          <label class="form-label ms-2">Search</label>
          <ul class="ps-0 rounded-1" *ngIf="recipients_display" >
            <div *ngFor="let rec of recipients_display">
              <li class="py-2 ps-1 d-flex ">
                <div class="row w-100">
                  <div class="col-11 d-flex">
                    <img *ngIf="rec.zelle == true" [src]="'assets/images/paym-request/zelle_logo.svg'" class="img-fluid ">
                    <p *ngIf="rec.zelle == true " class="mb-0 ms-3 align-content-center">{{rec.first_name}} {{rec.last_name}} </p>

                    <img *ngIf="rec.kredio == true " [src]="'assets/images/paym-request/logo_' + brand + '.svg'" class="img-fluid ">
                    <p *ngIf="rec.kredio == true " class="mb-0 ms-2 align-content-center">{{rec.first_name}} {{rec.last_name}} </p>
                  </div>
                  <div class="col-1 text-center btn-add button-pointer">
                    <p class=" mb-0 button-pointer me-3 "
                       (click)="addRecipient(rec.id, rec)">Add</p>
                  </div>
                </div>
              </li>
            </div>
          </ul>
          <div class="p-subtitle-small my-2">You can request multiple payments from up to 15 people.</div>
        </div>
      </div>

      <div class="row my-2 px-2" >

        <div *ngIf="display_selected">

          <div class="p-subtitle my-1">Selected Recipients</div>

          <div [ngClass]=" display_selected_all ? 'scroll-requests px-2': 'px-2'">
            <div *ngFor="let recs of recipients_selected"    >
              <div class="row row_selected ">
                <div class="col-1 text-center align-content-center p-0">
                  <div class="p-0 button-pointer" (click)="removeRecipient(recs.id)">
                    <img *ngIf="recs.zelle == true" [src]="'assets/images/paym-request/zelle_logo.svg'" class="img-fluid">
                    <img *ngIf="recs.kredio == true " [src]="'assets/images/paym-request/logo_' + brand + '.svg'" class="img-fluid">
                  </div>
                </div>
                <div class="col-8 d-flex">
                  <p *ngIf="recs.zelle == true " class="mb-0 align-content-center fw-bold">{{recs.first_name}} {{recs.last_name}} </p>

                  <p *ngIf="recs.kredio == true " class="mb-0 align-content-center fw-bold">{{recs.first_name}} {{recs.last_name}} </p>

                  <p *ngIf="recs.email" class="mb-0 ms-2 align-content-center ">{{recs.email}}</p>

                  <p *ngIf="!recs.email" class="mb-0 ms-2 align-content-center ">{{recs.phone}}</p>
                </div>
                <div class="col-3 text-center btn-add button-pointer">
                  <div class="form-floating  my-1" >
                    <input
                      class="form-control input-selected ms-2 text-end"
                      type="string"
                      [placeholder]="'Amount'"
                      [ngModel]="select_amount"
                      (ngModelChange)="updateRecipientAmount(recs.id , $event)"
                    >
                    <label class="form-label ms-2">Amount</label>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div class="row  my-3 pe-2">
            <div class="col-6 align-content-center">
              <div class="form-floating me-3 my-1" >
                <input
                  class="form-control input-memo "
                  type="string"
                  [placeholder]="'Memo'"
                  [ngModel]="select_memo"
                  (ngModelChange)="addMemo($event)"
                >
                <label class="form-label input-memo-label ms-2">Memo (Optional)</label>
              </div>
            </div>
            <div class="col-6 text-end">
              <p class="p-totAmount mb-0 h-100 align-content-center">Total Amount
                <span class="p-totAmountNum">
             ${{ this.totalAmount|number :'.2'}}</span></p>
            </div>

          </div>

          <br>
          <div class="row">
            <div class="col-6 offset-3">
              <button class="violet-button rounded-4 w-100 py-3"
                      (click)="sendRequest()" id="btnSend"
              ><img src="../../../../../../assets/images/paym-request/send-request.svg" class="img-fluid pe-2">
                Send Request</button>
            </div>
          </div>
        </div>

        <div *ngIf="!display_selected " class="text-center">
          <br>
          <br>
          <p class="p-no-select">No recipients have been selected yet.</p>
          <p class="p-no-select" >Add a recipient by searching above.</p>
          <br>
          <br>
        </div>

      </div>

    </div><!--Close col -->

    <div class="d-none d-md-block col-md-4 px-md-3  my-2">

      <div class="p-subtitle my-1">Frequent Recipients</div>

      <div class="row mt-1">
        <div class="card h-100 border-0 shadow-sm" >
          <div class="card-body">
            <div class="  h-100">
              <app-pay-req-feqrecips></app-pay-req-feqrecips>
            </div>
          </div> <!--Close card body-->
          <div class="card-footer card-footer-div border-top-solid bg-color-clear">
            <div class=" d-flex justify-content-center align-items-end" >
              <button routerLink="/main/accounts/recipients"
                      class="btn-manage py-1 bg-color-clear " >Manage Recipients</button>
            </div>
          </div>
        </div> <!--Close card-->
      </div>

      <br>
      <br>
      <div class="p-subtitle my-1">More ways to request money</div>
      <br>
      <div class="row rounded-4 box-borders hovering-box  w-75">
        <div class="row">
              <div class="col-2  d-flex align-items-center justify-content-center px-0"
                   (click)="useZelle()">
                <img class="img-fluid w-75"
                     src="assets/images/pay_tf/zellePic.svg" alt="Zelle">
              </div>
              <div class="col-10" (click)="useZelle()">
                <div class="my-1">
                  <p class="box-text my-0">Send money using <img src="assets/images/pay_tf/zelle_letters.svg" alt=""></p>
                  <p class="sub-text my-0">Send money to others using their phone number or email</p>
                </div>
              </div>
            </div>
      </div>
      <br>

    </div>

  </div>

  <div class="row" *ngIf="successNewRequest">
    <app-pay-req-success
      [totalAmount] = totalAmount
      [amountObject] = amountObject
      [recipients_selected] = recipients_selected
      (newRequest)="startNewRequest($event)"
    ></app-pay-req-success>
  </div>

</div>
