import {Component, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {HomeComponent} from "../../Home/home/home.component";
import {AccountsComponent} from "../../Accounts/accounts/accounts.component";
import {window} from "rxjs";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent {
  brand_img  :string = 'assets/images/bank_imgs/logo_blue_' + environment.brand + '.svg'
  brand  :string =  environment.brand

}
