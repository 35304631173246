<div class="container-fluid h-100"  [@appearSlow]>

  <div class="row">

    <div class="col-lg-2 col-2 p-0"   >
      <div class="d-flex justify-content-around ">
        <img class="img-fluid" src="assets/images/pay_tf/request_kredio.svg" >
      </div>
    </div> <!-- Second Col ends -->

    <div class="col-lg-8 col-8 p-0 d-flex align-items-center " >
      <p class="p-title mb-0 ms-1">Filter Payments</p>
    </div> <!-- Col ends -->

    <div class="col-lg-2 col-2 p-0"  >
      <div class="d-flex justify-content-around button-pointer">
        <img (click)="cancel()"
             src="assets/images/pay_tf/exit_button.svg" class="img-fluid" alt="exit_button">
      </div>
    </div> <!-- Second Col ends -->

  </div> <!-- First Row ends -->

  <div class="row px-2 my-4">
    <div class="col-2 d-flex justify-content-end">
              <span [ngClass]="this.filterObject['Zelle'] ? 'termsSelected mx-1': 'termsSelect mx-1' "
                    (click)="filter('Zelle', !this.filterObject['Zelle'] )"></span>
    </div>
    <div class="col-10 px-0 ">
      <p class="p-under mb-0">Zelle</p>
    </div>
  </div>

  <div class="row px-2 my-4">
    <div class="col-2 d-flex justify-content-end">
              <span [ngClass]=" this.filterObject['isBank'] ? 'termsSelected mx-1': 'termsSelect mx-1' "
                    (click)="filter('isBank', !this.filterObject['isBank'] )" ></span>
    </div>
    <div class="col-10 px-0 ">
      <p class="p-under mb-0">{{brand}}</p>
    </div>
  </div>

</div>
