import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Recipient, RecipientExtended, RecipientsService} from "../../../services/recipients.service";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {RecipientDetailsComponent} from "../recipient-details/recipient-details.component";
import {RecipAddNewComponent} from "../recip-add-new/recip-add-new.component";
import {RecipEditComponent} from "../recip-edit/recip-edit.component";
import {animate, style, transition, trigger} from "@angular/animations";
import {appearSlow} from "../../Shared/animations";

@Component({
  selector: 'app-recipients',
  templateUrl: './recipients.component.html',
  styleUrls: ['./recipients.component.css'],
  animations: [ appearSlow ],
})
export class RecipientsComponent implements OnInit{

  your_route = 'Recipient List'
  recipients: RecipientExtended[] = []
  recipients_display: RecipientExtended[] = []
  pictures_display !: string[][]

  @ViewChild('overlay') overlay !: ElementRef;

  buttonName: string = "See Recipients"

  search_word !:string

  constructor(
    private recipientsService: RecipientsService,
    public dialog : MatDialog
  ) {}

  filterRecipients(newValue:any):void{

    if(newValue){

      const listName = this.recipients.filter(rec=>rec.first_name.toString().toLowerCase().includes(newValue.toString().toLowerCase()))
      const listNameEmails = listName.map(rec => rec.email)

      const listLastName = this.recipients.filter(rec=>rec.last_name.toString().toLowerCase().includes(newValue.toString().toLowerCase()))
      const listLastNameEmails = listLastName.map(rec => rec.email)

      const allEmails = [...listNameEmails,  ...listLastNameEmails]
      const uniqueEmails = [...new Set(allEmails)]

      this.recipients_display = this.recipients.filter(rec => uniqueEmails.includes(rec.email))

    } else {
      this.recipients_display = (this.recipients.length<=3 ? this.recipients :this.recipients.slice(0,3))
    }

  }

  initialRecipients():void {
    this.overlay.nativeElement.classList.remove("scroll-recipients")
    this.overlay.nativeElement.classList.add("scroll-recipients-small")
  }
  //
  allRecipients():void {
    this.overlay.nativeElement.classList.remove("scroll-recipients-small")
    this.overlay.nativeElement.classList.add("scroll-recipients")
  }

  seeAllRecipients(name: string):void{
    if (name === "See Recipients") {
      this.recipientsService.getRecipients().subscribe({ next:(res)=>{
            this.recipients_display = res.recipients
            this.buttonName = "Hide Recipients"
            this.allRecipients()
          }
        })
    } else if (name === "Hide Recipients") {

      this.recipientsService.getRecipients().subscribe({
          next:(res)=>{
            this.recipients = res.recipients
            this.recipients_display = (this.recipients.length<=3 ?
              this.recipients :this.recipients.slice(0,3))
            this.buttonName = "See Recipients"
            this.initialRecipients()
          }
        })
    }
  }

  addNewRecipient():void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'full-50-dialog';
    this.dialog.open(RecipAddNewComponent,dialogConfig)
  }

  showRecipientDetails(data:string):void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { id: data }
    dialogConfig.panelClass = 'full-40-dialog';
    this.dialog.open(RecipientDetailsComponent,dialogConfig)
  }

  editRecipient(data:Recipient):void{
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = { recipient: data }
    dialogConfig.panelClass = 'full-40-dialog';
    this.dialog.open(RecipEditComponent,dialogConfig)
  }

  ngOnInit() {
    this.recipientsService.getRecipients().subscribe({ next:(res)=>{
        if(res.e_code ==1) {
          this.recipients = res.recipients
          this.recipients_display = (this.recipients.length<=3 ? this.recipients :this.recipients.slice(0,3))
          this.pictures_display = res.recipients.map((rec:RecipientExtended)=>
            [rec.first_name, rec.last_name, rec.img_url, rec.id])
          this.initialRecipients()
        }}
    })}
}
