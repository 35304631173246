<div class="container-fluid h-100" [@slideInLeft] >

      <div>
        <app-nav-buttons
          [current_route]= your_route
        ></app-nav-buttons>

        <!-- First narrow bar: Account, Cash Back, Live Payroll -->
        <div class="row my-3 px-3 " >

          <div class=" col-12 p-0 my-lg-0 my-2">
            <div class="card h-100  ms-lg-2 me-lg-3  rounded-3 border-0 bg-color-clear">
              <div class="card-body" >

                <div class="row ">
                  <p class="text-bank-hint color-bank ">Next 30 days</p>
                </div>

                <app-rec-pay-month></app-rec-pay-month>

              </div> <!--Close card body-->
            </div> <!--Close card-->
          </div> <!--Close col-->



        </div> <!--Close row-->

        <!--Second row: Display accounts-->
        <div class="row my-3 px-3 " >

          <div class=" col-12 p-0 my-lg-0 my-2">
            <div class="card h-100  ms-lg-2 me-lg-3  rounded-3 border-0 bg-color-clear">
              <div class="card-body" >

                <div class="row ">
                  <p class="text-bank-hint color-bank ">All payments</p>
                </div>

                <div class="row ">

                  <div class="row col-lg-6 col-12 my-2">
                    <div class="form-floating w-100">
                      <input
                        id="inputSearch"
                        class ="form-control"
                        placeholder="Search"
                        type="string"
                        [ngModel]="search_word"
                        (ngModelChange)="filterRecPayments( $event)"
                        style="background: #F5F5F2 url('assets/images/accounts/magnifier.svg') 95%  no-repeat"
                      >
                      <label class="form-label ms-2">Search</label>
                    </div>
                  </div>

                  <div class="row col-lg-3 col-12 d-flex justify-content-center my-2 px-4 ">
                    <button class="text-center py-3 w-100  rounded-4 p-filter">
                      <img src="assets/images/recipients/filter_recp_white.svg">
                      Filters
                    </button>
                  </div>

                  <div class="row col-lg-3 col-12 d-flex justify-content-center my-2 px-4">
                    <button id="btnAddRP" class="submit-button py-3 rounded-4 w-100" (click)="addRecPayments()"
                    > + Schedule a Payment</button>
                  </div>

                </div>

                <div class="row my-2">
                  <div class="scroll-recpayments">
                    <!-- Responsive html format: large screens  -->
                    <div class="d-none d-lg-block">
                      <table class="table ">
                        <tbody *ngFor="let rec of rec_items ; index as i">
                        <tr >
                          <td class="text-center align-content-center">
                            <p id="p_number" class="td-name mb-0">{{this.brand}} {{rec.number.toString().slice(-4)}}</p>
                            <p id="p_routnumber" class="td-routeNum mb-0 ">Routing No. {{rec.routing}}</p>
                          </td>
                          <td class="text-center align-content-center">
                            <img src="assets/images/rec-payments/arrow.svg" class="img-fluid">
                          </td>
                          <td class="text-center align-content-center">
                            <p id="p_recipient" class="td-name mb-0">{{rec.recipient}}</p>
                            <p id="p_network" class="td-routeNum mb-0 ">{{rec.network}}</p>
                          </td>
                          <td id="p_memo" class="td-memo text-center align-content-center">{{rec.memo}}</td>
                          <td id="p_amount" class="td-amount text-center align-content-center">${{rec.amount|number :'.2'}}</td>
                          <td id="p_freq" class="td-memo text-center align-content-center">{{rec.frequency}}</td>
                          <td id="p_endDate" class="td-memo text-center align-content-center">{{ rec.end_date}}</td>
                          <td class="text-center align-content-center" >
                            <p id="p_Active" *ngIf="rec.is_active" class="tr-active mb-0" >Active</p>
                            <p id="p_Ended" *ngIf="!rec.is_active" class="tr-active mb-0" >Ended</p>
                          </td>

<!--                          <td>-->
<!--                            <button id="btnEdit" type="button" class="btn btn-default btn-sm">-->
<!--                              <img src="assets/images/rec-payments/edit_button.svg">-->
<!--                            </button>-->
<!--                          </td>-->
                          <td>
                            <button id="btnTrash" type="button" class="btn btn-default btn-sm bg-color-clear" (click)="showDeletePayment(rec)">
                              <img src="assets/images/rec-payments/trash_button.svg">
                            </button>
                          </td>

                        </tr>
                        </tbody>

                      </table>
                    </div>

                  <!-- Responsive html format: small screens-->
                    <div  class=" d-lg-none" >
                      <div *ngFor="let tr of rec_items" class="mt-2 mb-3">

                        <div class="row">
                          <div class="col-5">
                            <div class="text-center">
                              <p class="td-name mb-0">{{this.brand}} {{tr.number.toString().slice(-4)}}</p>
                              <p class="td-routeNum mb-0 ">Routing No. {{tr.routing}}</p>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="text-center"><img src="assets/images/rec-payments/arrow.svg" class="img-fluid"></div>
                          </div>
                          <div class="col-5">
                            <div class="text-center">
                              <p class="td-name mb-0">{{tr.recipient}}</p>
                              <p class="td-routeNum mb-0 ">{{tr.network}}</p>
                            </div>
                          </div>
                        </div>

                        <div class="row my-2">

                          <div class="col-5 p-0">
                            <p class="td-memo-resp  mb-0 text-center">{{tr.memo}}</p>
                          </div>
                          <div class="col-4 p-0">
                            <p class="td-amount-resp text-center mb-0 ">${{tr.amount|number :'.2'}}</p>
                          </div>
                          <div class="col-3 p-0">
                            <p class="td-memo-resp text-center mb-0 ">{{tr.frequency}}</p>
                          </div>
<!--                          <div class="col-4 p-0">-->
<!--                            <p class="td-memo-resp text-center mb-0">{{tr.end_date}}</p>-->
<!--                          </div>-->

                        </div>

                        <div class="row borderBottom ">
                          <div class="col-10  d-flex align-items-center ">
                            <div class="text-center">
                              <p *ngIf="tr.is_active" class="tr-active mb-0 " >Active</p>
                              <p *ngIf="!tr.is_active" class="tr-active mb-0" >Ended</p>
                              <p class="td-memo-resp text-center mb-0">{{tr.end_date}}</p>
                            </div>
                          </div>

<!--                          <div class="col-1  d-flex justify-content-center">-->
<!--                            <button type="button" class="btn btn-default btn-sm">-->
<!--                              <img src="assets/images/rec-payments/edit_button.svg">-->
<!--                            </button>-->
<!--                          </div>-->

                          <div class="col-2  ">
                            <button type="button" class="btn btn-default btn-sm " (click)="showDeletePayment(tr)">
                              <img src="assets/images/rec-payments/trash_button.svg">
                            </button>
                          </div>

                        </div>

                      </div>
                    </div>

                  </div>
                </div>


              </div> <!--Close card body-->
            </div> <!--Close card-->
          </div> <!--Close col-->

        </div> <!--Close row-->

        <!-- Third row bar: Transaction List -->

      </div>

<!--    </div> &lt;!&ndash;Close col-10&ndash;&gt;-->

    <div class="d-lg-none d-block" style="height: 5rem;"></div>

<!--  </div>-->

</div>  <!--Close container h-100-->


