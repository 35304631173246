import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {Router} from "@angular/router";
import {FormFieldConfig} from "../components/Shared/forms-shared/typesForm";
import {
  addressValidator,
  checkLengthChrValidator, emailValidator, isMinAgeValidator, isMinExpDateValidator,
  isNotEmptyValidator, isPastDateValidator, isPositiveOrEmptyValidator, isTermAcceptedValidator, sateValidator,
  ssnValidator, statesUSA, usaPhoneValidator, zipCodeValidator
} from "../components/Shared/forms-shared/validators";
import {Observable} from "rxjs";


export interface ErrorProfile  {
  e_code: number,
  e_msg: string
}

export interface Profile {
  user_id: string,
  first_name: string,
  last_name: string,
  dob: string,
  street: string,
  apt:string,
  zipcode: string,
  state:string,
  city: string,
  ssn: string,
  id_type: number,
  id_number :string,
  id_issued_date: string,
  id_exp_date: string,
  id_verified_date: string,
  id_issuing_state: string,
  expected_no_transactions:number,
  transaction_size:number,
  cross_border_activity:number

}

export interface ProfileLegal {
  deposit_account_agreement: number,
  esig_disclosure: number,
  privacy_policy_kredio: number,
  privacy_policy_crb: number,
  patriot_act: number,
  user_id: string,
}

export interface ProfileUpdateUser {
  first_name: string,
  last_name: string,
  dob: string,
  id_type: number,
  id_number :string,
  id_issued_date: string,
  id_exp_date: string,
  id_verified_date: string,
  id_issuing_state: string

}

export interface ProfileAddress {
  street: string,
  apt:string,
  zipcode: string,
  state:string,
  city: string,
}

export interface ProfileEdit {
  apt:string,
  city: string,
  dob: string,
  email: string,
  email_id: string,
  first_name: string,
  id_exp_date: string,
  id_issued_date: string,
  id_issuing_state: string,
  id_number: string,
  id_type: number,
  id_verified_date: string,
  last_name: string,
  phone: string,
  state: string,
  street: string,
  zipcode: string,
}

export interface ProfileEditPhone {
  phone_number:string
}

export interface ProfileEditEmail {
  email:string
}

export interface ActivatePhoneAuth {
  phone_id:string,
  code:number
}
@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  formFields: FormFieldConfig[] = [
    {
      name: 'first_name',
      displayName: 'First Name',
      type: 'string',
      placeHolder: 'First Name',
      validators: [isNotEmptyValidator, checkLengthChrValidator(2),]
    },
    {
      name: 'last_name',
      displayName: 'Last Name',
      type: 'string',
      placeHolder: 'Last Name',
      validators: [isNotEmptyValidator, checkLengthChrValidator(2)]
    },
    {
      name: 'dob',
      displayName: 'Birthdate',
      type: 'string',
      placeHolder: 'Date of Birth',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'street',
      displayName: 'Street Address',
      type: 'string',
      placeHolder: 'Address',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'apt',
      displayName: 'Apt',
      placeHolder: 'Apt',
      type: 'string',
    },
    {
      name: 'zipcode',
      displayName: 'Zip Code',
      type: 'string',
      placeHolder: 'Zip code',
      validators: [isNotEmptyValidator, zipCodeValidator]
    },
    {
      name: 'city',
      displayName: 'City',
      type: 'string',
      placeHolder: 'City',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'state',
      displayName: 'State',
      type: 'dropdown',
      placeHolder: 'State',
      validators: [isNotEmptyValidator, sateValidator],
      options: statesUSA
    },
    {
      name: 'id_type',
      displayName: 'Id Type',
      type: 'dropdown',
      placeHolder: 'Id Type',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'id_number',
      displayName: 'Id Number',
      type: 'string',
      placeHolder: 'Id Number',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'id_issued_date',
      displayName: 'Date of issue',
      type: 'string',
      placeHolder: 'Date of issue',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'id_exp_date',
      displayName: 'Expiration Date',
      type: 'string',
      placeHolder: 'Expiration Date',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'id_verified_date',
      displayName: 'Id Verified Date',
      type: 'string',
      placeHolder: 'Id Verified Date',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'id_issuing_state',
      displayName: 'Issuing State',
      type: 'dropdown',
      placeHolder: 'Issuing State',
      validators: [isNotEmptyValidator, sateValidator],
      options: statesUSA
    },
    {
      name: 'ssn',
      displayName: 'SSN',
      type: 'string',
      placeHolder: 'SSN',
      validators: [isNotEmptyValidator, ssnValidator,]
    },
    {
      name: 'expected_no_transactions',
      displayName: 'Expected Number of Transactions',
      type: 'dropdown',
      placeHolder: 'Expected Number of Transactions',
      validators: [isNotEmptyValidator],
    },
    {
      name: 'transaction_size',
      displayName: 'Expected Transactions Size',
      type: 'dropdown',
      placeHolder: 'Expected Transactions Size',
      validators: [isNotEmptyValidator],
    },
    {
      name: 'cross_border_activity',
      displayName: 'Cross Border Activity',
      type: 'dropdown',
      placeHolder: 'Cross Border Activity',
      validators: [isNotEmptyValidator],
    },
  ]
  formFieldsName: FormFieldConfig[] = [
    {
      name: 'first_name',
      displayName: 'First Name',
      type: 'string',
      placeHolder: 'First Name',
      validators: [isNotEmptyValidator, checkLengthChrValidator(2),]
    },
    {
      name: 'last_name',
      displayName: 'Last Name',
      type: 'string',
      placeHolder: 'Last Name',
      validators: [isNotEmptyValidator, checkLengthChrValidator(2)]
    },
  ]
  formFieldsDOB: FormFieldConfig[] = [
    {
      name: 'dob',
      displayName: 'Birthdate',
      type: 'string',
      placeHolder: 'Date of Birth',
      validators: [isNotEmptyValidator]
    },
  ]
  formFieldsIdData: FormFieldConfig[] = [
    {
      name: 'id_type',
      displayName: 'Id Type',
      type: 'dropdown',
      placeHolder: 'Id Type',
      validators: [isNotEmptyValidator]
    },
      {
        name: 'id_number',
        displayName: 'Id Number',
        type: 'string',
        placeHolder: 'Id Number',
        validators: [isNotEmptyValidator]
      },
      {
        name: 'id_issued_date',
        displayName: 'Date of issue',
        type: 'string',
        placeHolder: 'Date of issue',
        validators: [isNotEmptyValidator]
      },
      {
        name: 'id_exp_date',
        displayName: 'Expiration Date',
        type: 'string',
        placeHolder: 'Expiration Date',
        validators: [isNotEmptyValidator]
      },
      {
        name: 'id_verified_date',
        displayName: 'Id Verified Date',
        type: 'string',
        placeHolder: 'Id Verified Date',
        validators: [isNotEmptyValidator]
      },
      {
        name: 'id_issuing_state',
        displayName: 'Issuing State',
        type: 'dropdown',
        placeHolder: 'Issuing State',
        validators: [isNotEmptyValidator, sateValidator],
        options: statesUSA
      },
    ]
  formFieldsSSNITIN: FormFieldConfig[] = [
    {
      name: 'ssn',
      displayName: 'SSN',
      type: 'string',
      placeHolder: 'SSN',
      validators: [isNotEmptyValidator, ssnValidator,]
    },
  ]
  formFieldsDisplay: FormFieldConfig[] = [
    {
      name: 'first_name', //0
      displayName: 'First Name',
      type: 'string',
      placeHolder: 'First Name',
      validators: [isNotEmptyValidator, checkLengthChrValidator(2),]
    },
    {
      name: 'last_name', //1
      displayName: 'Last Name',
      type: 'string',
      placeHolder: 'Last Name',
      validators: [isNotEmptyValidator, checkLengthChrValidator(2)]
    },
    {
      name: 'dob', //2
      displayName: 'Birthdate',
      type: 'string',
      placeHolder: 'Date of Birth',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'id_type', //3
      displayName: 'Id Type',
      type: 'dropdown',
      placeHolder: 'Id Type',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'id_number',//4
      displayName: 'Id Number',
      type: 'string',
      placeHolder: 'Id Number',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'id_issued_date',//5
      displayName: 'Date of issue',
      type: 'string',
      placeHolder: 'Date of issue',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'id_exp_date', //6
      displayName: 'Expiration Date',
      type: 'string',
      placeHolder: 'Expiration Date',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'id_verified_date', //7
      displayName: 'Id Verified Date',
      type: 'string',
      placeHolder: 'Id Verified Date',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'id_issuing_state',//8
      displayName: 'Issuing State',
      type: 'dropdown',
      placeHolder: 'Issuing State',
      validators: [isNotEmptyValidator, sateValidator],
      options: statesUSA
    },

  ]
  formFieldsAddress: FormFieldConfig[] = [
    {
      name: 'street',
      displayName: 'Street Address',
      type: 'string',
      placeHolder: 'Address',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'apt',
      displayName: 'Apt',
      placeHolder: 'Apt',
      type: 'string',
    },
    {
      name: 'zipcode',
      displayName: 'Zip Code',
      type: 'string',
      placeHolder: 'Zip code',
      validators: [isNotEmptyValidator, zipCodeValidator]
    },
    {
      name: 'city',
      displayName: 'City',
      type: 'string',
      placeHolder: 'City',
      validators: [isNotEmptyValidator]
    },
    {
      name: 'state',
      displayName: 'State',
      type: 'dropdown',
      placeHolder: 'State',
      validators: [isNotEmptyValidator, sateValidator],
      options: statesUSA
    },
  ]
  formFieldsTransactionsExp: FormFieldConfig[] = [
    {
      name: 'expected_no_transactions',
      displayName: 'Expected Number of Transactions',
      type: 'dropdown',
      placeHolder: 'Expected Number of Transactions',
      validators: [isNotEmptyValidator],
    },
    {
      name: 'transaction_size',
      displayName: 'Expected Transactions Size',
      type: 'dropdown',
      placeHolder: 'Expected Transactions Size',
      validators: [isNotEmptyValidator],
    },
    {
      name: 'cross_border_activity',
      displayName: 'Cross Border Activity',
      type: 'dropdown',
      placeHolder: 'Cross Border Activity',
      validators: [isNotEmptyValidator],
    },
  ]
  formFieldsLegal: FormFieldConfig[] = [
    {
      name: 'deposit_account_agreement',
      type: 'checkbox',
      validators: [isTermAcceptedValidator]
    },
    {
      name: 'esig_disclosure',
      type: 'checkbox',
      validators: [isTermAcceptedValidator]
    },
    {
      name: 'privacy_policy_kredio',
      displayName: 'Click to accept our privacy policy',
      type: 'checkbox',
      validators: [isTermAcceptedValidator]
    },
    {
      name: 'patriot_act',
      displayName: 'Click to accept terms of USA Patriot Act',
      type: 'checkbox',
      validators: [isTermAcceptedValidator]
    },
    {
      name: 'privacy_policy_crb',
      displayName: 'Click to accept Cross River Bank privacy policy',
      type: 'checkbox',
      validators: [isTermAcceptedValidator]
    },
    {
      name: 'greendot_terms',
      displayName: 'Click to accept Greendot Terms & Conditions',
      type: 'checkbox',
      validators: [isTermAcceptedValidator]
    },
    {
      name: 'user_id',
      type: 'string',
      validators: [isNotEmptyValidator]
    }

  ]
  constructor(
    private api: ApiService,
    private router: Router) { }

  getUserInfo():Observable<any> {
    return this.api.get('/get_user_info/')
  }

  postProfile(data: Profile):Observable<any> {
    return this.api.post('/kyc/', data)
  }

  // Legal documents content
  getLegalDicsKYC():Observable<any> {
    return this.api.get('/get_kyc_legal_docs/')
  }

  signLegalDicsKYC(data: ProfileLegal):Observable<any> {
    return this.api.post('/sing_legal_docs/', data)
  }

  updateUserEmail(data:ProfileEditEmail):Observable<any> {
    return  this.api.post('/edit_email/', data)
  }

  updateUserData(data:ProfileUpdateUser):Observable<any> {
    return  this.api.post('/edit_user/', data)
  }

  // Profile: get employment type
  getIdType():Observable<any> {
    return this.api.get('/get_id_types/')
  }

  editPhone(data: ProfileEditPhone):Observable<any> {
    return this.api.post('/edit_phone/', data)
  }

  activatePhoneAuth(data: ActivatePhoneAuth):Observable<any> {
    return this.api.post('/activate_auth_phone/', data)
  }

  editPostalAddress(data:ProfileAddress):Observable<any>{
    return this.api.post('/edit_address/',data)
  }

  getTransactionsOptions():Observable<any>{
    return this.api.get('/get_anticipated_account_activity/')
  }

}
