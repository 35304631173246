import {Component, OnInit} from '@angular/core';
//import {CreditscService, CreditScore} from "../../../services/creditsc.service";
import {Router} from "@angular/router";

import {myCreditScore} from "./mockData";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-credit-score',
  templateUrl: './credit-score.component.html',
  styleUrls: ['./credit-score.component.css']
})
export class CreditScoreComponent implements OnInit{

  //creditScoreData !:CreditScore

  brand : string = environment.brand
  creditScoreData !:any

  your_route :string = 'Credit Score & Journey'
  message !: string
  score_class !:string

  constructor(
    //private creditScoreService: CreditscService,
    private router: Router
  ) {
    this.creditScoreData= myCreditScore
  }


  clickedButton(str:string):void{
    alert(str)
  }

  enrollToCreditScore():void{
    this.router.navigateByUrl('/main/accounts')
  }


  cancelEnrollToCreditScore():void{
    this.router.navigateByUrl('/main/home')
  }

  score_values !: number[]
  picked_labels !:string[]
  chartData = [
    {
      data: this.score_values,
      label: 'Score Values',
      snapToDataPoint: true,
    },
  ];

  chartLabels = this.picked_labels
  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    resizeDelay:1,
    borderColor: '#EDC33B',
    scales: {

    }

  };

  createChart(expArr: number[], labArr: string[]) {
    this.chartData[0].data  = expArr
    this.chartLabels = labArr
  }

  ngOnInit() {



      const rating = Number(this.creditScoreData.rating)
      if( rating >= 800){
        this.message = 'excellent'
        this.score_class = 'isExcellent'
      } else if( rating >= 740 && rating < 800 ){
        this.message = 'very good'
        this.score_class = 'isVeryGood'
      } else if( rating >= 670 && rating < 740 ){
        this.message = 'good'
        this.score_class = 'isGood'
      }  else if( rating >= 580 && rating < 670 ){
        this.message = 'fair'
        this.score_class = 'isFair'
      } else if( rating >= 300 && rating < 580 ){
        this.message = 'poor'
        this.score_class = 'isPoor'
      }


      this.picked_labels = this.creditScoreData.score_histo.map((i:any) => i.date)
      this.score_values = this.creditScoreData.score_histo.map((i:any) => i.score)
    this.createChart(this.score_values, this.picked_labels)

  }

}
